import { Injectable, EventEmitter, TemplateRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {

    invokeToaster = new EventEmitter();
   
    showToaster(message, duration = 3000 , type = 1) {
        this.invokeToaster.emit({ message: message, type: type, duration: duration });
    }

    toasts: any[] = [];

    show(message: string, options: any = {}) {
        this.toasts.push({ message, ...options });
    }

    remove(toast) {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

}
