import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { EventEmitterService } from '../../../@shared/services/common';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit, OnDestroy {
    currentUser: any;

    @Input()
    layout = 'vertical';

    @Input()
    menu: any;
    searchInput: string;
    favSchemesChangedSub: Subscription;
    favSchemesDeletedSub: Subscription;
    // Private
    private _unsubscribeAll: Subject<void>;

    /**
    *
    * @param {ChangeDetectorRef} _changeDetectorRef
    * @param {CoreMenuService} _coreMenuService
    */
    constructor(private _changeDetectorRef: ChangeDetectorRef, private _coreMenuService: CoreMenuService, private eventEmitterService:EventEmitterService) {
    // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.eventEmitterService.invokeSearchInputEvent.subscribe(data => {

            this.searchInput = data;
            this._changeDetectorRef.detectChanges();
        });

    }
    ngOnDestroy() {
        try { if (typeof this.favSchemesChangedSub != "undefined") this.favSchemesChangedSub.unsubscribe(); } catch (err) { console.error(err) }
        try { if (typeof this.favSchemesDeletedSub != "undefined") this.favSchemesDeletedSub.unsubscribe(); } catch (err) { console.error(err) }

    }

    // Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
    * On init
    */
    removeFunc(data: any) {
        
        try {
            let favSchemes = data.filter(item => item.order === 2);
            if (!!favSchemes && favSchemes.length > 3) {
                const indexToDelete = data.findIndex(item => item.order == 2);
                if (indexToDelete !== -1) {
                    data.splice(indexToDelete, 1);
                    return data;
                }
                else {
                    return data;
                }
            }
            else {
                return data;
            }
        }
        catch (ex) {
            return data;
        }


    }
    removeItemFunc(data: number) {
       
        let elIndex = this.menu.findIndex(i => i.id == data);
        if (elIndex !== -1) {
            this.menu.splice(elIndex, 1);
            this._changeDetectorRef.detectChanges();
        }
    }
    applyMenu() {
        this.currentUser = this._coreMenuService.currentUser;
        // Load menu
        this.menu = this.removeFunc(this._coreMenuService.getCurrentMenu());
        this.menu.sort(function (a, b) {
            return a.order - b.order;
        });

        this._changeDetectorRef.detectChanges();
    }
    ngOnInit(): void {
        // Set the menu either from the input or from the service
        this.menu = this.menu || this._coreMenuService.getCurrentMenu();
       
        // Subscribe to the current menu changes
        this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.applyMenu();
        });
        this.favSchemesChangedSub = this.eventEmitterService.invokeAddFavSchemeEvent.subscribe(data => {          
            if (!!data && data.length > 0) {
                setTimeout(() => {
                    this.applyMenu();
                }, 250);

            }
        });
        this.favSchemesDeletedSub =   this.eventEmitterService.invokeFavSchemeDeletedEvent.subscribe(data => {
            if (!!data && data > 0) {
                setTimeout(() => {
                    this.removeItemFunc(data);
                }, 250);
            }         
        });
    }
    

}
