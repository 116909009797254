/*/*/// <reference path="../shared/services/event-emitter.service.ts" />*/*/

import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import * as _ from "lodash";
import notify from "devextreme/ui/notify";
import { EventEmitterService } from './event-emitter.service';
import { fontAwesome } from '../../../assets/font-awesome-icons';
import { BehaviorSubject, Subject } from 'rxjs';
import { GenericService } from '../api';
import { TranslateService } from '@ngx-translate/core';
export enum PageType {
    menu = -1,
    kanban = 1,
    gantt = 2,
    scheduler = 3,
    todo = 4
}
@Injectable({
    providedIn: 'root'
})
export class ComponentService {

    hiddenComponents: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    popupComponents: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    isSentFormToDb: boolean = false;

    userHasApprove: boolean = false;
    currentUserStaus: number = 0;
    currentProcessFormId: number = 0;
    currentInstanceId: number = 0;

    schemaListAll = [];
    isPageSqlChange = false;

    selectedSchemaId: number = 0;

    tempDatagridPopupComponent = { components: [] };

    runtimeSelectboxDatasource = [];

    datagridColumnHasUserChanges = {};

    isNewSchemaPopup: any = null;

    currentFormCS: any = {};

    AnotherPopup: any = {


        schemaId: 0,
        keyword: "",
        start1: null,
        start2: null,
        end1: null,
        end2: null,
        status: 0,
        userId: 0
    }

    formDelegated: any = {
        prevUserId: 0,
        isDelegated: false
    }

    menuSaveFinish: any = true;

    schemaId: number = 0;

    datagridFilePicturebox = [];


    kanbanColors = [
        { bg: "#F8F4EC", fn: "#A7843E" },
        { bg: "#FFDD95", fn: "#956500" },
        { bg: "#86A7FC", fn: "#032680" },
        { bg: "#FFB996", fn: "#963200" },
        { bg: "#D5F0C1", fn: "#518F23" },
        { bg: "#7077A1", fn: "#07080B" },
        { bg: "#E5E1DA", fn: "#71654F" },
        { bg: "#86A789", fn: "#131B14" },
        { bg: "#AC87C5", fn: "#291934" },
        { bg: "#F3EDC8", fn: "#9A8922" },
        { bg: "#B2A59B", fn: "#2C2622" },
        { bg: "#FA7070", fn: "#670404" },
        { bg: "#C3ACD0", fn: "#432D50" },
        { bg: "#BFB29E", fn: "#393125" },
        { bg: "#E25E3E", fn: "#1D0904" }
    ];


    //languageSourceObject: any;
    //languageSource: any = {};

    elementsOutOfDatagrid: any = [];

    taskListFilter: any = {
        schemaId: 0,
        keyword: "",

        start1: null,
        start2: null,
        end1: null,
        end2: null,
        status: 0,
        userId: 0
    };

    systemInfoElements: any = [
        { id: 0, text: "Seçiniz", value: "" },
        { id: 1, text: "Başlatan Adı", value: "{|STARTED-USER-NAME|}" },
        { id: 2, text: "Başlatan ID", value: "{|STARTED-USER-ID|}" },
        { id: 3, text: "Başlama Tarihi", value: "{|START-DATE|}" },
        { id: 4, text: "Süreç Numarası", value: "{|PROCESS-INSTANCE-ID|}" },
        { id: 5, text: "Form ID", value: "{|FORM-ID|}" }
    ];

    languageCodes = [
        {
            "code": "trTR",
            "name": "Türkçe",
        },
        {
            "code": "enEN",
            "name": "English",
        },
        {
            "code": "deDE",
            "name": "Deutsche",
        },
        {
            "code": "esES",
            "name": "Español",
        },
        {
            "code": "frFR",
            "name": "French",
        },
        {
            "code": "caES",
            "name": "Catalan",
        },
        {
            "code": "roRO",
            "name": "Romanian",
        },//Alttakiler bileşenlerde vardı. Veritabanı langSource tablomuzda karşılığı yok. Kullanıldıysa sorun olmasın diye kaldırılmadı.
        {
            "code": "srRS",
            "name": "Српски",
        },
        {
            "code": "zhhansCN",
            "name": "中文",
        },
        {
            "code": "arAR",
            "name": "عربي",
        },
        {
            "code": "gorGOR",
            "name": "ქართული",
        },
        {
            "code": "nlNL",
            "name": "Nederlands",
        },
        {
            "code": "azAZ",
            "name": "Azərbaycanlı",
        },
        {
            "code": "itIT",
            "name": "Italiano",
        },
        {
            "code": "ruRU",
            "name": "Русский",
        },

    ]

    // countryFlags: any = [
    //     { Code: "TRTR", Key: "tr-TR", name: "Türkçe" },
    //     { Code: "ENEN", Key: "en-EN", name: "English" },
    //     { Code: "DEDE", Key: "de-DE", name: "Deutsche" },
    //     { Code: "ESES", Key: "es-ES", name: "Español" },
    //     { Code: "FRFR", Key: "fr-FR", name: "Français" },
    //     { Code: "CAES", Key: "ca-ES", name: "Catalan" },
    //     { Code: "RORO", Key: "ro-RO", name: "Romanian" },
    //     { Code: "ARAR", Key: "ar-AR", name: "عربي" },
    //     { Code: "GORGOR", Key: "gor-GOR", name: "ქართული" },
    //     { Code: "NLNL", Key: "nl-NL", name: "Nederlands" },
    //     { Code: "AZAZ", Key: "az-AZ", name: "Azərbaycanlı" },
    //     { Code: "ITIT", Key: "it-IT", name: "Italiano" },
    // ];

    currentSystemInfo: any = [];//{ id: 0, text: "", value: "" }

    isMenuForm: any = false;
    isHistoryForm: any = false;
    menuPageId: any = "";
    menuRowId: any = -1;
    usedForms: any = [];
    menuFormNodeOptions: any = [];
    historyActions = { save: 1, undo: 2, redo: 3 };
    currentForm: any = {
        formId: 0,
        categoryId: 0,
        formNo: 1,
        isReadonly: false,
        user: '',
        name: '',
        variableName: '',
        isGeneric: false,
        description: '',
        permissions: '',
        expression: '',
        components: [],
        data: [],
        canTakeScreenshot: true,
        preferences: {
            formOpenedEvents: [], formOpenedMultiElementEvents: [], formClosingEvents: [], formOpenedDisabledElementEvents: []

        },
        language: [
            { "Code": "trTR", "Title": "Form Adı" }
        ],
        tour: [],
        startTourWhenFormOpen: false

    };

    currentForm_POPUP1: any = {
        formId: 0,
        categoryId: 0,
        formNo: 1,
        isReadonly: false,
        user: '',
        name: '',
        variableName: '',
        isGeneric: false,
        description: '',
        permissions: '',
        expression: '',
        components: [],
        data: [],
        language: [
            { "Code": "trTR", "Title": "Form Adı" }
        ],
        tour: [],
        startTourWhenFormOpen: false


    };

    childPopupObj = {
        id: 0,
        currentForm: this.currentForm_POPUP1,
        currentProcessFormId: 0,
        currentInstanceId: 0,
        selectedSchemaId: 0,
        isNewSchemaPopup: null,
        isSentFormToDb: false,
        menuObjects: {},
        type: { isMenu: false, isHistory: false }
    };

    childPopups = [];
    childPopupsClosed = [];


    languageSourceChange: Subject<any> = new Subject<any>();
    button: any;
    args: any;
    setLanguageSource(_langSource) {
        //this.languageSource = _langSource;
        this.languageSourceChange.next(_langSource);
    }

    getLanguageSource() {
        return null //this.languageSource;
    }

    //cdkListIds: any;
    formStatus =
        {
            Processing: 1, //while yes
            SaveAndSend: 2, //while break
            Approve: 3, // while break
            Disapprove: 4, //while break
            Returned: 5, //while yes
            Cancelled: 6, // while break
            DisapproveTerminate: 7, // Terminate WF
            Completed: 8
        }

    userStatus = {
        DefaultStatus: 0,
        InterimApprove: 1,
        FinalApprove: 2,
        Completed: 3,
    }

    //datagridFilterOutType = {
    //    SetElement: 0,
    //    SetFilter: 1 
    //}

    cdkListIds = ['cdkListItems', 'cdkListMain'];
    currentFormComponents: any;
    //languageCode = "enEN";

    componentList = [
        {
            "group": "basic",
            "components": [
                {
                    "component": "DxLabelComponent",
                    "uuid": "",
                    "id": "",
                    "name": "Label",
                    "type": "label",
                    "isInput": true,
                    "inputType": "",
                    "value": "Label",
                    "fontSize": "14",
                    "fontStyle": "normal",
                    "fontWeight": "normal",
                    "color": "#5e5873",
                    "underline": false,
                    "showEditPop": false,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Etiket Yazısı"
                        }
                    ]
                },


                {
                    "component": "DxTextboxComponent",
                    "uuid": "",
                    "name": "Text Box",
                    "labelpossition": "top",
                    "id": "",
                    "type": "textbox",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "triggerReport": false,
                    "width": "",
                    "maxLength": 500,
                    "minLength": null,
                    "isAuto": false,
                    "autoText": "",
                    "systemInfoId": 0,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Metin Kutusu",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxPasswordComponent",
                    "uuid": "",
                    "name": "Password",
                    "id": "",
                    "type": "password",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "showEditPop": false,
                    "value": "",
                    "required": false,
                    "width": "",
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Parola",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxNumberComponent",
                    "uuid": "",
                    "name": "Number",
                    "id": "",
                    "type": "number",
                    "isInput": true,
                    "inputType": "decimal",
                    "format": "#,##0.00",
                    "showSpinButtons": false,
                    "showClearButton": false,
                    "showEditPop": false,
                    "showMinMaxInfo": false,
                    "min": null,
                    "max": null,
                    "triggerReport": false,
                    "value": 0,
                    "required": false,
                    "width": "",
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Sayı",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxPhoneComponent",
                    "uuid": "",
                    "name": "Phone",
                    "id": "",
                    "type": "phone",
                    "isInput": true,
                    "triggerReport": false,
                    "width": "",
                    "inputType": "string",
                    "showClearButton": true,
                    "mask": "+90 (000) 000-0000",
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Telefon",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxEmailComponent",
                    "name": "Email",
                    "id": "",
                    "type": "email",
                    "isInput": true,
                    "triggerReport": false,
                    "inputType": "string",
                    "showClearButton": true,
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "width": "",
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Email",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxTextareaComponent",
                    "uuid": "",
                    "name": "Text Area",
                    "id": "",
                    "type": "textarea",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "maxLength": 500,
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "width": "",
                    "height": 90,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Metin",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxSelectboxComponent",
                    "uuid": "",
                    "name": "Select Box",
                    "id": "",
                    "type": "selectbox",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "triggerReport": false,
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "resultLengthWarning": true,
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "items": [
                        {
                            "value": "",
                        }
                    ],
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "Seçiniz",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxDatagridSelectComponent",
                    "uuid": "",
                    "name": "Lookup",
                    "id": "",
                    "type": "selectbox",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "triggerReport": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "items": [
                        {
                            "value": "",
                        }
                    ],
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "Seçiniz",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxDateboxComponent",
                    "uuid": "",
                    "name": "Date Box",
                    "id": "",
                    "type": "datebox",
                    "isInput": true,
                    "triggerReport": false,
                    "inputType": "datetime",
                    "datetype": "date",
                    "displayFormat": "dd/MM/yyyy HH:mm",
                    "width": "",
                    "showEditPop": false,
                    "value": null,
                    "required": false,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Tarih",
                            "Placeholder": "...",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxCheckboxComponent",
                    "uuid": "",
                    "name": "Check Box",
                    "id": "",
                    "type": "checkbox",
                    "isInput": true,
                    "inputType": "bool",
                    "width": 130,
                    "showEditPop": false,
                    "value": false,
                    "required": false,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Check Box",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxRadiogroupComponent",
                    "uuid": "",
                    "name": "Radio Group",
                    "id": "",
                    "type": "radiogroup",
                    "isInput": true,
                    "inputType": "string",
                    "label": "Radio Group",
                    "showEditPop": false,
                    "layout": "horizontal",
                    "value": "",
                    "required": false,
                    "width": "",
                    "items": [
                        {
                            "text": "Radio Button 1",
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "language": [
                                {
                                    "Code": "trTR",
                                    "Title": ""
                                }
                            ],
                        }
                    ],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Radio Group",
                            "Hint": ""
                        }
                    ],
                    "outsideDisabled": false,
                    "outsideVisible": true
                },
                {
                    "component": "DxButtonComponent",
                    "uuid": "",
                    "name": "Buttons",
                    "nameValue": "",
                    "id": "",
                    "type": "button",
                    "label": "Button",
                    "value": 1,
                    "isInput": true,
                    "inputType": "int",
                    "style": "default",
                    "showEditPop": false,
                    "width": "",
                    "buttons": [],

                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Buton",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxMultiSelectBoxComponent",
                    "uuid": "",
                    "name": "MultiSelectBox",
                    "id": "",
                    "type": "multiselectbox",
                    "isInput": true,
                    "triggerReport": false,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "Seçiniz",
                            "Hint": ""
                        }
                    ]
                },

                {
                    "component": "DxTimeComponent",
                    "uuid": "",
                    "name": "Time Box",
                    "id": "",
                    "type": "timebox",
                    "isInput": true,
                    "triggerReport": false,
                    "displayFormat": "HH:mm",
                    "width": "",
                    "showEditPop": false,
                    "value": null,
                    "required": false,
                    "showClearButton": true,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Zaman",
                            "Placeholder": "...",
                            "Hint": ""
                        }
                    ]
                }

                //,
                //{
                //    "component": "DxPaymentComponent",
                //    "uuid": "",
                //    "name": "Payment",
                //    "labelpossition": "top",
                //    "id": "",
                //    "type": "payment",
                //    "isInput": true,
                //    "inputType": "string",
                //    "showClearButton": true,
                //    "value": "",
                //    "required": false,
                //    "showEditPop": false,
                //    "width": "",
                //    "maxLength": 500,
                //    "isAuto": false,
                //    "autoText": "",
                //    "systemInfoId": 0,
                //    "permissions": {
                //        "readonlyGroup": [],
                //        "readonlyUsers": [],
                //        "hideGroup": [],
                //        "hideUsers": []
                //    },
                //    "language": [
                //        {
                //            "Code": "trTR",
                //            "Name": "Adı Soyadı",
                //            "CardNo": "Kart Numarası",
                //            "Expires": "Tarih", 
                //            "CvvCvc": "CVV / CVC"
                //        }
                //    ]
                //}
            ]
        },
        {
            "group": "special",
            "components": [
                {
                    "component": "DxMapComponent",
                    "uuid": "",
                    "name": "Map",
                    "id": "",
                    "type": "map",
                    "isInput": false,
                    "inputType": "map",
                    "showEditPop": false,
                    "required": false,
                    "draggable": true,
                    "userChangeDraggable": false,
                    "isAddMultipleMarker": true,
                    "maxMarkerLimit": 0,
                    "value": [],
                    "markers": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Harita",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxLiveComponent",
                    "uuid": "",
                    "name": "Live Stream",
                    "labelpossition": "top",
                    "id": "",
                    "type": "livestream",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "showEditPop": false,
                    "width": "",
                    "maxLength": 500,
                    "systemInfoId": 0,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Canlı Yayın"
                        }
                    ]
                },
                {
                    "component": "DxSignatureComponent",
                    "uuid": "",
                    "id": "",
                    "name": "Signature",
                    "type": "signature",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Elektronik Imza"
                        }
                    ]
                },
                {
                    "component": "DxPdfViewerComponent",
                    "uuid": "",
                    "id": "",
                    "name": "PdfViewer",
                    "type": "pdfviewer",
                    "width": "100%",
                    "height": "100px",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Pdf Göster"
                        }
                    ]
                },
                {
                    "component": "DxCommentComponent",
                    "uuid": "",
                    "name": "Comment",
                    "id": "",
                    "type": "comment",
                    "isInput": false,
                    "inputType": "comment",
                    "value": [],
                    "width": "30",
                    "height": "400",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "showEditPop": false,
                    "validations": [],
                    "sizeTypeWidth": "%",
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Seçim",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },

                {
                    "component": "DxDiaTNBComponent",
                    "uuid": "",
                    "name": "TNB",
                    "labelpossition": "top",
                    "id": "",
                    "type": "tnb-api",
                    "isInput": true,
                    "inputType": "string",
                    "showClearButton": true,
                    "value": "",
                    "required": false,
                    "showEditPop": false,
                    "width": "",
                    "maxLength": 500,
                    "apiProperties": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "TNB Api",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
            ]
        },


        {
            "group": "Data",
            "components": [
                {
                    "component": "DxDataGridComponent",
                    "uuid": "",
                    "name": "Data Grid",
                    "id": "",
                    "type": "datagrid",
                    "isInput": false,
                    "inputType": "datagrid",
                    "label": "Data Grid",
                    "text": "Data Grid",
                    "showEditPop": true,
                    "headers": [],

                    "dataSource": [],
                    "value": [],
                    "keyExpr": "ID",
                    "summaryCol": "",
                    "selectedRows": [],
                    "sqlExpressions": [],
                    "colorExpressions": [],
                    "total": 0,
                    "filter": {
                        "users": [],
                        "field": "",
                        "operator": "",
                        "value": 0,
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxDataListComponent",
                    "uuid": "",
                    "name": "DataList",
                    "id": "",
                    "type": "datalist",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "showClearButton": true,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": [],
                        "excelDownloadGroup": [],
                        "excelDownloadUsers": []
                    }
                    ,
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "DataList",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                //{
                //    "component": "DxDatasourceComponent",
                //    "uuid": "",
                //    "name": "Data Source",
                //    "id": "",
                //    "type": "datasource",
                //    "isInput": false,
                //    "inputType": "",
                //    "value": "",
                //    "valueExpr": "",
                //    "showEditPop": false,
                //    "validations": [],
                //    "query": "",
                //    "connectionString": "",
                //    "dataSource": [],
                //},
                {
                    "component": "DxFormResultComponent",
                    "uuid": "",
                    "name": "Kolon Toplam",
                    "id": "",
                    "type": "number",
                    "isInput": true,
                    "inputType": "decimal",
                    "format": "#,##0.00",
                    "possition": "right",
                    "showEditPop": false,
                    "value": 0,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Toplam",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                },
                //{
                //    "component": "DxDisplayComponent",
                //    "uuid": "",
                //    "name": "Display Text",
                //    "labelpossition": "top",
                //    "id": "",
                //    "type": "display",
                //    "isInput": true,
                //    "inputType": "string",
                //    "width": 0,
                //    "showClearButton": true,
                //    "value": "Display",
                //    "keyExpr": "ID",
                //    "index": -1,
                //    "showEditPop": false,
                //    "showImage": false,
                //    "imgSrc": "",
                //    "showVideo": false,
                //    "imgCol": false,
                //    "videoCol": false,
                //    "videoSrc": "",
                //    "validations": [],
                //    "dataSource": [],
                //    "dataSourceCol": "",
                //    "dataSourceKey": "",
                //    "permissions": {
                //        "readonlyGroup": [],
                //        "readonlyUsers": [],
                //        "hideGroup": [],
                //        "hideUsers": []
                //    },
                //    "language": [
                //        {
                //            "Code": "trTR",
                //            "Label": "Display",
                //            "Placeholder": "Text",
                //            "Hint": "Display"
                //        }
                //    ]
                //},
                {
                    "component": "DxGalleryComponent",
                    "uuid": "",
                    "name": "Galeri",
                    "id": "gallery",
                    "type": "gallery",
                    "isInput": false,
                    "inputType": "gallery",
                    "showEditPop": false,
                    "dashboardId": 0,
                    "headers": "",
                    "isPriceEditable": false,
                    "isVideoGallery": false,
                    "value": [],
                    "category": {
                        "connectionId": 0,
                        "query": "",
                        "valueCol": "",
                        "displayCol": "",
                        "filterType": 1
                    },
                    "storage": {
                        "connectionStringId": 0,
                        "query": "",
                        "keyColumn": "",
                        "categoryColumn": "",
                        "photoColumn": "",
                        "videoColumn": "",
                        "titleColumn": "",
                        "priceColumn": "",
                        "totalColumn": "",
                        "totalColumnElement": "",
                        "imageFormat": "2",
                        "unitConnectionId": 0,
                        "unitQuery": ""
                    },
                    "options": {
                        "layout": 1,
                        "cols": "3",
                        "borderColor": "",
                        "borderSize": "",
                        "infoPossition": "bottom",
                        "imgHeight": 144,
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "rows": [
                        {
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "cols": [
                                {
                                    "size": 6,
                                    "components": []
                                },
                                {
                                    "size": 6,
                                    "components": []
                                }
                            ]
                        }
                    ]
                },
                {
                    "component": "DxDashboardComponent",
                    "uuid": "",
                    "name": "Dashboard",
                    "id": "",
                    "type": "dashboard",
                    "isInput": false,
                    "inputType": "dashboard",
                    "label": "Dashboard",
                    "text": "Dashboard",
                    "showEditPop": true,
                    "dashboardId": 0,
                    "show": true,
                    "components": [],
                    "sizeTypeWidth": "%",
                    "sizes": {
                        "width": "100",
                        "height": "300",
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Dashboard",
                            "Placeholder": "Dashboard seçiniz",
                            "Hint": "Dashboard"
                        }
                    ]
                },
                //{
                //    "component": "DxPrintDesignerComponent",
                //    "uuid": "",
                //    "name": "Print Designer",
                //    "id": "",
                //    "type": "printdesigner",
                //    "isInput": false,
                //    "inputType": "printdesigner",
                //    "label": "PrintDesigner",
                //    "text": "PrintDesigner",
                //    "showEditPop": true,
                //    "sizeTypeWidth": "%",
                //    "sizes": {
                //        "width": "100",
                //        "height": "500",
                //    },
                //    "permissions": {
                //        "readonlyGroup": [],
                //        "readonlyUsers": [],
                //        "hideGroup": [],
                //        "hideUsers": []
                //    },
                //    "language": [
                //        {
                //            "Code": "trTR",
                //            "Label": "Beyaz Sayfa Raporu",
                //            "Placeholder": "Beyaz Sayfa raporu seçiniz",
                //            "Hint": "Beyaz Sayfa Raporu"
                //        }
                //    ]
                //}
            ]
        },
        {
            "group": "Business",
            "components": [
                {
                    "component": "DxSchedulerComponent",
                    "uuid": "",
                    "name": "Scheduler",
                    "id": "",
                    "type": "scheduler",
                    "isInput": false,
                    "inputType": "scheduler",
                    "showEditPop": false,
                    "required": false,
                    "width": 500,
                    "height": 600,
                    "viewTypes": [],
                    "startTime": 8,
                    "endTime": 18,
                    "weekdayColor": "rgba(51, 122, 183, 0.1)",
                    "weekendColor": "rgba(51, 122, 183, 0.2)",
                    "eventColor": "#327dbf",
                    "canUserChange": true,
                    "isChangeDefaultColor": false,
                    "value": [],
                    "menuData": {
                        "menuNodeKey": "",
                        "subMenuNodeKey": "",
                        "menuBrowserNode": ""
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Planlayıcı",
                            "Hint": ""
                        }
                    ]
                },
                /*       {
                           "component": "DxKanbanComponent",
                           "uuid": "",
                           "name": "Kanban",
                           "id": "",
                           "type": "kanban",
                           "isInput": true,
                           "inputType": "string",
                           "value": "",
                           "width": "",
                           "height": "500",
                           "required": false,
                           "showClearButton": true,
                           "isManual": false,
                           "valueExpr": "value",
                           "displayExpr": "text",
                           "showEditPop": false,
                           "validations": [],
                           "sqlScripts": [],
                           "dataStorage": {
                               "connectionId": 0,
                               "query": ""
                           },
                           "groupSql": "",
                           "assignedColumnName": "",
                           "groupColumnName": "",
                           "taskPriorityColumnName": "",
                           "taskSubjectColumnName": "",
                           "taskDetailColumnName": "",
                           "dataSourceKey": "",
                           "dataSource": [],
                           "permissions": {
                               "readonlyGroup": [],
                               "readonlyUsers": [],
                               "hideGroup": [],
                               "hideUsers": []
                           }
                           ,
                           "language": [
                               {
                                   "Code": "trTR",
                                   "Label": "Kanban",
                                   "Placeholder": "Kanban",
                                   "Hint": "Kanban"
                               }
                           ]
                       },
                       {
                           "component": "DxRiverGanttComponent",
                           "uuid": "",
                           "name": "Gantt",
                           "id": "",
                           "type": "gantt",
                           "isInput": true,
                           "inputType": "string",
                           "value": "",
                           "width": "",
                           "required": false,
                           "showClearButton": true,
                           "isManual": false,
                           "valueExpr": "value",
                           "displayExpr": "text",
                           "showEditPop": false,
                           "validations": [],
                           "sqlScripts": [],
                           "dataStorage": {
                               "connectionId": 0,
                               "query": ""
                           },
                           "task": {
                               "connectionId": 0,
                               "query": "",
                               "keyExpr": "",
                               "titleExpr": "",
                               "startExpr": "",
                               "endExpr": "",
                               "parentIdExpr": "",
                               "progressExpr": "",
                               "colorExpr": "",
                           },
                           "dataSourceKey": "",
                           "dataSource": [],
                           "permissions": {
                               "readonlyGroup": [],
                               "readonlyUsers": [],
                               "hideGroup": [],
                               "hideUsers": []
                           }
                           ,
                           "language": [
                               {
                                   "Code": "trTR",
                                   "Label": "Gantt",
                                   "Placeholder": "Gantt",
                                   "Hint": "Gantt"
                               }
                           ]
                       },*/
                {
                    "component": "DxEtrDiagramComponent",
                    "uuid": "",
                    "name": "Diagram",
                    "id": "",
                    "type": "diagram",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "width": "",
                    "required": false,
                    "isManual": false,
                    "valueExpr": "value",
                    "displayExpr": "text",
                    "showEditPop": false,
                    "validations": [],
                    "sqlScripts": [],
                    "dataStorage": {
                        "connectionId": 0,
                        "query": ""
                    },
                    "customShapes": [],
                    "dataSourceKey": "",
                    "dataSource": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    }
                    ,
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Gantt",
                            "Placeholder": "",
                            "Hint": ""
                        }
                    ]
                }
            ]
        },
        {
            "group": "file",
            "components": [
                {
                    "component": "DxImageuploadComponent",
                    "uuid": "",
                    "name": "Image Upload",
                    "id": "",
                    "type": "imageupload",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Fotoğraf Yükle",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxPictureBoxComponent",
                    "uuid": "",
                    "name": "Picture Box",
                    "id": "",
                    "type": "picturebox",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "required": false,
                    "maxFileSize": 5,
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Fotoğraf Yükle",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxVideouploadComponent",
                    "uuid": "",
                    "name": "Video Upload",
                    "id": "",
                    "type": "videoupload",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "value": [],
                    "required": false,
                    "maxFileSize": 10,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Video Yükle",
                            "Hint": ""
                        }
                    ]
                },
                {
                    "component": "DxFileuploadComponent",
                    "uuid": "",
                    "id": "",
                    "type": "fileupload",
                    "name": "File Upload",
                    "isInput": false,
                    "inputType": "file",
                    "showEditPop": false,
                    "value": [],
                    "files": [],
                    "required": false,
                    "maxFileSize": 5,
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Dosya Yükle",
                            "Hint": ""
                        }
                    ]
                }
            ]
        },
        {
            "group": "Layout",
            "components": [
                {
                    "component": "DxLineComponent",
                    "uuid": "",
                    "name": "Line",
                    "id": "",
                    "type": "line",
                    "isInput": false,
                    "showClearButton": true,
                    "showEditPop": false,
                    "value": "",
                    "required": false,
                    "align": "",
                    "width": "",
                    "thickness": "",
                    "bgColor": "",
                    "UpperDistance": "",
                    "BottomDistance": "",
                    "validations": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Line",
                            "Placeholder": "Line",
                            "Hint": ""
                        }
                    ]
                },




                {
                    "component": "DxMenuComponent",
                    "uuid": "",
                    "name": "Menu",
                    "id": "",
                    "type": "menu",
                    "isInput": false,
                    "inputType": "menu",
                    "label": "Menu",
                    "showEditPop": false,
                    "nodes": [],
                    "value": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        { "Code": "trTR", "Title": "Form Adı" }
                    ],

                },
                {
                    "component": "DxGridComponent",
                    "uuid": "",
                    "name": "Grid",
                    "id": "grid",
                    "type": "grid",
                    "isInput": false,
                    "inputType": "",
                    "showEditPop": false,
                    "options": {
                        "showBorder": true,
                        "borderType": "ridge",
                        "borderColor": "#9f9f9f",
                        "borderSize": "1",
                        "marginTop": "0",
                        "horizontalLine": true,
                        "borderLeft": true,
                        "borderRight": true,
                        "bgColor": "rgba(0, 0, 0, 0)",
                    },

                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "rows": [
                        {
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "cols": [
                                {
                                    "size": 12,
                                    "components": []
                                }

                            ]

                        }
                    ]
                },
                {
                    "component": "DxAccordionComponent",
                    "uuid": "",
                    "name": "Panel",
                    "id": "accordion",
                    "type": "panel",
                    "isInput": false,
                    "inputType": "",
                    "showEditPop": false,
                    "options": {
                        "bgColor": "rgba(255, 255, 255, 1)",
                        "color": "rgb(0, 0, 0)"
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "items": [
                        {
                            "title": "Panel 1",
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "components": []
                        }
                    ]
                },
                {
                    "component": "DxTabsComponent",
                    "uuid": "",
                    "name": "Tab",
                    "id": "tabs",
                    "type": "tabs",
                    "isInput": false,
                    "inputType": "",
                    "showEditPop": false,
                    "options": {
                        "bgColor": "rgba(255, 255, 255, 1)",
                        "color": "rgb(0, 0, 0)"
                    },
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "items": [
                        {
                            "title": "Tab 1",
                            "permissions": {
                                "readonlyGroup": [],
                                "readonlyUsers": [],
                                "hideGroup": [],
                                "hideUsers": []
                            },
                            "components": [],
                            "visible": true
                        }
                    ],
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Tabs"
                        }
                    ]
                },
                {
                    "component": "DxChildFormComponent",
                    "uuid": "",
                    "name": "Child Form",
                    "labelpossition": "top",
                    "id": "",
                    "type": "childform",
                    "isInput": true,
                    "inputType": "string",
                    "value": "",
                    "showEditPop": false,
                    "width": "",
                    "maxLength": 500,
                    "systemInfoId": 0,
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                    "language": [
                        {
                            "Code": "trTR",
                            "Label": "Form Aç"
                        }
                    ]
                },
            ]
        },
        {
            "group": "editors",
            "components": [
                {
                    "component": "DxHtmleditorComponent",
                    "uuid": "",
                    "name": "HTML Editor",
                    "id": "",
                    "type": "htmleditor",
                    "isInput": false,
                    "inputType": "htmleditor",
                    "value": "",
                    "height": "350px",
                    "showEditPop": false,
                    "labels": [],
                    "permissions": {
                        "readonlyGroup": [],
                        "readonlyUsers": [],
                        "hideGroup": [],
                        "hideUsers": []
                    },
                }
                /*{
                    "component": "DxCodeditorComponent",
                    "uuid": "",
                    "name": "Code Editor",
                    "id": "",
                    "type": "codeditor",
                    "isInput": false,
                    "inputType": "codeeditor",
                    "value": "",
                    "height": "350px",
                    "label": "Kod Editor",
                    "showEditPop": false,
                    "labels": [],
                    "expretion": ""
                }*/
            ]
        }

    ]


    inputComponents: any = [];
    isCreateditorDict: boolean = false;

    formComponentList: any = [];

    formComponentTempList: any = [];
    schedulerChangeData: any;

    constructor(private eventEmitterService: EventEmitterService, private genericService: GenericService, public translateService: TranslateService) {

        this.allIcons = fontAwesome;

        this.languageSourceChange.subscribe((value) => {
            //this.languageSource = value
        });
    }

    createuuid() {
        return uuid();
    }


    //changeDisableAndVisible(_sub) {
    //    this.eventEmitterService.ChangeDisableAndVisible(_sub);
    //}
    //for undo and redo
    public cloneModel<T>(oldData: T): T {
        return _.cloneDeep(oldData);
    }

    isIdAvalible(id, uuid, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        let elm = this.formComponentList.find(x => x.id === id && x.uuid != uuid);
        return typeof (elm) == "undefined";
    }

    checkUserAuthorization(isReadonly, components, userId, anotherPopupId) {

        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        for (let component of this.formComponentList) {

            if (component.permissions) {
                let rUser = component.permissions.readonlyUsers.indexOf(userId) !== -1;
                let hUser = component.permissions.hideUsers.indexOf(userId) !== -1;
                let dhUser = component.permissions.deleteHideUsers ? component.permissions.deleteHideUsers.indexOf(userId) !== -1 : false;

                let rGroup: boolean = false;
                let hGroup: boolean = false;
                let dhGroup: boolean = false;
                component.permissions.readonlyGroup.forEach(group => {
                    if (group.users && group.users.indexOf(userId) !== -1) rGroup = true;
                });
                component.permissions.hideGroup.forEach(group => {
                    if (group.users && group.users.indexOf(userId) !== -1) hGroup = true;
                });
                if (component.permissions.deleteHideGroup)
                    component.permissions.deleteHideGroup.forEach(group => {
                        if (group.users && group.users.indexOf(userId) !== -1) dhGroup = true;
                    });

                component.disabled = isReadonly || rUser || rGroup;
                component.hide = hUser || hGroup;
                component.deleteHide = dhUser || dhGroup;

            }
            else {
                component.hide = false;
                component.disabled = false;
                component.deleteHide = false;
            }
        }
    }

    hideComponentList(components, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        for (let i = 0; i < components.length; i++) {
            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.hideComponentList(components[i].rows[j].cols[k].components, anotherPopupId);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.hideComponentList(components[i].items[j].components, anotherPopupId);
                }
            }
            else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
                for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
                    this.hideComponentList(currentFormSelected.menuComponents[m], anotherPopupId);
                    if (currentFormSelected.menuComponents[m].type == "grid") {
                        for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
                            for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
                                this.hideComponentList(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId);
                            }
                        }
                    }
                    else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
                        for (let j = 0; j < currentFormSelected.menuComponents[m].items.length; j++) {
                            this.hideComponentList(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId);
                        }
                    }
                }
            }
            else {
                if (components[i].hide) {
                    components.splice(i, 1);
                }
            }
        }
    }

    returnComponents = [];
    recursiveGridTabPanelComps(components, anotherPopupId, recursive = false) {
        if (!recursive) {
            this.returnComponents = [];
        }
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        for (let i = 0; i < components.length; i++) {
            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.recursiveGridTabPanelComps(components[i].rows[j].cols[k].components, anotherPopupId,true);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.recursiveGridTabPanelComps(components[i].items[j].components, anotherPopupId, true);
                }
            }
            else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
                for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
                    this.recursiveGridTabPanelComps(currentFormSelected.menuComponents[m], anotherPopupId, true);
                    if (currentFormSelected.menuComponents[m].type == "grid") {
                        for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
                            for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
                                this.recursiveGridTabPanelComps(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId, true);
                            }
                        }
                    }
                    else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
                        for (let j = 0; j < currentFormSelected.menuComponents[m].items.length; j++) {
                            this.recursiveGridTabPanelComps(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId, true);
                        }
                    }
                }
            }
            else {
                if (components[i]) {
                    this.returnComponents.push(components[i]);
                }
            }
        }
        return this.returnComponents;
    }

    evulateExpretion(expretion) {
        eval(expretion);
    }

    setFormValues(components, formValues, anotherPopupId) {

        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        for (let i = 0; i < formValues.length; i++) {
            let com = this.formComponentList.find(x => x.id == formValues[i].key);

            if (typeof com != "undefined") {
                com.value = formValues[i].value;
                if (com.component == "DxSelectboxComponent" && com.isManual == true && formValues[i].dataSource != undefined) {
                    com.dataSource = formValues[i].dataSource;
                }
                if (com.isInput) {
                    com.isUserChange = typeof formValues[i].isUserChange == "undefined" ? false : formValues[i].isUserChange;
                }
                if (com.inputType == "datagrid") {
                    if (com.value == "[]") com.value = []
                    if (com.value.length > 0) {
                        com.value = this.fixDataGridHeadersAndValues(com.value, formValues[i].headers, formValues[i].headerTypes)
                    }
                    if (this.isMenuForm == true) {
                        com.headers.forEach(header => {
                            if (header.type == "picturebox") {
                                header.files = formValues[i].headerFiles;
                            }
                        });
                    }
                    
                    com.selectedRows = formValues[i].selectedRowCount;
                    com.userChangeColumns = formValues[i].userChangeColumns;
                }
                if (com.inputType == "map") {
                    com.markers = formValues[i].markers;
                }
                if (com.componentType == "diagram") {
                    components[i].exportIds = typeof components[i].exportIds == "undefined" ? [] : components[i].exportIds
                }

                if (com.inputType == "datetime") {
                    com.isTodaySelected = typeof com.isTodaySelected == "undefined" ? true : com.isTodaySelected;
                    if (com.isTodaySelected && !this.isHistoryForm && (typeof com.value == "undefined" || com.value == null || com.value == "")) {
                        com.value = new Date();
                    }
                }

                //if (com.type == "button") { 
                //    com.isLiveWeb = formValues[i].isLiveWeb;
                //    com.liveWeb = formValues[i].liveWeb;
                //}  
            }
        }
    }

    fixDataGridHeadersAndValues(compVal, headers, headerTypes) {
        // Datagrid valuelarının tanımsız gelme sorununu düzeltmek için yazıldı.
        // Aynı zamanda header type için default değer ile ilgili bir sorun yaşanırsa kullanılabilir.
        const headersList = headers.split(',')
        const headersTypeList = headerTypes.split(',')
        if (compVal.length > 0) {
            compVal.forEach(data => {

                for (var i = 0; i < headersList.length; i++) {
                    if (typeof data[headersList[i]] == "undefined") {

                        if (headersTypeList[i] == "file"
                            || headersTypeList[i] == "map"
                            || headersTypeList[i] == "picturebox"
                            || headersTypeList[i] == "fileviewer") {

                            data[headersList[i]] = []
                        } else if (headersTypeList[i] == "int" || headersTypeList[i] == "double") {
                            data[headersList[i]] = 0
                        } else if (headersTypeList[i] == "checkbox") {
                            data[headersList[i]] = false
                        } else if (headersTypeList[i] == "date" || headersTypeList[i] == "datetime") {
                            data[headersList[i]] = null
                        } else {
                            data[headersList[i]] = ""
                        }
                    }

                }
            })
        }


        return compVal
    }

    createCdkList(anotherPopupId) {
        this.cdkListIds = [];
        let grids: any[] = this.getComponentsByType("grid", anotherPopupId);
        let panels: any[] = this.getComponentsByType("panel", anotherPopupId);
        let tabs: any[] = this.getComponentsByType("tabs", anotherPopupId);

        for (let i = 0; i < grids.length; i++) {
            for (let j = 0; j < grids[i].rows.length; j++) {
                for (let k = 0; k < grids[i].rows[j].cols.length; k++) {
                    let gridId = "cdkListGrid" + grids[i].id + "" + j + "" + k;
                    this.cdkListIds.push(gridId);
                }
            }
        }
        for (let i = 0; i < panels.length; i++) {
            for (let j = 0; j < panels[i].items.length; j++) {
                let panelId = "cdkListPanel" + panels[i].id + j;
                this.cdkListIds.push(panelId);
            }
        }
        for (let i = 0; i < tabs.length; i++) {
            for (let j = 0; j < tabs[i].items.length; j++) {
                let tabId = "cdkListTab" + tabs[i].id + j;
                this.cdkListIds.push(tabId);
            }
        }
    }

    setComponent(components, selectBoxId, elementId, key, anotherPopupId) {
        let selectBox = this.getById(selectBoxId, components, anotherPopupId);
        let el = this.getById(elementId, components, anotherPopupId);
        if (selectBox.dataSource) {
            let item = selectBox.dataSource.find(x => x[selectBox.valueExpr] === selectBox.value);
            if (item) el.value = item[key];
        }
    }

    createId(type, components, anotherPopupId) {
        if (components) {
            this.formComponentList = [];
            this.createComponentList(components, anotherPopupId);
            let items = this.formComponentList.filter(x => x.type == type);
            let i = 1;
            let itemId = "";

            for (i = 1; i < (this.formComponentList.length + 100); i++) {
                itemId = type + (items.length + i);
                if (typeof this.formComponentList.find(x => x.id == itemId) == "undefined")
                    break;
            }
            itemId = itemId == "" ? type + (items.length + 1) : itemId;

            return itemId;//type + (items.length + 1);
        }
    }

    getAllComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList;
        return items;
    }

    getComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true && x.type != "button") || x.type == "datagrid" || x.type == "gallery" || x.type == "picturebox" || x.type == "signature" || x.type == "imageupload" || x.type == "videoupload" || x.type == "fileupload" || x.type == "map" || x.type == "comment");
        return items;
    }

    getComponentContainerList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => x.type == "grid" || x.type == "panel" || x.type == "tabs");
        return items;
    }

    getComponentListWithButton(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true) || x.type == "datagrid" || x.type == "gallery" || x.type == "picturebox" || x.type == "map" || x.type == "signature" || x.type == "comment");
        return items;
    }

    getButtonComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true && x.type == "button"));
        return items;
    }
    getChildFormButtonComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true && x.type == "childform"));
        return items;
    }
    getDatagridComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == false && x.type == "datagrid"));
        return items;
    }

    getComponentListByType(components, types, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        //types.forEach(type => {

        //});
        let items = this.formComponentList.filter(x => (x.isInput == true && types.indexOf(x.type) != -1));
        return items;
    }

    getFilesComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => x.inputType == "file");
        return items;
    }

    getDiagramComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => x.type == "diagram");
        return items;
    }

    removeDuplicates(arr) {
        return arr.filter((item,
            index) => arr.indexOf(item) === index);
    }
    deleteComponentsControlLayout(_item, deleteComponents) {
        if (_item.type == "grid") {
            _item.rows.forEach(row => {
                row.cols.forEach(col => {
                    col.components.forEach(comp => {
                        this.deleteComponentsControlLayout(comp, deleteComponents);
                    });
                });
            });
        } else if (_item.type == "tabs" || _item.type == "panel") {
            _item.items.forEach(item => {
                item.components.forEach(comp => {
                    this.deleteComponentsControlLayout(comp, deleteComponents);
                })
            })
        } else {
            deleteComponents.push(_item.id);
        }
    }
    deleteComponentsControl(_item, _prefs, _elements) {

        let deleteComponents = [];
        if (_item.type == "grid" || _item.type == "tabs" || _item.type == "panel") {
            this.deleteComponentsControlLayout(_item, deleteComponents);
        } else {
            deleteComponents.push(_item.id);
        }
        if (_item.type == "radiogroup" && _item.items.length > 0) {//Radiogroup içerisinde readonly ya da gizlenmiş component varken radiogroup silindiğinde componentlerin eski haline gelmemesinden dolayı eklendi.
            this.radiogroupItemsControl(_item);
        }
        let _controlSingleComponent = _item.type != "grid" && _item.type != "panel" && _item.type != "tabs";
        let deleteComponentsControl1 = [];

        _prefs.formOpenedEvents.forEach(foe => {
            try {
                let _match = foe.query.match(/\{(\w)+\}/ig);
                if (_match && _match.length > 0) {
                    deleteComponentsControl1 = deleteComponents.filter(x => _match.indexOf("{" + x + "}") > -1);
                }
                let _control = deleteComponents.find(x => x == foe.elementId);
                if (_control)
                    deleteComponentsControl1.push(_control);
            } catch (ex) { }
        });

        let deleteComponentsControl2 = [];
        try {
            _prefs.formOpenedMultiElementEvents.forEach(fome => {
                let _match = fome.query.match(/\{(\w)+\}/ig);
                if (_match && _match.length > 0)
                    deleteComponentsControl2 = deleteComponents.filter(x => _match.indexOf("{" + x + "}") > -1);
            });
        } catch (ex) { }
        let deleteComponentsControl3 = [];
        try {
            _prefs.formClosingEvents.forEach(fome => {
                let _match = fome.query.match(/\{(\w)+\}/ig);
                if (_match && _match.length > 0)
                    deleteComponentsControl3 = deleteComponents.filter(x => _match.indexOf("{" + x + "}") > -1);
            });
        } catch (ex) { }
        let deleteComponentsControl4 = [];
        try {
            _prefs.formOpenedDisabledElementEvents.forEach(fome => {
                let _match = fome.query.match(/\{(\w)+\}/ig);
                if (_match && _match.length > 0)
                    deleteComponentsControl4 = deleteComponents.filter(x => _match.indexOf("{" + x + "}") > -1);
            });
        } catch (ex) { }

        let deleteComponentsControl = deleteComponentsControl1.concat(deleteComponentsControl2, deleteComponentsControl3, deleteComponentsControl4);
        deleteComponentsControl = this.removeDuplicates(deleteComponentsControl);

        return { status: deleteComponentsControl.length > 0, messagesComponents: (_controlSingleComponent ? "" : "Components: " + deleteComponentsControl.join(", ")) };
    }

    getInputComponentList(components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        let items = this.formComponentList.filter(x => (x.isInput == true && x.type != "button") && x.type != "datagrid" && x.type != "gallery" && x.type != "picturebox" && x.type != "map" && x.type != "signature");
        return items;
    }

    componentListObjectTemp(components) {
        for (let i = 0; i < components.length; i++) {
            this.formComponentTempList.push(components[i]);
            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.componentListObjectTemp(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.componentListObjectTemp(components[i].items[j].components);
                }
            }
        }
    }

    changeComponentListObject(components, changedComponent, changedObject, changedValue) {

        for (let i = 0; i < components.length; i++) {
            if (components[i].id == "textbox4") {

            }

            if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.changeComponentListObject(components[i].rows[j].cols[k].components, changedComponent, changedObject, changedValue);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.changeComponentListObject(components[i].items[j].components, changedComponent, changedObject, changedValue);
                }
            }
            else {
                if (changedComponent == null || components[i].id == changedComponent) {
                    components[i][changedObject] = changedValue;
                    //return components;
                }
            }






            //else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
            //    for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
            //        this.createComponentList(currentFormSelected.menuComponents[m], anotherPopupId);
            //        if (currentFormSelected.menuComponents[m].type == "grid") {
            //            for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
            //                for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
            //                    this.createComponentList(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId);
            //                }
            //            }
            //        }
            //        else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
            //            for (let j = 0; j < this.currentForm.menuComponents[m].items.length; j++) {
            //                this.createComponentList(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId);
            //            }
            //        }
            //    }
            //}

        }
        return components;
    }

    createComponentList(components, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        if (typeof components != "undefined") {
            for (let i = 0; i < components.length; i++) {
                this.formComponentList.push(components[i]);
                if (components[i].type == "grid") {
                    for (let j = 0; j < components[i].rows.length; j++) {
                        for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                            this.createComponentList(components[i].rows[j].cols[k].components, anotherPopupId);
                        }
                    }
                }
                else if (components[i].type == "panel" || components[i].type == "tabs") {
                    for (let j = 0; j < components[i].items.length; j++) {
                        this.createComponentList(components[i].items[j].components, anotherPopupId);
                    }
                }
                else if (components[i].type == "menu" && currentFormSelected.menuComponents) {
                    for (let m = 0; m < currentFormSelected.menuComponents.length; m++) {
                        this.createComponentList(currentFormSelected.menuComponents[m], anotherPopupId);
                        if (currentFormSelected.menuComponents[m].type == "grid") {
                            for (let j = 0; j < currentFormSelected.menuComponents[m].rows.length; j++) {
                                for (let k = 0; k < currentFormSelected.menuComponents[m].rows[j].cols.length; k++) {
                                    this.createComponentList(currentFormSelected.menuComponents[m].rows[j].cols[k].components, anotherPopupId);
                                }
                            }
                        }
                        else if (currentFormSelected.menuComponents[m].type == "panel" || currentFormSelected.menuComponents[m].type == "tabs") {
                            for (let j = 0; j < this.currentForm.menuComponents[m].items.length; j++) {
                                this.createComponentList(currentFormSelected.menuComponents[m].items[j].components, anotherPopupId);
                            }
                        }
                    }
                }
            }
        }
    }

    getInputComponents(components, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        const list = [];
        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput === true) {
                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                list.push(obj);
            }
            else if (components[i].type === "map") {
                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["markers"] = components[i].markers;
                list.push(obj);
            }
            else if (components[i].type === "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.getInputComponents(components[i].rows[j].cols[k].components, anotherPopupId);
                    }
                }
            }
            else if (components[i].type === "panel" || components[i].type === "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.getInputComponents(components[i].items[j].components, anotherPopupId);
                }
            }
            else if (components[i].type === "menu" && currentFormSelected.menuComponents) {
                for (let k = 0; k < currentFormSelected.menuComponents.length; k++) {
                    this.createComponentList(currentFormSelected.menuComponents[k], anotherPopupId);
                }
            }
        }

        return list;
    }






    getInputComponentsByReturnArray(components, list: any, anotherPopupId) {

        let currentFormSelected = this.currentFormSelected(anotherPopupId);

        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput === true) {

                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                list.push(obj);
            }
            else if (components[i].type === "map") {
                const obj = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["markers"] = components[i].markers;
                list.push(obj);
            }
            else if (components[i].type === "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.getInputComponentsByReturnArray(components[i].rows[j].cols[k].components, list, anotherPopupId);
                    }
                }
            }
            else if (components[i].type === "panel" || components[i].type === "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.getInputComponentsByReturnArray(components[i].items[j].components, list, anotherPopupId);
                }
            }
            else if (components[i].type === "menu" && currentFormSelected.menuComponents) {
                for (let k = 0; k < currentFormSelected.menuComponents.length; k++) {
                    this.getInputComponentsByReturnArray(currentFormSelected.menuComponents[k], list, anotherPopupId);
                }
            }
        }

        return list;
    }


    getById(id, components, anotherPopupId) {
        this.formComponentList = [];
        this.createComponentList(components, anotherPopupId);
        return this.formComponentList.find(x => x.id === id) || {};
    }

    getComponentsByType(key, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        return this.formComponentList.filter(f => f.type == key);
    }

    getComponentsByComponentName(name, anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        return this.formComponentList.filter(f => f.component == name);
    }

    getFileComponents(anotherPopupId) {
        let currentFormSelected = this.currentFormSelected(anotherPopupId);
        this.formComponentList = [];
        this.createComponentList(currentFormSelected.components, anotherPopupId);
        return this.formComponentList.filter(f => { f.type == "fileupload" || f.type == "imageupload" || f.type == "picturebox" || f.type == "videoupload" || f.type == "signature" });
    }

    getDataSourceByKey(key, anotherPopupId) {
        return this.getById(key, this.currentForm.components, anotherPopupId)
    }

    getLocaleLang(locale) {
        return locale != null && locale != "" && locale.indexOf("-") > -1 ? locale.split("-")[0] : "tr";
    }

    sum(id, columnName, components, anotherPopupId) {
        let grid = this.getById(id, components, anotherPopupId);
        if (grid) {
            let total = 0;
            for (let i = 0; i < grid.dataSource.length; i++) {
                total += parseInt(grid.dataSource[i][columnName]);
            }
            return total;
        }
        return 0;
    }

    getLang(code, languages) {
        try {
            for (let i = 0; i < languages.length; i++) {
                if (languages[i].Code.toUpperCase() == code.toUpperCase()) {
                    return languages[i];
                } 
            }
        }
        catch {
        }
        return this.getDefLang(languages);
    }

    getDefLang(languages) {
        try { return languages.find(f => f.Code == "trTR"); }
        catch {
        }
    }

    getFile(fileData) {
        return new Promise(resolve => {
            var reader = new FileReader();
            reader.readAsDataURL(fileData);
            reader.onload = (args: any) => {
                if (reader.result) {
                    let fileName = fileData.name;
                    let fileType: string = fileData.type;
                    let base64BlobData: any = args.target.result.split(',').pop();
                    let fileExtension = fileName.slice((fileName.lastIndexOf('.') - 1 >>> 0) + 2);
                    let fileSize = fileData.size;
                    let document: any = {
                        base64BlobData: base64BlobData,
                        name: fileName,
                        fileType: fileType,
                        extension: fileExtension,
                        size: fileSize,
                        isImg: this.isImage(fileExtension)
                    };
                    resolve(document);
                }
            }
        });
    }
    replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace).replace(/[\u200B-\u200D\uFEFF]/g, '');
    }
    replaceAllUsingType(query, find, replace, type = null) {
        if (replace != null && typeof replace != "undefined" && typeof replace.getMonth === 'function') {
            replace = replace.getFullYear() + "-" + (replace.getMonth() + 1) + "-" + replace.getDate() + " " + replace.getHours() + ":" + replace.getMinutes();
        }
        query = this.replaceAll(query, find, replace);
        return query;
    }

    isImage(extension) {
        let ext = extension.toLowerCase();
        if (ext == "png"
            || ext == "jpg"
            || ext == "jpeg"
            || ext == "jfif"
            || ext == "gif"
            || ext == "tif"
            || ext == "tiff") return true;
        else return false;
    }

    //code editor functions
    colSum(id, colm, components, anotherPopupId) {
        let dataGridValue = this.getById(id, components, anotherPopupId).value;
        if (dataGridValue) {
            let total = 0;
            for (let j = 0; j < dataGridValue.length; j++) {
                total += dataGridValue[j][colm];
            }
            return total;
        }
    }

    setValue(selectboxId, elementId, key, components, anotherPopupId) {
        let selectBox = this.getById(selectboxId, components, anotherPopupId);
        let el = this.getById(elementId, components, anotherPopupId);
        let item = selectBox.dataSource.find(x => x[selectBox.valueExpr] === selectBox.value);
        el.value = item[key];
    }

    showAlert(message, status, time) {
        notify(message, status, time);
    }

    setColSumToElement(elementId, field, gridData, components) {
        
        let sum = 0;
        for (let i = 0; i < gridData.length; i++) {
            let gridVal = parseFloat(gridData[i][field]);
            sum += (typeof gridVal === "undefined" || isNaN(gridVal) ? 0 : gridVal);
        }
        return sum;
        /* 
        let elm = this.getById(elementId, components);
        elm.value = sum;*/
    }


    currentFormSelected(anotherPopupId) {
        let currentFormSelected;
        // try {
        if (anotherPopupId == 0) {
            currentFormSelected = this.currentForm;
        } else {

            let childObj = this.childPopups.find(x => x.id == anotherPopupId);
            if (childObj) {
                currentFormSelected = childObj.currentForm;
            }
            else {

                let childClosedObj = this.childPopupsClosed.find(x => x.id == anotherPopupId);

                //console.log("--------------------------------------------------------------");
                //console.log(this.childPopupsClosed);
                //console.log("++++++++++++++++++++");
                //console.log(anotherPopupId); 
                if (typeof childClosedObj != "undefined") {
                    currentFormSelected = childClosedObj.currentForm;
                } else {
                    currentFormSelected = this.currentForm;
                }
            }

        }
        //}
        //catch (ex) {
        //    console.warn("River Error: ");
        //    console.warn(ex);
        //}
        return currentFormSelected;
    }


    getAllIcons() {
        let _return = [];
        this.allIcons.forEach(_icon => {
            _return.push({ icon: _icon, html: "<i class='" + _icon + "'></i>" });
        });
        return _return;
    }


    isInteger(str) {
        if (typeof str != "undefined" && str != null) {
            str = (str + "").trim();
            if (!str) {
                return false;
            }
            str = str.replace(/^0+/, "") || "0";
            var n = Math.floor(Number(str));
            return String(n) === str && n >= 0;
        }
        else
            return false;
    }

    getlanguageCodes() {
        return this.languageCodes;
    }

    allIcons = []
    buttonNameGlobal: any = "";
    createValuesDataCS(components) {

        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput == true
                || components[i].inputType == "datagrid"
                || components[i].inputType == "file"
                || components[i].inputType == "map"
                || components[i].inputType == "menu"
                || components[i].type == "gallery"
                || components[i].type == "codeeditor"
                || components[i].type == "htmleditor"
                || components[i].type == "comment"
                || components[i].type == "diagram") {
                var obj: any = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["type"] = components[i].inputType;
                obj["componentType"] = components[i].type;
                obj["isAuto"] = components[i].isAuto;
                obj["autoText"] = components[i].autoText;
                obj["isUserChange"] = components[i].isUserChange;
                if (components[i].isInput) {
                    components[i].isUserChange = typeof components[i].isUserChange == "undefined" ? false : components[i].isUserChange
                }
                if (components[i].type == "livestream") {
                    obj["meetingType"] = components[i].meetingType;
                }
                if (components[i].type == "button") {

                    // let selectedButton = components[i];//.find(x => x.property.name == this.buttonNameGlobal);
                    obj["name"] = this.buttonNameGlobal;// components[i].nameValue; 
                    // obj["isLiveWeb"] = selectedButton.isLiveWeb;
                    //  obj["liveWeb"] = selectedButton.liveWeb;
                }
                if (components[i].inputType == "datagrid") {

                    var gridVal = components[i].value;
                    var lastId = gridVal.length > 0 ? gridVal[gridVal.length - 1].ID : 0;
                    obj["lastid"] = lastId;

                    var headerAndType = this.getGridHeaders(components[i]);
                    obj["headers"] = headerAndType.headers;
                    obj["headerTypes"] = headerAndType.headerTypes;
                    obj["rowCount"] = components[i].value.length;
                    obj["selectedRowCount"] = components[i].selectedRows;
                    obj["userChangeColumns"] = components[i].userChangeColumns;
                }
                else if (components[i].type == "map") {

                    obj["markers"] = components[i].markers;
                }
                if (components[i].inputType == "diagram") {
                    obj["exportIds"] = typeof components[i].exportIds == "undefined" ? [] : components[i].exportIds
                }
                else {
                    obj["markers"] = [];
                    obj["headers"] = "";
                    obj["headerTypes"] = "";
                    obj["rowCount"] = 0;
                    obj["selectedRowCount"] = 0;
                    obj["userChangeColumns"] = [];
                }
                this.currentFormCS.push(obj);
            }
            else if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createValuesDataCS(components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createValuesDataCS(components[i].items[j].components);
                }
            }


        }
    }

    getGridHeaders(grid) {
        let headers = [];
        let headerTypes = [];
        grid.headers.forEach(header => {
            var obj: any = {};
            //if (header.type != "file") {

            headers.push(header.field);
            headerTypes.push(header.type);
            //}
        });
        return { headers: headers.join(","), headerTypes: headerTypes.join(",") };
    }

    async recFunctionAsync(selectebuttonsFormClose, _args, _button, _index, anotherPopupId): Promise<{ success: boolean, index: number }> {
        return new Promise((resolve, reject) => {
            if (_index >= selectebuttonsFormClose.length) {
                // Recursion sona erdi, işlem tamamlandı
                resolve({ success: false, index: _index }); // Varsayılan bir değer veya istediğiniz bir şeyi döndürebilirsiniz
            } else {
                if (typeof selectebuttonsFormClose[_index].id == 'undefined')
                    selectebuttonsFormClose[_index].id = this.createuuid();

                let selectebuttonsFormCloseItem = this.cloneModel(selectebuttonsFormClose[_index]);
                let currentFormSelected = this.currentFormSelected(anotherPopupId);
                let curentComponents = this.getComponentList(currentFormSelected.components, anotherPopupId);
                let pref = selectebuttonsFormCloseItem.value;
                let query = selectebuttonsFormCloseItem.query;
                var pattern = /\{(\w)+\}/ig;
                var patt = new RegExp(pattern);
                this.button = _button;
                this.args = _args;
                if (patt.test(pref)) {
                    let _match = pref.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                pref = this.replaceAllUsingType(pref, f, _value);
                            }
                            else if (this.tempDatagridPopupComponent.components.length > 0) {
                                let _selectElement = this.tempDatagridPopupComponent.components.find(x => x.id == f.replace("{", "").replace("}", ""));
                                if (_selectElement && _selectElement.type) {
                                    let _value = _selectElement.value == null ? "" : _selectElement.value;
                                    pref = this.replaceAllUsingType(pref, f, _value);
                                }
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }
                if (patt.test(query)) {
                    let _match = query.match(pattern);
                    if (_match.length > 0) {
                        _match.forEach(f => {
                            let _k = curentComponents.find(k => k.id == f.replace("{", "").replace("}", ""));
                            if (_k) {
                                let _value = _k.value == null ? "" : _k.value;
                                query = this.replaceAllUsingType(query, f, _value);
                            }
                            else if (this.tempDatagridPopupComponent.components.length > 0) {
                                let _selectElement = this.tempDatagridPopupComponent.components.find(x => x.id == f.replace("{", "").replace("}", ""));
                                if (_selectElement && _selectElement.type) {
                                    let _value = _selectElement.value == null ? "" : _selectElement.value;
                                    query = this.replaceAllUsingType(query, f, _value);
                                }
                            }
                        });
                    }
                    //query = this.genericService.cleanSqlSyntax(query.replace(pattern, ""))
                }

                this.currentFormCS = [];

                let currentFormSelectedGlobalCS = this.currentFormSelected(anotherPopupId);

                this.createValuesDataCS(currentFormSelectedGlobalCS.components);

                let fileuploadComponent = this.getFilesComponentList(currentFormSelectedGlobalCS.components, anotherPopupId);
                if (fileuploadComponent.length > 0) {
                    fileuploadComponent = fileuploadComponent.filter(component => component.name !== 'PdfViewer');
                    fileuploadComponent.forEach(f => {
                        f.tempValueCount = typeof f.valueTemp == "undefined" || f.valueTemp == null ? 0 : f.valueTemp.length;
                        let currentFileuploadCS = this.currentFormCS.find(k => k.key == f.id);
                        if (currentFileuploadCS) {
                            currentFileuploadCS.tempValueCount = typeof f.valueTemp == "undefined" || f.valueTemp == null ? 0 : f.valueTemp.length;
                        }
                    });
                }

                let jsonValStr = JSON.stringify(this.currentFormCS);

                jsonValStr = jsonValStr.replace(/'/g, " ");

                query = this.replaceAll(query, "{SYSTEMFORMJSONVALUE}", jsonValStr);

                query = query.replace('\r', '').replace('\n', '')

                this.genericService.executeQuerySecuritedBody(query, selectebuttonsFormCloseItem.connectionId).subscribe((result: any) => {
                    if (result && result.length > 0) {
                        if (this.button.type == "childform") {
                            let _data = {
                                processId: this.button.processId,
                                isFormSaveAndClose: this.button.isFormSaveAndClose,
                                addedComponents: this.button.addedComponents
                            }

                            if (_index < selectebuttonsFormClose.length) {
                                let _return = this.formClosingEvent(selectebuttonsFormCloseItem, result[0], pref);
                                if (_return == true) {
                                    resolve({ success: true, index: _index });
                                } else if (_index + 1 < selectebuttonsFormClose.length) {
                                    this.recFunctionAsync(selectebuttonsFormClose, this.args, this.button, _index + 1, anotherPopupId).then(result => resolve(result)).catch(error => reject(error));
                                } else {
                                    resolve({ success: false, index: _index });
                                }
                            } else {
                                resolve({ success: false, index: _index });
                            }

                        } else {
                            if (_index < selectebuttonsFormClose.length) {
                                let _return = this.formClosingEvent(selectebuttonsFormCloseItem, result[0], pref);
                                if (_return == true) {
                                    resolve({ success: true, index: _index });
                                } else if (_index + 1 < selectebuttonsFormClose.length) {
                                    this.recFunctionAsync(selectebuttonsFormClose, this.args, this.button, _index + 1, anotherPopupId).then(result => resolve(result)).catch(error => reject(error));
                                } else {
                                    resolve({ success: false, index: _index });
                                }
                            } else {
                                resolve({ success: false, index: _index });
                            }
                        }

                    } else {
                        this.showAlert("Form Closing Event Sorgunuzu Kontrol Edin.", "error", 5000);
                        reject("Form Closing Event Sorgusu Boş");
                    }
                });
            }
        });
    }
    //getComponentValuesForSpParameterValues(components, _components, list: any, anotherPopupId) {
    //    debugger
    //    let currentFormSelected = this.currentFormSelected(anotherPopupId);

    //    for (let i = 0; i < components.length; i++) {
    //        let comp = _components[i];
    //        if (comp.isInput === true) {
    //            const obj = {};
    //            obj["key"] = components[i].key;
    //            obj["value"] = components[i].value;
    //            list.push(obj);
    //        }
    //        else if (components[i].componentType === "map") {
    //            const obj = {};
    //            obj["key"] = components[i].key;
    //            obj["value"] = components[i].value;
    //            obj["markers"] = components[i].markers;
    //            list.push(obj);
    //        }
    //        else if (components[i].componentType === "grid") {
    //            for (let j = 0; j < components[i].rows.length; j++) {
    //                for (let k = 0; k < components[i].rows[j].cols.length; k++) {
    //                    this.getComponentValuesForSpParameterValues(components[i].rows[j].cols[k].components, _components ,list, anotherPopupId);
    //                }
    //            }
    //        }
    //        else if (components[i].componentType === "panel" || components[i].componentType === "tabs") {
    //            for (let j = 0; j < components[i].items.length; j++) {
    //                this.getComponentValuesForSpParameterValues(components[i].items[j].components, _components ,list, anotherPopupId);
    //            }
    //        }
    //        else if (components[i].componentType === "menu" && currentFormSelected.menuComponents) {
    //            for (let k = 0; k < currentFormSelected.menuComponents.length; k++) {
    //                this.getComponentValuesForSpParameterValues(currentFormSelected.menuComponents[k], _components ,list, anotherPopupId);
    //            }
    //        }
    //    }

    //    return list;
    //}
    formClosingEvent(selectebuttonsFormCloseItem, result, pref) {

        let queryValue = result[Object.keys(result)[0]];
        if (selectebuttonsFormCloseItem.operator === "=") {
            if (queryValue == pref) {
                return true;
            } else { return false; }
        }
        else if (selectebuttonsFormCloseItem.operator === "!=") {
            if (queryValue != pref) {
                return true;
            } else { return false; }
        }
        else if (selectebuttonsFormCloseItem.operator === "<") {
            if (queryValue < pref) {
                return true;
            } else { return false; }
        }
        else if (selectebuttonsFormCloseItem.operator === ">") {
            if (queryValue > pref) {
                return true;
            } else { return false; }
        }
        else if (selectebuttonsFormCloseItem.operator === "in") {
            let prefStr = pref.toString();
            if (queryValue != "") {
                if (prefStr.includes(queryValue.toString())) {
                    return true;
                } else { return false; }
            }
        }
        else if (selectebuttonsFormCloseItem.operator === "not in") {
            let prefStr = pref.toString();
            if (queryValue != "") {
                if (!prefStr.includes(queryValue.toString())) {
                    return true;
                } else { return false; }
            }
        }
    }

    radiogroupItemsControl(item) {//Radiogroup içerisinde readonly ya da gizlenmiş component varken radiogroup silindiğinde componentlerin eski haline gelmemesinden dolayı eklendi.
        let currentFormSelected = this.currentFormSelected(0);
        let componentList = this.getAllComponentList(currentFormSelected.components, 0);
        item.items.forEach(i => {
            if (typeof i.disable != "undefined" && i.disable != "") {////
                i.disable.split(",").forEach(f => {
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        _k.outsideDisabled = false;
                        let _sub = { key: _k.id, outsideDisabled: false };
                        this.eventEmitterService.changeDisableStatus(_sub);
                    }
                });
            }
            if (typeof i.invisible != "undefined" && i.invisible != "") {//////
                i.invisible.split(",").forEach(f => {
                    f = f.replace("{", "").replace("}", "")
                    let button = []; // Button name list
                    let tabs = [];
                    if (f.includes(";")) {

                        let temp = f.split(";")[1]
                        if (temp.includes(":")) {
                            button = temp.split(":")
                            tabs = temp.split(":")
                        } else {
                            button.push(temp)
                            tabs.push(temp)
                        }
                        f = f.split(";")[0]
                    }
                    let _k = componentList.find(k => k.id == f.replace("{", "").replace("}", ""));
                    if (typeof _k != "undefined") {
                        //console.log(_k)
                        _k.outsideVisible = true;
                        let _sub = { key: _k.id, outsideVisible: true, hideButtonList: [], hideTabList: [], resetHiddenComponent: false }

                        this.eventEmitterService.ChangeVisible(_sub);
                    }
                });
            }
        })
    }


}
