import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { AppInfoService, EventEmitterService } from '../../../../../@shared/services/common';
import { CoreConfigService } from '../../../../services/config.service';
import { CoreMenuService } from '../../core-menu.service';
import { Subject, takeUntil } from 'rxjs';
import { TaskService } from '../../../../../@shared/services/api';
import { DxContextMenuComponent } from 'devextreme-angular';

@Component({
    selector: '[core-menu-vertical-item]',
    templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent implements AfterViewInit {
    licenseType: string;
    isNotPro: boolean;
    isCollapsed: boolean;
    private _unsubscribeAll: Subject<void>;
    isSemiDark: any;
    layoutType: any;
    @ViewChildren('elementRef') elements: QueryList<any>;
    @ViewChild(DxContextMenuComponent, { static: false }) contextMenu: DxContextMenuComponent;
    constructor(private menuService: CoreMenuService,
        private appInfo: AppInfoService,
        private eventEmitterService: EventEmitterService,
        public _coreConfigService: CoreConfigService,
        private taskService: TaskService,
        private changeDetectorRef: ChangeDetectorRef,
        private el: ElementRef,
        private renderer: Renderer2) {
        if (appInfo.currentUser.tabs && appInfo.currentUser.tabs.includes(6)) {
            this.analiticsIsTrue = true;
        } else {
            this.analiticsIsTrue = false;
        }
        this._unsubscribeAll = new Subject();
        this.licenseType = this.appInfo.currentUser.licanseType;
        if (this.licenseType == '1') {
            this.isNotPro = true;
        }
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            
            this.isCollapsed = config.layout.menu.collapsed;
            this.isSemiDark = config.layout.skin;
            this.layoutType = config.layout.type;
            this.updateFavSchemeClass();
        });
    }
    ngAfterViewInit() {
        this.updateFavSchemeClass();
    }
    updateClass() {
        if (!!this.elements) {
            this.elements.forEach(element => {
                if (element.nativeElement) {
                    if (this.isSemiDark === 'dark' || this.isSemiDark === 'semi-dark') {
                        this.renderer.removeClass(element.nativeElement, 'favScheme');
                    } else {
                        this.renderer.addClass(element.nativeElement, 'favScheme');
                    }
                }
            });
        }
    }
    updateFavSchemeClass() {
        if (!!this.elements) {
            this.updateClass()
        }
        else {
            setTimeout(() => {
                this.updateClass();
            },1500)
        }
    }
    @Input()
    item: CoreMenuItem;
    analiticsIsTrue: boolean;
    onMenuItemClick(item: any) {       
        this.menuService.onMenuItemClickEvent(item);
    } 
    itemClick(event, title, id, item) {
        if (event.itemData.name === 'filterItems') {
            this.eventEmitterService.applyMenuToDataGridFilter(title);
            this.forceMenuClose();
        }
        else if (event.itemData.name === 'addFavoriteScheme') {
            this.taskService.SetFavorite(id).subscribe(data => {
                this.eventEmitterService.addFavSchemeEvent(data.list);
                this.forceMenuClose();  
            });
        }
        else if (event.itemData.name === 'deleteFavoriteScheme') {
            this.taskService.deleteFavoriteScheme(this.appInfo.currentUser.id, id).subscribe(data => {
                if (data) {
                    this.eventEmitterService.favSchemeDeleteEvent(id);
                    this.forceMenuClose();  
                }
            });
        }
    }
    //Favorilerden kald�r veya filtrele diyince contextmenu kapanm�yordu.Forcelamak zorunda kald�m.
    forceMenuClose() {
        this.contextMenu.instance.hide();  
        document.addEventListener('click', () => {
            this.contextMenu.instance.hide();  
        }, { once: true });  
    }

    
}
