import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { PlatformLocation } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AppInfoService {

    public RiverEntity: any = {};
    public currentUser: any = {
        id: 1,
        color: "red",
        subUsers: []
    };
    public teDate = "";
    public sessionTimeout = "";
    public token = "";
    public homeTabs = {
        dashboard: "1",
        scheduler: "2",
        task: "3",
        report: "4",
    }

    public chatMessageEnum = {
        User: 0,
        Group: 1,
        NotChat: 2
    }

    public parameters: any;
    public accessTokenUrl = "";
    public chatBoxMessageOpen: any = false;
    public chatSelectedUserGlobal: any = null;

    constructor(private platformLocation: PlatformLocation) {
    }

    public get title() {
        return 'RIVER';
    }

    public get appUrl() {
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        this.accessTokenUrl = urlParams.get('access_token');
        return/* "http://localhost:53007/";//"http://localhost:53007/";//*/(this.platformLocation as any).location.origin + "/";
    }

    public get httpOptions() { 
        let token = this.currentUser.token ? this.currentUser.token : this.accessTokenUrl;
        if (!token) {
            let rememberMe = localStorage.getItem("rememberMe");
            let _localUserSession = localStorage.getItem("localUserSession");
           
            if ((rememberMe && rememberMe == "true") && _localUserSession) {
                let entity = JSON.parse(_localUserSession);
                token = entity.token;
            }
        }
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${token}`,
            })
        };
    }

    public get httpOptionsFile() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'multipart/form-data'
            })
        };
    }



}
