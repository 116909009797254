import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { Profile } from './profile.model';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    public onChangeSelectedUser: BehaviorSubject<number>;

    constructor(public appInfo: AppInfoService, private http: HttpClient) {
        this.onChangeSelectedUser = new BehaviorSubject(0);
    }
    deletePostComment(id: number, userId:number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/profile/deletepostcomment?id=${id}&userId=${userId}`, this.appInfo.httpOptions);
    }

    deletePost(id: number, userId: number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/profile/deletepost?id=${id}&userId=${userId}`, this.appInfo.httpOptions);
    }
    savePost(model: any) { 
    
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/savepost", JSON.stringify(model), this.appInfo.httpOptions);
    }
    postCountControl(profileId:number) {
        return this.http.get<boolean>(this.appInfo.appUrl + "api/profile/postcountcontrol?profileId=" + profileId, this.appInfo.httpOptions);

    }
    savePostComments(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/savepostcomments", JSON.stringify(model), this.appInfo.httpOptions);
    }
    GetMostActiveUsers(profileId:number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/profile/getmostactiveusers?profileId=" + profileId, this.appInfo.httpOptions);

    }
    getMostLikedPosts(profileId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/profile/getmostlikedposts?profileId=" + profileId, this.appInfo.httpOptions)
    }

    getPostComments(postId: any) {
        return this.http.get<any>(this.appInfo.appUrl + "api/profile/getpostcomments?postId="+postId, this.appInfo.httpOptions);
    }

    retweet(retweetFromId: number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/retweet?retweetFromId=" + retweetFromId, this.appInfo.httpOptions);

    }
   getUserDesign(userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/user/getuserdesign?userId=" + userId, this.appInfo.httpOptions);

    }
    setUserDesign(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/user/setuserdesign", JSON.stringify(model), this.appInfo.httpOptions);
    }
    resetUserDesign(profileId: number) {
         
        return this.http.post<any>(this.appInfo.appUrl + "api/user/resetuserdesign?profileId=" + profileId, this.appInfo.httpOptions);

    }
    likePost(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/likepost", JSON.stringify(model), this.appInfo.httpOptions);
    }

    whoLikedMyPost(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/wholikedmypost", JSON.stringify(model), this.appInfo.httpOptions);
    }
    whoLikedMyComment(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/wholikedmycomment", JSON.stringify(model), this.appInfo.httpOptions);
    }
   


    getPostList(selectedUser: number, profileId:number) {
        return this.http.get(`${this.appInfo.appUrl}api/profile/getpostlist?selectedUser=${selectedUser}&profileId=${profileId}`, this.appInfo.httpOptions);
    }

    getPreferences(userId: number) {
        return this.http.get(this.appInfo.appUrl + "api/profile/getpreferences?userId=" + userId, this.appInfo.httpOptions);
 
    }

    setPreferences(model: any) {
        
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/setpreferences", JSON.stringify(model), this.appInfo.httpOptions);

    }
    getEmailPreferences(userId: number) {
        return this.http.get(this.appInfo.appUrl + "api/profile/getemailpreferences?userId=" + userId, this.appInfo.httpOptions);

    }
    getShowNotifiData(profileId: number) {
        return this.http.get(this.appInfo.appUrl + "api/profile/getshownotifidata?profileId=" + profileId, this.appInfo.httpOptions);
    }
    

    setEmailPreferences(model:any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/setemailpreferences", JSON.stringify(model), this.appInfo.httpOptions);
      }
    setNotificationPreferences(model: any) {
        
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/setnotificationpreferences", JSON.stringify(model), this.appInfo.httpOptions);


    }
    

    getPost(id:number) {
        return this.http.get(this.appInfo.appUrl + "api/profile/getpost?id=" + id, this.appInfo.httpOptions);
    }


    likeComment(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/profile/likecomment", JSON.stringify(model), this.appInfo.httpOptions);
    }
    getProfile(profileId: number) {
         
        return this.http.get<any>(`${this.appInfo.appUrl}api/profile/getprofile?profileId=${profileId}`, this.appInfo.httpOptions);
    }
    updateProfile(profile: Profile): Observable<any> {
        return this.http.post(this.appInfo.appUrl + "api/profile/updateprofile", JSON.stringify(profile), this.appInfo.httpOptions);
    }
    updateProfileNotification(profile: Profile): Observable<any>  {
        return this.http.post(this.appInfo.appUrl + "api/profile/updateprofilenotification", JSON.stringify(profile), this.appInfo.httpOptions);

    }
    //updateCommentNotification(profile: Profile): Observable<any> {
        
    //    return this.http.post(this.appInfo.appUrl + "api/profile/updatecommentnotification", JSON.stringify(profile), this.appInfo.httpOptions);

    //}
    updateBoardNotification(profile: Profile): Observable<any> {
         
        return this.http.post(this.appInfo.appUrl + "api/profile/updateboardnotification", JSON.stringify(profile), this.appInfo.httpOptions);

    }
    updateProcessNotification(profile: Profile): Observable<any> {

        return this.http.post(this.appInfo.appUrl + "api/profile/updateprocessnotification", JSON.stringify(profile), this.appInfo.httpOptions);

    }
   
    getGroupNames() {
        return this.http.get<any>(this.appInfo.appUrl + "api/user/getgroups" , this.appInfo.httpOptions);
    }
    getPostCommentLikes(commentId: number) {
        return this.http.get(this.appInfo.appUrl + "api/profile/getpostcommentlikes?commentId=" + commentId, this.appInfo.httpOptions);
    }

    getMyPosts(userId: number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/profile/getmyposts?userId=${userId}`, this.appInfo.httpOptions);
    }

    getLikedPosts(userId: number) {
        return this.http.get<any>(`${this.appInfo.appUrl}api/profile/getlikedposts?userId=${userId}`, this.appInfo.httpOptions);

    }
    getCanPost() {
        return this.http.get<any>(`${this.appInfo.appUrl}api/profile/getcanpost`, this.appInfo.httpOptions);

    }
    canSearch(canSearch: boolean) {
        
        return this.http.post<any>(`${this.appInfo.appUrl}api/profile/cansearch?canSearch=${canSearch}`, this.appInfo.httpOptions);
 
    }
    
    canPost(canPost: boolean) {

        return this.http.post<any>(`${this.appInfo.appUrl}api/profile/canpost?canPost=${canPost}`, this.appInfo.httpOptions);

    }




    changeSelectedUser(id) {
        if (this.appInfo.currentUser.licanseType == "1" && id != this.appInfo.currentUser.id) {
            id = this.appInfo.currentUser.id
        }
        this.onChangeSelectedUser.next(id);
    }


}
