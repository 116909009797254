import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

export interface Locale {
    lang: string;
    data: Object;
}

@Injectable({
    providedIn: 'root'
})
export class CoreTranslationService {

    countryFlags: any = [
        { Code: "TRTR", Key: "tr-TR", name: "Türkçe" },
        { Code: "ENEN", Key: "en-EN", name: "English" },
        { Code: "DEDE", Key: "de-DE", name: "Deutsche" },
        { Code: "ESES", Key: "es-ES", name: "Español" },
        { Code: "FRFR", Key: "fr-FR", name: "Français" },
        { Code: "CAES", Key: "ca-ES", name: "Catalan" },
        { Code: "RORO", Key: "ro-RO", name: "Romanian" },
        { Code: "ARAR", Key: "ar-AR", name: "عربي" },
        { Code: "GORGOR",Key: "gor-GOR", name: "ქართული" },
        { Code: "NLNL", Key: "nl-NL", name: "Nederlands" },
        { Code: "AZAZ", Key: "az-AZ", name: "Azərbaycanlı" },
        { Code: "ITIT", Key: "it-IT", name: "Italiano" },
        { Code: "RURU", Key: "ru-RU", name: "Русский" },

    ];





    /**
     * Constructor
     *
     * @param {TranslateService} _translateService
     */
    constructor(private _translateService: TranslateService) { }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Translate
     *
     * @param {Locale} args
     */
    translate(...args: Locale[]): void {
        const locales = [...args];

        locales.forEach(locale => {
            // use setTranslation() with the third argument value as true to append translations instead of replacing them
            this._translateService.setTranslation(locale.lang, locale.data, true);
        });
    }

    /**
    * Current Lang
    *
    */
   get currentLang(): string {
        return this._translateService.currentLang;
    }
     get getCurrentLangCompatibleWithDevExp():string {
        let lang = this._translateService.currentLang;
        if (lang == "GORGOR") {
            return  "go";
        }
        else {
            return lang.toLowerCase().substring(0, 2);;

        }
    }


}
