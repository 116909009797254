import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { MailhtmlModel } from '@shared/models/mailhtml-model';
import { MailKanbanModel } from '@shared/models/mailKanbanHeader-model';

@Injectable({
    providedIn: 'root'
})
export class MailService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    getHtmlBody(_guid: string): Observable<any> {
        let params = new HttpParams().set("guid", _guid);

        return this.http.get<any>(this.appInfo.appUrl + "api/mail/gethtmlbody", { params: params });
    }

    getSmtp(): Observable<any> {
        return this.http.get<any>(this.appInfo.appUrl + "api/mail/getsmtp", this.appInfo.httpOptions);
    }

    postHtmlBody(model:any): Observable<MailhtmlModel> {
        return this.http.post<MailhtmlModel>(this.appInfo.appUrl + "api/mail/posthtmlbody", JSON.stringify(model), this.appInfo.httpOptions);
    }

    postMail(model: any): Observable<MailKanbanModel> {
        var test = {
                Id: 1,
                Userid :1,
                Guid :'TEST',
                Htmlbody:'DENEME'
            }
        return this.http.post<MailKanbanModel>(this.appInfo.appUrl + "api/mail/postkanbanmail", JSON.stringify(model), this.appInfo.httpOptions);
    }

}
