import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { SchemeModel } from '@shared/models/scheme-model';
import { joblistChatCountModel } from '../../../app/pages/home/joblist_chat.model';
import { EventEmitter } from 'events';
import { EventEmitterService } from '../common';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    constructor(public appInfo: AppInfoService, private http: HttpClient, private eventEmitterService: EventEmitterService) {
         
    }

    getCategoryList() {
        return this.http.get(this.appInfo.appUrl + "api/task/getcategorylist", this.appInfo.httpOptions);
    }

    getActiveFormCategoryList() {
        return this.http.get(this.appInfo.appUrl + "api/task/getactiveformcategories", this.appInfo.httpOptions);
    }

    addFormCategory(name: string): Observable<any> {
        const requestBody = { Name: name };
        return this.http.post(this.appInfo.appUrl + "api/task/addformcategory", requestBody, this.appInfo.httpOptions);
    }

    getSchemeList(): Observable<SchemeModel[]> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getschemalist", { params: params });
    }

    getJustSchemeList(): Observable<SchemeModel[]> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getjustschemalist", { params: params });
    }

    getSidebarMenu(): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getsidebarmenulist", { params: params });
    }

    getMenuList(): Observable<any> {
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getmenulist", this.appInfo.httpOptions);
    }
    getSchedulerMenuAndFormDatas(): Observable<any> {
        return this.http.get<SchemeModel[]>(this.appInfo.appUrl + "api/task/getschedulermenuandformdatas", this.appInfo.httpOptions);
    }

    SetFavorite(schemeId): Observable<any> {

        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("schemeId", schemeId);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/updatefavorite", { params: params });
    }
    addClickedUsersForChat(userId:number,instanceId:number) {
       
        return this.http.post<any>(`${this.appInfo.appUrl}api/task/addclickedusersforchat?userId=${userId}&instanceId=${instanceId}`, this.appInfo.httpOptions);
    }
    


    GetProcessStarterId(processFormId): Observable<any> {

        let params = new HttpParams().set("processFormId", processFormId);
        
        return this.http.get<any>(this.appInfo.appUrl + "api/task/getprocessstarterid", { params: params });
    }
    setTaskChatData(model: any) {
        
        return this.http.post<any>(this.appInfo.appUrl + "api/task/settaskchatdata", JSON.stringify(model), this.appInfo.httpOptions);
    } 
    saveJobListFilter(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/task/savejoblistfilter", JSON.stringify(model),this.appInfo.httpOptions);
    } 
    updateTaskChatData(id: number, content: string) {
        return this.http.post<any>(`${this.appInfo.appUrl}api/task/updatetaskchatdata?id=${id}&content=${content}`, this.appInfo.httpOptions);
    }
    deleteTaskChatData(id: number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/task/deletetaskchatdata?id=" + id, this.appInfo.httpOptions);

    }
    getTaskChatData(instanceId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/task/gettaskchatdata?instanceId=" + instanceId, this.appInfo.httpOptions);

    }
    getJobListFilter(userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/task/getjoblistfilter?userId=" + userId, this.appInfo.httpOptions);
    }

    updateJoblistFilter(model: any) {
        return this.http.post<any>(this.appInfo.appUrl + "api/task/updatejoblistfilter", JSON.stringify(model), this.appInfo.httpOptions)
    }
 

    async GetFavoriteList(): Promise<any[]> {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id);
        try {
            const response = await this.http.get<any>(this.appInfo.appUrl + "api/task/getfavoritelist", { params: params }).toPromise();
            return response;
        } catch (error) {
            console.log("GetFavoriteList error:", error);
            throw error;
        }
    }


    getTaskList(userId, filter) { //paging �ncesi,art�k kullan�lm�yor
         
        let params = new HttpParams().set("uId", userId).set("filter", filter);
        
        return this.http.get(this.appInfo.appUrl + "api/task/gettasks", { params: params });
    }

    getHistoryPage(id) { //paging �ncesi,art�k kullan�lm�yor
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("type", id);
        
        return this.http.get(this.appInfo.appUrl + "api/task/gethistorypage", { params: params });
    }
    getWaitingJobsData(userId: number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/task/getwaitingjobsdata?userId=", userId, this.appInfo.httpOptions);

    }
 
    setUserPhoto(tasks) {
      
        const userMap = {};
        this.appInfo.RiverEntity.users.forEach(user => {
            userMap[user.id] = user;
        });

        const inactiveUser = {
            fullname: "Aktif olmayan kullan�c�",
            email: "",
            hasPhoto: false
        };

        tasks.forEach(data => {
            const userId = data.startedUserId;
            const user = userMap[userId] || inactiveUser;

            user.PhotoUrl = user.hasPhoto? `${this.appInfo.appUrl}api/filemanagement/getUserPhoto?id=${userId}&type=1` : null;
            data.startedUserEntity = user;
        });
    }
  

    getNewTaskListWithPaging(pagingObj: any) {
        this.eventEmitterService.openLoadingEvent(true);
        return new Promise((resolve, reject) => {
            return this.http.post(this.appInfo.appUrl + "api/task/gettaskswithpaging", JSON.stringify(pagingObj), this.appInfo.httpOptions)
                .subscribe(
                    (response: any) => {
                        if (response.data != null) {
                            response.data.map(f => {
                                f.formname = f.form.name
                                f.formstatus = f.form.status
                            });
                            this.setUserPhoto(response.data);
                          }

                        resolve(response);
                    }, (error: any) => {
                        reject(error)
                    }
                )
        })
    }

    setHeaderFilters(obj) {
        let formNoHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let formNameHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let startedUserHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let startDateHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        let formTimeoutHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
      
        let allAssignedDateHeaderFilter = {
            dataSource: [] as { text: string; value: [string, string, string] }[]
        };
        try {
            const instanceNumbers = obj.data.map(f => f.instanceNumbers);
            instanceNumbers[0].split(',').forEach(number => {
                formNoHeaderFilter.dataSource.push({
                    text: number,
                    value: ["formNo", "=", number]
                });
            });

            const startDates = obj.data.map(f => f.allStartDates);
            startDates[0].split(',').forEach(date => {
                startDateHeaderFilter.dataSource.push({
                    text: date,
                    value: ["startDate", "=", date]
                });
            });
            const timeoutValues = obj.data.map(m => m.allFormTimeoutDates);

            if (timeoutValues[0] !== "") {
                const firstElements = timeoutValues[0].split(',');

                firstElements.forEach(timeoutValue => {
                    const trimmedValue = timeoutValue.trim();
                    const spaceIndex = trimmedValue.indexOf(' ');

                    formTimeoutHeaderFilter.dataSource.push({
                        text: spaceIndex !== -1 ? trimmedValue.substring(0, spaceIndex) : trimmedValue,
                        value: ["formTimeout", "=", trimmedValue]
                    });
                });
            }
         

            
            
          

            const formNames = obj.data.map(f => f.allFormNames);
            formNames[0].split(',').forEach(formName => {
                formNameHeaderFilter.dataSource.push({
                    text: formName,
                    value: ["formname", "contains", formName]
                });
            })

            const names = obj.data.map(n => n.allNames);
            names[0].split(',').forEach(headerData => {
                startedUserHeaderFilter.dataSource.push({
                    text: headerData,
                    value: ["startedUserEntity.fullName", "=", headerData]
                });
            });
          
            const AllAssignedDate = obj.data.map(n => n.allAssignedDate);
            AllAssignedDate[0].split(',').forEach(headerData => {
                allAssignedDateHeaderFilter.dataSource.push({
                    text: headerData,
                    value: ["assignedDate", "=", headerData]
                });
            });


        }
        catch(ex) {

        }
        
   
       
        return { formNoHeaderFilter, formNameHeaderFilter, startedUserHeaderFilter, formTimeoutHeaderFilter, startDateHeaderFilter, allAssignedDateHeaderFilter };
    }
    getUserHideJoblistTabsData(profileId: number) {
        return this.http.get(`${this.appInfo.appUrl}api/task/getusercustomjoblistdata?profileId=${profileId}`, this.appInfo.httpOptions);
    }
    setUserHideJoblistData(profileId: number, value: boolean) {
        
        return this.http.post(`${this.appInfo.appUrl}api/task/setuserhidejoblisttabsdata?profileId=${profileId}&value=${value}`, this.appInfo.httpOptions);
    }
   
    setJobListUserPhotoSettingsData(profileId: number, value: boolean) {
        
       return this.http.post(`${this.appInfo.appUrl}api/task/setjoblistuserphotosettingsdata?profileId=${profileId}&value=${value}`, this.appInfo.httpOptions);
    }

    setJobListAutoRefreshData(profileId: number, value: boolean) {

        return this.http.post(`${this.appInfo.appUrl}api/task/setjoblistautorefreshdata?profileId=${profileId}&value=${value}`, this.appInfo.httpOptions);
    }

    deleteFavoriteScheme(userId: number, schemeId: number) {
        debugger
        let params = new HttpParams().set("userId", userId).set("schemeId", schemeId);
        return this.http.get<any>(this.appInfo.appUrl + "api/task/deletefavoritescheme", { params: params });

    }

    //getJoblistChatCount(idList: string): Observable<any> {

    //    return this.http.get(`${this.appInfo.appUrl}api/task/getjoblistchatcount?idList=${idList}`, this.appInfo.httpOptions);
    //}

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }
}
