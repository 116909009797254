
import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { EventEmitterService, AppInfoService, ComponentService } from '@shared/services/common';
import { FormService } from '@shared/services/api';
import { MenuNode } from '@shared/models/menu-node-model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MenuService {

    constructor(private eventEmitterService: EventEmitterService, public componentService: ComponentService, private formService: FormService,
        private appInfo: AppInfoService, private httpClient: HttpClient) {

    }

    currentFormData = [];
    createValuesData(formId, lastId, components) {

        let Id = lastId == 0 ? 1 : lastId + 1;
        for (let i = 0; i < components.length; i++) {
            if (components[i].isInput == true || components[i].inputType == "datagrid"
                || components[i].inputType == "file" || components[i].inputType == "map"
                || components[i].type == "gallery"
                || components[i].type == "codeeditor"
                || components[i].type == "htmleditor"
                || components[i].type == "comment"
                || components[i].type == "diagram") {

                var obj: any = {};
                obj["key"] = components[i].id;
                obj["value"] = components[i].value;
                obj["type"] = components[i].inputType;
                obj["componentType"] = components[i].type;
                if (components[i].type == "textbox") {
                    //console.log(obj)
                    if (components[i].systemInfoId != 0 && components[i].systemInfoId != 4 && obj["value"] == "") {
                        let systemInfoObject = this.componentService.systemInfoElements.find(el => el.id == components[i].systemInfoId)
                        let emitValue = ""
                        if (systemInfoObject.id == 1) {
                            emitValue = this.appInfo.currentUser.fullName
                        } else if (systemInfoObject.id == 2) {
                            emitValue = this.appInfo.currentUser.id
                        } else if (systemInfoObject.id == 3) {
                            const date = new Date();
                            emitValue = date.getDate() + "." + date.getMonth() + "." + date.getFullYear();
                        } else if (systemInfoObject.id == 5) {
                            emitValue = formId
                        }
                        obj["value"] = emitValue;
                    }

                    obj["isAuto"] = components[i].isAuto;
                    obj["autoText"] = components[i].autoText;
                    if (components[i].isAuto && obj["value"] == "") {
                        obj["value"] = components[i].autoText + Id
                        // _thisPageLocal.cellInfoGlobal.data[key] = isAutoTextVal;
                    }
                    else { }

                }
                if (components[i].inputType == "datagrid") {

                    var gridVal = components[i].value;
                    var lastId = gridVal.length > 0 ? gridVal[gridVal.length - 1].ID : 0;
                    obj["lastid"] = lastId;
                    var headerAndType = this.getGridHeaders(components[i]);
                    obj["headers"] = headerAndType.headers;
                    obj["headerTypes"] = headerAndType.headerTypes;
                    obj["rowCount"] = components[i].value.length;
                    obj["selectedRowCount"] = components[i].selectedRows;
                    obj["userChangeColumns"] = components[i].userChangeColumns;
                }
                else if (components[i].inputType == "map") {
                    obj["markers"] = this.componentService.cloneModel(components[i].markers);
                }
                else if (components[i].type == "diagram") {
                    obj["exportIds"] = typeof components[i].exportIds == "undefined" ? [] : components[i].exportIds
                }
                else {
                    obj["headers"] = "";
                    obj["rowCount"] = 0;
                    obj["selectedRowCount"] = 0;
                    obj["userChangeColumns"] = [];
                }
                this.currentFormData.push(obj);
            }
            else if (components[i].type == "grid") {
                for (let j = 0; j < components[i].rows.length; j++) {
                    for (let k = 0; k < components[i].rows[j].cols.length; k++) {
                        this.createValuesData(formId, lastId, components[i].rows[j].cols[k].components);
                    }
                }
            }
            else if (components[i].type == "panel" || components[i].type == "tabs") {
                for (let j = 0; j < components[i].items.length; j++) {
                    this.createValuesData(formId, lastId, components[i].items[j].components);
                }
            }
        }

        return this.currentFormData

    }

    getGridHeaders(grid) {

        let headers = [];
        let headerTypes = [];
        grid.headers.forEach(header => {
            var obj: any = {};
            //if (header.type != "file") {

            headers.push(header.field);
            headerTypes.push(header.type);
            //}
        });
        return { headers: headers.join(","), headerTypes: headerTypes.join(",") };
    }



    addDataToGrid(_Status, _Name, formId, pageId, schemaId, components, anotherPopupId, selectedDataGridRowIndex = -1, selectedDataGridItem = { data: undefined }) {

        let ffff = this;
        return new Promise(function (resolve, reject) {//(resolve, reject) => {
            //let _components = this.componentService .getComponentList(this.componentService .currentForm.components, 0);
            let componentSelect = [];
            let _componentAutoText = [];


            components.forEach(el => {
                if (el.type == "selectbox")
                    componentSelect.push(el);
                if (el.type == "textbox" && el.isAuto)
                    _componentAutoText.push(el);
            });

            let newData: any = {};
            for (let i = 0; i < this.currentFormData.length; i++) {
                let field = this.currentFormData[i];
                if (field.componentType == "selectbox") {
                    let componentText = "";
                    let componentTextObject = componentSelect.find(x => x.id == field.key);
                    if (componentTextObject) {
                        let compDatasourceObj;
                        if (componentTextObject.valueExpr)
                            compDatasourceObj = componentTextObject.dataSource.find(x => x[componentTextObject.valueExpr] == field.value);
                        else
                            compDatasourceObj = componentTextObject.dataSource.find(x => x.ID == field.value);

                        componentText = compDatasourceObj ? compDatasourceObj.text : "";
                    }

                    newData[field.key] = field.value;
                }
                if (field.componentType == "picturebox") {

                    if (!newData["_ImagePictureboxlist_"]) newData["_ImagePictureboxlist_"] = [];
                    let _selectboxlist_ = newData["_ImagePictureboxlist_"].find(x => x == field.key);
                    if (_selectboxlist_)
                        _selectboxlist_ = field.key;
                    else
                        newData["_ImagePictureboxlist_"].push(field.key);

                    newData[field.key] = field.value;
                }
                if (field.componentType == "map") {
                    newData[field.key] = { markers: this.componentService.cloneModel(field.markers) };
                } else {

                    newData[field.key] = field.value;
                }
            }

            if (selectedDataGridRowIndex >= 0) {


                newData.ID = selectedDataGridItem.data[selectedDataGridRowIndex].ID;//  "@RIVER_MENU_ROW_ID_TEMP @";
                newData.IsChecked = selectedDataGridItem.data[selectedDataGridRowIndex].IsChecked;
                newData.Disabled = selectedDataGridItem.data[selectedDataGridRowIndex].Disabled;
                selectedDataGridItem.data.splice(selectedDataGridRowIndex, 1, newData);
            }

            else {
                if (typeof selectedDataGridItem.data === "undefined")
                    selectedDataGridItem.data = [];
                newData.ID = "@RIVER_MENU_ROW_ID_TEMP@";//this.selectedDataGridItem.data.length == 0 ? 1 : (this.selectedDataGridItem.data[this.selectedDataGridItem.data.length - 1].ID + 1);
                newData.IsChecked = false;
                newData.Disabled = false;
                selectedDataGridItem.data.push(newData);
            }

            _componentAutoText.forEach(el => {
                newData[el.id] = el.autoText + newData.ID;
            });


            selectedDataGridRowIndex = -1;
            //this.showFormRenderPopup = false;    BU MENU DE SAYFADA YAZILACAK (EĞER MENU BURAYI KULLANACAKSA...)

            this.saveMenuTemp(JSON.stringify(newData), _Status, _Name, formId, pageId, schemaId, components, anotherPopupId).then(val => {
                resolve(val);
            });
        }.bind(this));
    }

    saveMenuTemp(rowData, status, name, formId, pageId, schemaId, components, anotherPopupId, saveType = "save") {
        return new Promise(function (resolve, reject) {
            let menuNode = new MenuNode();
            menuNode.FormId = parseInt(formId);
            menuNode.NodeKey = pageId;
            menuNode.NodeData = rowData;
            menuNode.FormJson = JSON.stringify({ data: this.currentFormData });
            menuNode.Name = "";
            menuNode.Status = parseInt(status);
            menuNode.ButtonName = name;
            menuNode.UserId = this.appInfo.currentUser.id;
            menuNode.SchemaId = parseInt(schemaId);


            let fileObjArray: any = []
            if (saveType != "clone") {
                fileObjArray = this.filesProcess(components);
            }

            let filesIds = []

            if (fileObjArray.length > 0) {

                filesIds = fileObjArray.map(ele => ele.fileId);

            }


            if (fileObjArray.length > 0) {
                this.formService.saveMenuFilesData(fileObjArray, 0, []).subscribe(response => {
                    //
                    if (response.status == true) {
                        if (response.files.length > 0) {

                            response.files.map(ele => filesIds.push(ele.Id));
                            this.currentFormData.forEach(formData => {

                                let _fileVal = response.files.filter(x => x.ComponentId == formData.key);

                                if (_fileVal != null && typeof _fileVal != "undefined" && _fileVal.length > 0) {
                                    if (formData.type == "datagrid") {
                                        _fileVal.forEach(_fileValObj => {
                                            let _tempData = formData.value.find(x => x.ID == parseInt(_fileValObj.RowId));
                                            if (_tempData)
                                                _tempData[_fileValObj.ColumnId] = _fileVal.filter(z => z.RowId == _fileValObj.RowId && z.ColumnId == _fileValObj.ColumnId).map(ele => ele.Id);
                                        });
                                    } else {
                                        _fileVal.map(ele => formData.value.push(ele.Id));
                                    }

                                }

                            });


                        }
                        this.saveMenuForm(menuNode, filesIds, components, anotherPopupId).then(val => {
                            resolve(val);
                        });
                    }
                    else
                        resolve(null);
                })
            } else {
                this.saveMenuForm(menuNode, filesIds, components, anotherPopupId).then(val => {
                    resolve(val);
                });
            }
        }.bind(this));
    }

    saveMenuForm(menuNode, filesIds, components, anotherPopupId) {
        return new Promise(function (resolve, reject) {
            let menuDataArray = JSON.parse(menuNode.NodeData)

            Object.keys(menuDataArray).forEach(element => {
                //
                this.currentFormData.forEach(formData => {
                    //
                    if (element == formData.key && formData.isAuto != true) {
                        menuDataArray[element] = formData.value
                    }

                })

            })

            //let _components = this.componentService.getComponentList(components, anotherPopupId);
            let textComponents: any[] = components.filter(f => f.type == "textbox")
            if (textComponents.length > 0) {
                textComponents.forEach(textComponent => {
                    let systemInfoElement = this.componentService.systemInfoElements.find(systemInfo => systemInfo.id == textComponent.systemInfoId)
                    if (typeof systemInfoElement != "undefined" && systemInfoElement.value != "") {
                        menuDataArray[textComponent.id] = systemInfoElement.value
                    }
                })
            }

            menuNode.NodeData = JSON.stringify(menuDataArray)



            this.formService.saveMenuForm(menuNode, filesIds).subscribe((result: any) => {
                resolve(result)
                //BURADAN AŞAĞISI MENU DE SAYFADA YAZILACAK(EĞER MENU BURAYI KULLANACAKSA...)
                //if (this.formIsPicturebox) {
                //    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                //    this.router.onSameUrlNavigation = 'reload';
                //    this.router.navigate([this.router.url], { state: { pageUuid: this.componentService .createuuid() } });
                //}
                //else {
                //    this.getMenuForm();
                //}

                //this.instanceService.changeMenuFormAvability(this.formId, this.pageId, this.cellInfoKeyTemp, true).subscribe(result => { });
            }, err => {
                resolve(null)
                //this.hideLoadPanel();
            });
        }.bind(this));
    }

    filesProcess(_components) {

        //let currentFormSelectedGlobal = this.componentService.currentFormSelected(0);   currentFormSelectedGlobal.components
        let curentFormFiles = _components.filter(x => x.type == "file"); //:      this.componentService.getFilesComponentList(_components, 0);
        let fileArray = [];

        curentFormFiles.forEach(file => {

            if (typeof file.valueTemp != "undefined" && file.valueTemp != "") {

                file.valueTemp.forEach(fileVal => {
                    //value array içine gir
                    //base64BlobDat a
                    //extension 
                    if (typeof fileVal.id == "undefined" || fileVal.id == 0) {
                        let obj: any = {
                            fileId: 0,
                            componentId: file.id,
                            rowId: "-1",
                            columnId: "-1",
                            fileData: fileVal.base64BlobData,
                            fileExtension: fileVal.extension,
                            fileType: fileVal.fileType,
                            isImage: fileVal.isImg,
                            name: fileVal.name,
                            size: fileVal.size,
                            description: fileVal.description
                        };

                        if (typeof obj.description == "undefined") {
                            obj.description = ""
                        }

                        fileArray.push(obj);
                    }
                    else {
                        let obj: any = {
                            fileId: fileVal.id,
                            componentId: "",
                            rowId: "",
                            columnId: "",
                            fileData: "",
                            fileExtension: "",
                            fileType: "",
                            isImage: false,
                            name: "",
                            size: 0,
                            description: fileVal.description
                        };

                        if (typeof obj.description == "undefined") {
                            obj.description = ""
                        }

                        fileArray.push(obj);
                    }

                });
            }
            //Eğer valueTemp undefined durumunda ise boş array olarak set ediyoruz.
            file.valueTemp = [];
        });

        return fileArray;
    }

    getMenuDataByPaging(pagingObj: any) {
        return new Promise((resolve, reject) => {
            return this.httpClient.post(this.appInfo.appUrl + "api/menu/data", JSON.stringify(pagingObj), this.appInfo.httpOptions)
                .subscribe(
                    (response: any) => {
                        if (response.data != null) {
                            var obj = {}
                            response.data.forEach(el => {
                                obj = JSON.parse(el.etr_menu_form_data)
                                Object.keys(obj).forEach(key => {
                                    el[key] = obj[key]
                                })
                            })
                        }
                       
                        resolve(response)
                    }, (error: any) => {
                        reject(error)
                    }
                )
        })
    }

    getAllMenuData(pagingObj: any) {
        return new Promise((resolve, reject) => {
            return this.httpClient.post(this.appInfo.appUrl + "api/menu/all", JSON.stringify(pagingObj), this.appInfo.httpOptions)
                .subscribe(
                    (response: any) => {
                        resolve(response)
                    }, (error: any) => {
                        reject(error)
                    }
                )
        })
    }


}
