import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { CoreSidebarComponent } from '@core/components/core-sidebar/core-sidebar.component';

@NgModule({
    imports: [CommonModule],
    declarations: [CoreSidebarComponent],
    exports: [CoreSidebarComponent]
})
export class CoreSidebarModule {

}
