import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule, DxButtonComponent, DxButtonModule, DxHtmlEditorModule, DxPopupModule, DxScrollViewModule, DxToolbarModule } from 'devextreme-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RiverFormModule } from '../../formdesigner/riverform.module';
import { DxoMediaResizingModule } from 'devextreme-angular/ui/nested';
import { HtmlEditorComponent } from './htmlEditor.component';
import { FormService, GenericService, InstanceService } from '../../../@shared/services/api';



@NgModule({
    declarations: [
        HtmlEditorComponent
    ],
  imports: [
      CommonModule,
      DevExtremeModule,
      ReactiveFormsModule,
      FormsModule,
      DxButtonModule,
      DxPopupModule,
      DxHtmlEditorModule,
      DxScrollViewModule,
      DxToolbarModule,
      DxoMediaResizingModule,
    ],
    exports: [DevExtremeModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    providers: [InstanceService, GenericService, FormService]
})
export class HtmlEditorModule { }
