export const locale = {

    lang: 'FRFR',
  
    data: {
  
        loginButton: "Connexion",
        userName: "Nom d'utilisateur",
        pass: "Mot de passe",
        remember: "Se souvenir de moi",
        forgotPass: "Mot de passe oublié",
        modules: "Modules",
        generic: "Général",
        generateLink: "générer un lien",
        fileLink: "Lien de fichier",
        givePasswordToTheLink: "donner le mot de passe au lien",
        giveTheLinkAnExpirationDate: "Donnez au lien une date d'expiration",

        search: "Recherche",
        logout: "Déconnexion",
        waitingJob: "Mon travail en attente",
        myStarted: "J'ai commencé",
        myIncluded: "Je suis inclus",
        myCompleted: "Travaux terminés",
        jobList: "Liste des tâches",
        formNo: "Numéro de formulaire",
        status: "Statut",
        starter: "Démarré",
        assigned: "Assigné",
        timeoutDate: "Date limite",
        startDate: "Date de début",
        processing: "En cours",
        you: "vous",
        history: "Historique",
        description: "Description",
        date: "Date",
        jobListMessageArea:"zone de message de la liste de tâches",
        growShrink: "Agrandir/Réduire",
        close: "Fermer",
        gridReset: "Réinitialiser les paramètres de la grille",
        columnSelect: "Sélection de colonne",
        jobName: "Nom de la tâche",
        keyword: "Mot-clé",
        completion: "Achèvement",
        all: "Tout",
        completedDate: "Date de fin",
        completions: "Terminé",
        ongoing: "En cours",
        formName: "Nom du formulaire",
        sunday: "Dimanche",
        monday: "Lundi",
        tuesday: "Mardi",
        wednesday: "Mercredi",
        thursday: "Jeudi",
        friday: "Vendredi",
        saturday: "Samedi",
        pdfExport: "Exporter en PDF",
        sendPassword: "Envoyer mon mot de passe",
        assignedDate: "Date d'assignation",
        events: "Événements",
        new: "Nouveau",
        save: "Enregistrer",
        formula: "Formule",
        selectDataSource: "Sélectionner la source de données",
        requiredField: "Champ obligatoire",
        suffix: "Suffixe",
        valueColumn: "Colonne de valeur",
        showLength: "Afficher le nombre de tâches dans les colonnes",
        clearButton: "Bouton Effacer",
        allCaps: "Tout en majuscules",
        autoAsc: "Tri automatique croissant",
        
        width: "Largeur",
        length: "Longueur",
        functions: "Fonctions",
        selectElement: "Sélectionner l'Élément",
        selectColumn: "Sélectionner la Colonne",
        edit: "Éditer",
        changeEventActions: "Modifier les Actions d'Événement",
        barcode: "Code-barres",
        systemInfo: "Information Système",
        prefix: "Préfixe",
        max: "Maximum",
        showMinMaxInfo: "Afficher les informations Minimum et Maximum",
        selectUser: "Sélectionner un Utilisateur",
        spinButton: "Bouton de Rotation",
        numberIncDecButton: "Bouton Augmenter-Diminuer le Nombre",
        decimal: "Nombre Décimal",
        numeral: "Numéro",
        wrongFormat: "vous avez entré le mauvais format",
        height: "Hauteur",
        microphone: "Microphone",
        notifyDataReset: "Réinitialisation des Données",
        data: "Données",
        dynamicData: "Données Dynamiques",
        manualData: "Données Manuelles",
        selectDataGrid: "Sélectionner Datagrid",
        overrideUserChange: "Écraser les modifications de l'utilisateur",
        textColumn: "Colonne de Texte",
        makeInitialValue: "Valeur Initiale par Défaut",
        undo: "Annuler",
        copy: "Copier",
        preview: "Aperçu",
        delete: "Supprimer",
        cancel: "Annuler",
        languageSettings: "Paramètres de Langue",
        messageSqlLinkDeleted: "Comme vous avez ajouté des données manuellement, le script SQL et l'adresse du lien seront supprimés ! Continuer ?",
        messageSqlLinkDeletedForMenu: "La sélection des données en tant que menu entraînera la suppression du fichier de script SQL et de l'adresse du lien ! Souhaitez-vous continuer ?",
        permissions: "Permissions",
        objectid: "ID d'Objet",
        fontSize: "Taille de Police",
        fontWidth: "Largeur du Texte",
        fontStyle: "Style de Police",
        underline: "Souligné",
        textColor: "Couleur du Texte",
        visibility: "Visibilité",
        textAlignment: "Alignement du Texte",
        settings: "Paramètres",
        messageUnsaved: "Êtes-vous sûr de vouloir quitter sans enregistrer vos modifications ?",
        warning: "Avertissement !",
        userAuthorizations: "Autorisations d'Utilisateur",
        formPreferences: "Préférences du Formulaire",
        formOpeningevent: "Événement d'Ouverture du Formulaire",
        chooseConnectionAddress: "Choisir l'Adresse de Connexion",
        selectPriorty: "Sélectionner la Priorité",
        loadingEditor: "Chargement de l'Éditeur...",
        showAddButton: "Afficher le bouton Ajouter une ligne",
        update: "Mettre à Jour",
        connectionID: "ID de Connexion",
        formPreview: "Aperçu du Formulaire",
        nameDisplayedform: "Nom du Formulaire Affiché",
        formNamevariable: "Variable de Nom du Formulaire",
        category: "Catégorie",
        formColor: "Couleur du Formulaire",
        generalForm: "Formulaire Général",
        saveForm: "Enregistrer le Formulaire",
        messageNeworupdate: "Les modifications apportées au formulaire existant seront supprimées. Continuer ?",
        messageDelete: "Êtes-vous sûr de vouloir supprimer le formulaire ?",
        toastDeleted: "Formulaire Supprimé",
        toastError: "Désolé, une erreur s'est produite lors du traitement",
        alertDifferentID: "Cet identifiant est utilisé ailleurs, veuillez spécifier un identifiant différent.",
        confirmChanges: "Confirmer les modifications",
        toastUserpermission: "L'utilisateur n'a pas l'autorisation de concevoir le formulaire.",
        toastCodeeditor: "L'éditeur de code existe déjà",
        messageDeletesure: "Les éléments de ce composant seront supprimés",
        messageSure: "Êtes-vous sûr ?",
        successful: "Succès",
        error: "Erreur",
        toastFormupdate: "Formulaire Mis à Jour",
        toastMistake: "Désolé, une erreur s'est produite lors du traitement",
        toastSaved: "Enregistré",
        toastCopied: "Copié",
        toastEmptyFormMessage: "Aucun élément de formulaire ajouté pour le moment",
        toastGiveName: "Veuillez donner un nom à votre formulaire",
        toastGiveVariableName: "Veuillez donner un nom à la variable de nom du formulaire",
        
  
        toastVariableNameNumberWarning: "Le nom de la variable ne peut pas commencer par un nombre",
        formCopy: "Copier le Formulaire",
        messageButtonDelete: "Êtes-vous sûr de vouloir supprimer le bouton ?",
        noDataFound: "Aucune Donnée Trouvée",
        language: "Langue",
        filterDatagrid: "Filtrage du Datagrid",
        addManualData: "Ajouter des Données Manuelles en Cours d'Exécution",
        dateFormat: "Format de Date",
        messageSqlLinkDelete: "Parce que vous avez ajouté des données manuellement, le script SQL et l'adresse du lien seront supprimés ! Continuer ?",
        timeOption: "Option d'Horloge",
        defaultTodaysDate: "Laisser la date d'aujourd'hui par défaut",
        dateRange: "Plage de Dates",
        minToday: "Minimum Aujourd'hui",
        maxToday: "Maximum Aujourd'hui",
        dateRangeDay: "Plage de Dates (Jour)",
        enterDateRangeValue: "Entrer la valeur de la plage de dates",
        imageIdColumn: "Colonne d'ID",
        statusSettings: "Paramètres de Statut",
        active: "Actif",
        passive: "Passif",
        enable: "Activé",
        disable: "Désactivé",
        visible: "Visible",
        invisible: "Invisible",
        items: "Éléments",
        showItems: "Afficher les Éléments",
        array: "tableau",
        letLabelAppear: "Afficher l'Étiquette",
        add: "ajouter",
        horizontal: "horizontal",
        vertical: "vertical",
        text: "Texte",
        alertFillRequiredFields: "Veuillez remplir les champs obligatoires",
        alertSignatureField: "Le champ de signature ne peut pas être vide",
        messageApproveTransaction: "Approuvez-vous la transaction ?",
        button: "Bouton",
        reasonforRefusal: "Motif de Rejet",
        enterReasonRejection: "Saisir le Motif de Rejet",
        reject: "Rejeter",
        addDeleteButton: "Ajouter/Supprimer le Bouton",
        showBorder: "Afficher la Bordure",
        buttons: "Boutons",
        clean: "effacer",
        buttonSettings: "Paramètres du Bouton",
        saveLocation: "Emplacement d'Enregistrement",
        paymentButton: "Bouton de Paiement",
        paymentProvider: "Fournisseur de Paiement",
        price: "Prix",
        numberOfInstallments: "Nombre de Mensualités",
        descriptionField: "Champ de Description",
        current: "Actuel",
        currentForm: "Formulaire Actuel",
        name: "Nom",
        surname: "Nom de Famille",
        country: "Pays",
        city: "Ville",
        address: "Adresse",
        authorizations: "Autorisations",
        users: "Utilisateurs",
        user: "Utilisateur",
        givePermission: "Autoriser",
        groups: "Groupes",
        group: "Groupe",
        
        saveandSend: "Enregistrer et Envoyer",
        approve: "Approuver",
        sendBack: "Renvoyer",
        deslineAndEnd: "Rejeter et Terminer",
        complete: "Terminé",
        blue: "Bleu",
        gray: "Gris",
        green: "Vert",
        red: "Rouge",
        left: "Gauche",
        right: "Droite",
        color: "Couleur",
        symbol: "Symbole",
        position: "Position",
        action: "Action",
        total: "Total",
        filePath: "Chemin du Fichier",
        fileName: "Nom du Fichier",
        fileUpload: "Chargement de Fichier",
        choose: "Choisir",
        updateData: "Mettre à Jour les Données",
        show: "Afficher",
        columns: "Colonnes",
        changeEvent: "Événement de Changement",
        columnSettings: "Paramètres de Colonne",
        columnName: "Nom de Colonne",
        deleteColumn: "Supprimer la Colonne",
        columnSqlScripts: "Scripts SQL de Colonne",
        imageNameColumn: "Colonne de Nom de Fichier",
        columnLanguageSetting: "Paramètre de Langue de Colonne",
        dataSource: "Source de Données",
        filter: "Filtrer",
        reset: "Réinitialiser",
        column: "Colonne",
        image: "Image",
        searchLocation: "Recherche d'Emplacement...",
        draggable: "Déplaçable",
        usersCanChange: "Les Utilisateurs Peuvent Modifier",
        multiMarker: "Marqueur Multiple",
        markerLimit: "Limite de Marquage (0: Pas de Limite)",
        meetingType: "Type de Réunion",
        meetingName: "Nom de Réunion",
        loadingFiles: "Chargement des fichiers...",
        fileSizeExceeded: "Taille du fichier dépassée",
        filesLoaded: "Fichiers chargés",
        condition: "Condition",
        value: "valeur",
        connectionAddress: "Adresse de Connexion",
        imageTypeColumn: "Colonne de Type de Fichier",
        visibleColumn: "Colonne Visible",
        selectionMode: "Mode de Sélection",
        selectMode: "Mode de Sélection",
        datagridFiltering: "Filtrage du Datagrid",
        imageDataColumn: "Colonne de Fichier",
        selectDataColumn: "Sélectionner la Colonne du Datagrid",
        dataFormat: "Format de Données",
        photoPreview: "Aperçu de la Photo",
        videoPreview: "Aperçu de la Vidéo",
        displayPhoto: "Afficher la Photo",
        displayVideo: "Afficher la Vidéo",
        imageColumn: "Colonne d'Image",
        videoColumn: "Colonne de Vidéo",
        columnNameAppear: "Nom de Colonne à Afficher",
        
        selected: "Sélectionné",
        inStock: "En Stock",
        messageSave: "Êtes-vous sûr de vouloir enregistrer vos modifications?",
        gallery: "Galerie",
        dollar: "dollar",
        sterling: "livre sterling",
        underPhoto: "Sous la Photo",
        topPhoto: "Photo en Haut",
        byCategory: "Par Catégorie",
        dashboardShowHide: "Afficher/Masquer le Tableau de Bord",
        showDetail: "Afficher les Détails",
        hideDetail: "Masquer les Détails",
        decrease: "Diminuer",
        increase: "Augmenter",
        watch: "montre",
        watchMovie: "Regarder un Film",
        noRecordsFound: "Aucun enregistrement trouvé !",
        appearance: "Apparence",
        numberofColumns: "Nombre de Colonnes",
        pagingRecords: "Enregistrements de Pagination",
        detailLocation: "Emplacement des Détails",
        hideImages: "Masquer les Images",
        quantityCategoryDisable: "Désactiver la Catégorie Lorsque la Quantité Augmente",
        general: "général",
        idColumn: "Colonne d'ID",
        availableQuantity: "Quantité Disponible",
        categoryColumn: "Colonne de Catégorie",
        photoColumn: "Colonne de Photo",
        titleColumn: "Colonne de Titre",
        priceColumn: "Colonne de Prix",
        updatablePrice: "Prix Modifiable",
        videoGallery: "Galerie Vidéo",
        uniqueComponent: "Composant Unique",
        showVariant: "Afficher les Variantes",
        transferTotalAmount: "Transférer le Montant Total",
        title: "Titre",
        hide: "Masquer",
        detail: "Détail",
        priceQuery: "Requête de Prix",
        quantityQuery: "Requête de Quantité",
        uniqueComponentError: "Erreur de Composant Unique",
        joinColumn: "Colonne de Jointure",
        nameColumn: "Colonne de Nom",
        variantScript: "Script de Variante",
        variantImages: "Images de Variante",
        variantJoinColumn: "Colonne de Jointure de Variante",
        showAll: "Afficher Tout",
        showSelected: "Afficher les Sélectionnés",
        showByCategory: "Afficher par Catégorie",
        showInStock: "Afficher les Articles en Stock",
        notYetAdded: "Pas encore ajouté",
        added: "Ajouté",
        startingDate: "Date de Début",
        endDate: "Date de Fin",
        themeFeatures: "Fonctionnalités du Thème",
        menu: "Menu",
        selectMenu: "Sélectionner le Menu",
        selectSubmenu: "Sélectionner le Sous-Menu",
        selectMenuBrowser: "Sélectionner le Menu Navigateur",
        nameElement: "Élément de Nom",
        subject: "sujet",
        picture: "image",
        pictureElement: "Élément Image",
        startingDateElement: "Élément de Date de Début",
        endDateElement: "Élément de Date de Fin",
        usersCanEdit: "Les Utilisateurs Peuvent Modifier",
        thisPropertyCant: "Cette propriété NE PEUT PAS être utilisée si les données sont récupérées depuis SQL",
        calendarDisplay: "Propriétés d'Affichage du Calendrier",
        monthly: "Mensuel",
        generalDisplayLunarDays: "Affichage Général des Jours Lunaires",
        agenda: "Agenda",
        listofWeeklyEvents: "Liste des Événements Hebdomadaires",
        weekly: "Hebdomadaire",
        weekend: "Week-end",
        
  
        generalDisplayWeekDays: "Affichage Général des Jours de la Semaine",
        showWeekendDaysWhileWeekdays: "Afficher les Jours de Week-end en Affichant les Jours de Semaine",
        daily: "Quotidien",
        listofDailyEvents: "Liste des Événements Quotidiens",
        startTime: "Heure de Début",
        endTime: "Heure de Fin",
        changeDefaultColor: "Changer les Couleurs par Défaut",
        weekdayColor: "Couleur des Jours de Semaine",
        weekendColor: "Couleur des Jours de Week-end",
        eventColor: "Couleur de l'Événement",
        groupBySql: "Grouper par SQL",
        groupColumnName: "Nom de la Colonne de Groupe",
        assignedColumnName: "Nom de la Colonne Assignée",
        taskPriortiyColumnName: "Nom de la Colonne de Priorité de Tâche",
        taskSubjectColumnName: "Nom de la Colonne de Sujet de Tâche",
        taskDetailColumnName: "Nom de la Colonne de Détail de Tâche",
        taskQuery: "Requête de Tâche",
        taskKeyExpression: "Expression Clé de Tâche",
        taskTitleExpression: "Expression de Titre de Tâche",
        taskStartDateExpression: "Expression de Date de Début de Tâche",
        taskEndDateName: "Nom de la Colonne de Date de Fin de Tâche",
        taskParentExpression: "Expression Parent de Tâche",
        taskProgressExpression: "Expression de Progression de Tâche",
        taskColorExpression: "Expression de Couleur de Tâche",
        taskConnections: "Connexions de Tâche",
        diagramClear: "Êtes-vous sûr de vouloir effacer le diagramme ? Cette action ne peut pas être annulée.",
        uniqueComponentMenuCloneWarning: "Veuillez changer le composant unique avant de copier.",
        filesLoading: "Chargement des fichiers...",
        couldnotbeLoaded: "n'a pas pu être chargé.",
        fileSizeLimit: "Limite de Taille de Fichier",
        filesUploaded: "Fichiers téléchargés",
        filesWillDeleted: "Les fichiers seront supprimés !",
        filesSureDelete: "Êtes-vous sûr de vouloir supprimer les fichiers ?",
        fileDelete: "Voulez-vous supprimer le fichier ?",
        uploadedPhotos: "",
        maxSize: "Taille Maximale",
        center: "Moyen",
        size: "Taille",
        CanItBeUpdated: "peut-il être mis à jour après l'installation ?",
        uploadedFiles: "Fichiers Téléchargés",
        type: "Type",
        showdeleteOldData: "Options de Suppression des Anciennes Données",
        videoBrowser: "Votre navigateur ne prend pas en charge la balise vidéo.",
        deleted: "Supprimé",
        theseComponentElementsDeleted: "Ces éléments de composant seront supprimés.",
        invalidTarget: "Cible invalide",
        ridge: "Ligne Droite",
        Dotted: "Point",
        dashed: "Rayé",
        addRowColumn: "Ajouter Ligne/Colonne",
        dataPagingShow: "Obtenir les Données par Pages",
        borderType: "Type de Bordure",
        maxCharacters: "Nombre Maximum de Caractères",
        edgeThickness: "Épaisseur des Bords",
        lineSpacing: "Espacement des Lignes",
        settingsRow: "Paramètres de Ligne/Colonne",
        addRow: "Ajouter Ligne",
        deleteRow: "Supprimer Ligne",
        rowPermission: "Permissions de Ligne",
        addTabs: "Ajouter des Onglets",
        deletePanel: "Supprimer le Panneau",
        tabPermission: "Permissions d'Onglet",
        addPanel: "Ajouter un Panneau",
        panelPermission: "Permissions de Panneau",
        backgroundColor: "Couleur de Fond",
        saveAndCloseChildForm: "Enregistrer et Fermer ce Formulaire Enfant en Ouvrant un Nouveau Formulaire",
        icon: "Icône",
        selectForm: "Sélectionner le Formulaire",
        fontColor: "Couleur de Police",
        minCharacters: "Nombre Minimum de Caractères",
        generalCategory: "Catégorie Générale",
        removeRow: "Êtes-vous sûr de vouloir supprimer l'enregistrement ?",
        send: "envoyer",
        declineFinish: "Refuser et Terminer",
        finish: "Terminer",
        noData: "Pas de Données",
        createEditMenu: "Créer/Éditer le Menu",
        addNewBreakdown: "Ajouter une Nouvelle Analyse",
        addForm: "Ajouter un Formulaire",
        addBottomBreak: "Ajouter une Analyse en Bas",
        addNode: "Ajouter un Noeud",
        topCategory: "Catégorie Supérieure",
        explanation: "Description",
        browserTitle: "Titre du Navigateur",
        formTitle: "Titre du Formulaire",
        addSqlColumn: "Ajouter une Colonne SQL",
        formBg: "Arrière-plan du Formulaire",
        codeEditor: "Éditeur de Code",
        authorityType: "Type d'Autorisation",
        viewOnly: "Affichage Seulement",
        dark: "Sombre",
        white: "Blanc",
        hightBlack: "Noir",
        run: "Exécuter",
        rowColor: "Couleur de Ligne",
        operator: "Opérateur",
        flowOfForm: "Flux du Formulaire",
        columnWidth: "Largeur de Colonne",
        
  
        alignLeft: "Aligner à gauche",
        alignRight: "Aligner à droite",
        alignButton: "Aligner le Bouton",
        imageTableNameColumn: "Nom de la Table",
        uploadBg: "Télécharger l'Arrière-plan",
        selectFile: "Sélectionner le Fichier",
        dropFile: "Déposez le Fichier ici",
        alwaysRoundUp: "Toujours Arrondir à la Hausse",
        alwaysRoundBot: "Toujours Arrondir à la Baisse",
        default: "Par Défaut",
        deleteOldDataTable: "Supprimer les anciennes données de la table ?",
        deleteOldData: "Supprimer les anciennes données ?",
        integer: "Entier",
        double: "Nombre Décimal",
        pleaseFillFields: "Veuillez remplir les champs requis.",
        toastColumnNameNumber: "Le nom de la colonne ne peut pas commencer par un nombre.",
        areYouSure: "Êtes-vous sûr(e) ?",
        columnDeleted: "La colonne sera supprimée.",
        allColumnDeleted: "Toutes les colonnes seront supprimées.",
        idCantStartNumber: "L'ID ne peut pas commencer par un nombre.",
        uploadFile: "Téléverser le Fichier",
        fileViewer: "Visionneuse de Fichiers",
        ok: "D'accord",
        map: "Carte",
        editPanel: "Modifier le Panneau",
        filterColumnName: "Nom de la Colonne de Filtre",
        hintExamples: "MontantTotal, exemple : Salaire",
        columnLanguageSettings: "Paramètres de Langue de la Colonne",
      
        fileColumn: "Colonne de Fichier",
        fileNameColumn: "Colonne de Nom de Fichier",
        extensionColumn: "Colonne d'Extension",
        multipleSelection: "Sélection Multiple",
        addSelectionsRows: "Ajouter les sélections comme des lignes",
        mapSettings: "Paramètres de la Carte",
        formatAndFeatures: "Format et Fonctionnalités",
        enterDateRange: "Entrez la valeur de la plage de dates",
        filesCanUploadedUsers: "Les utilisateurs peuvent télécharger des fichiers",
        barcodeColumn: "Colonne de Code-barres",
        autoAscend: "Tri Automatique Ascendant",
        roundType: "Type d'Arrondi",
        font: "Police",
        columnProcess: "Opération de Colonne",
        exportColumnTotal: "Exporter le Total de la Colonne",
        outsideElementDisabled: "Désactiver les Éléments Externes",
        chat: "Discussion",
        languages: "Langues",
        display: "Affichage",
        enterYourComment: "Entrez votre commentaire",
        comment: "Commentaires",
        fileUploaded: "pièces téléversées",
        pdfViewIsButton: "Afficher comme Bouton",
        takeScreenshot: "Prendre une Capture d'Écran",
        alertText: "Texte d'Alerte",
        clearButtonVisible: "Afficher le Bouton Effacer",
        allText: "Tous",
        letter: "Lettres Seulement",
        letterandnumber: "Lettres et Chiffres",
        letterandsymbol: "Lettres et Symboles",
        numberandsymbol: "Chiffres et Symboles",
        showIDColumn: "Afficher la Colonne d'ID",
        showCopyButton: "Afficher le Bouton de Copie",
        showDeleteButton: "Afficher le Bouton de Suppression",
        showCheckboxButton: "Afficher le Bouton de Case à Cocher",
        autoRowHeight: "Hauteur de Ligne Automatique",
        exportImport: "Importation/Exportation de Formulaire",
        export: "Exporter",
        import: "Importer",
        toastImport: "Importation du Formulaire Terminée.",
        warningApproveImport: "Confirmez l'opération après avoir effectué les ajustements nécessaires. L'importation du formulaire fermera le formulaire actuellement ouvert.",
        defaultTodaysDateWithTime: "La date et l'heure actuelles par défaut",
        filterVisible: "Afficher le Filtrage de Colonne",
        updatetableMaxQuantity: "Quantité Maximale Mise à Jour",
        hideQuantity: "Masquer la Sélection de Quantité",
        disableQuantityTextbox: "Désactiver la Saisie de Quantité",
        multiplesOfQuantity: "Multiples de la Quantité",
        justnumber: "Chiffre seulement",
        paymentHourWarning: "Vous ne pouvez pas effectuer de paiement en dehors des heures de paiement.",
        
  
        addColumn: "Ajouter une Colonne",
        mailSettings: "Options de Messagerie",
        newTaskMailDetail: "Texte du Mail à Envoyer Lorsqu'une Nouvelle Tâche est Assignée",
        updateTaskMailDetail: "Texte du Mail à Envoyer Lorsqu'une Tâche est Modifiée",
        taskOnDropMail: "Texte du Mail à Envoyer Lorsque la Tâche est Déplacée",
        selectSMTP: "Sélectionner l'Adresse SMTP",
        taskCreator: "Assigné à la Tâche",
        taskEndDate: "Date d'Expiration de la Tâche",
        tags: "Étiquettes",
        taskStartDate: "Date de Début de la Tâche",
        taskAssigned: "Personne Assignée à la Tâche",
        taskDetail: "Détail de la Tâche",
        taskSubject: "Titre de la Tâche",
        taskPri: "Priorité de la Tâche",
        taskEdit: "Mise à Jour de la Tâche",
        addTask: "Ajouter une Tâche",
        taskStatus: "État de la Tâche",
        excelExportSettings: "Paramètres d'Export Excel",
        excelExportRequiredColor: "Couleur de la Colonne de Champ Requis",
        allDataExcelExport: "Exporter les Données au Format Excel",
        warningApproveImportReport: "Confirmez l'opération après avoir effectué les ajustements nécessaires. L'importation du rapport fermera le rapport actuellement ouvert.",
        reportName: "Nom du Rapport",
        emptyReportName: "Veuillez ajouter le nom du rapport à partir du côté droit.",
        old: "ancien",
        upperNode: "Nœud Supérieur",
        pdfViewStaticPdf: "Afficher le Fichier Fixe",
        actions: "Actions",
        loading: "Chargement",
        exportDataToExcell: "Exporter toutes les données au format Excel",
        showRefreshIcon: "Bouton Rafraîchir",
        headerFilter: "Filtre d'En-Tête",
        filterRowVisible: "Boîte de Recherche Apparaît dans la Colonne",
        password: "Mot de Passe",
        categories: "Catégories",
        pressEnter: "Appuyez sur Entrée",
        historyColumnValue: "Colonne Historique des Données",
        hideMobile: "Masquer sur Mobile",
        align: "Alignement",
        theme: "Thème",
        info: "Info",
        showTitle: "Afficher le Titre",
        purple: "Violet",
        brown: "Marron",
        yellow: "Jaune",
        turnOnComments: "Activer les Commentaires",
        showContent: "Afficher le Contenu",
        canBeHidden: "Peut être masqué",
        allowEdit: "Autoriser la Modification",
        allowDelete: "Autoriser la Suppression",
        allowAdd: "Autoriser l'ajout",
        allowCopy: "Autoriser la copie",
        toastDeleteErrorMessage: "Ce composant est utilisé dans des scripts de Préférences de Formulaire. Il ne peut pas être supprimé.",
        formOpeningMultiElementEvent: "Événement d'Ouverture de Formulaire avec Plusieurs Éléments",
        resetDatagridLocalSettings: "Réinitialiser les Paramètres Locaux de la Grille de Données",
        
        loginP1: "River offre un système de mémoire holistique en tant que mémoire d'entreprise contenant des informations telles que les expériences passées, les succès, les échecs, les leçons apprises, la culture et les valeurs de votre organisation ou institution pouvant servir de source de référence pour les décisions et actions futures. les processus, la connaissance de ses employés, les expériences des clients, le développement de produits et de services, et bien d'autres informations. Ces informations guideront les objectifs et les stratégies futures de l'entreprise.",
        loginP2: "River aide votre entreprise à exécuter les processus commerciaux de manière plus efficace et plus efficace avec sa structure prise en charge par une application Web et mobile avancée. Tout ce dont vous avez besoin d'accéder est aussi proche de vous que votre ordinateur, votre tablette ou votre smartphone.",
        loginConnect: "Connexion",
        loginText: "Connexion",
        loginRightMore: "Plus",
        loginHeaderP1: "Votre Mémoire d'Entreprise",
        loginHeaderP2: "Mobilité",
        loginHeaderP3: "Puissance Graphique",
        kanbanaddTasks: "Ajouter une Tâche Kanban",
        tasksstatusDesciption: "Affiche le nom de la colonne à laquelle la tâche Kanban sera attribuée.",
        taskssubjectDescription: "Vous permet de saisir le titre de la tâche Kanban. Le titre du Kanban est un champ obligatoire.",
        taskassignedToTask: "Groupes Ajoutés à la Tâche",
        taskassignedTaskDescription: "Permet la sélection des groupes à attribuer à la tâche Kanban.",
        addedForTasks: "Ajouté Pour la Tâche",
        taskAssignedInfoDescription: "Personnes à notifier pour la tâche",
        assignedInfo: "Personnes à notifier",
        groupInfo: "Groupes à notifier pour la tâche",
        sortEnd: "Trier par Date de Fin",
        sender: "De",
        forward: "Transférer",
        attachments: "Pièces Jointes",
        loginP3: "Les diagrammes Kanban et Gantt sont des outils extrêmement utiles pour visualiser un projet ou un flux de travail. Ils fournissent une représentation visuelle des éléments de travail, de leur état actuel et du flux de travail à différentes étapes de l'entreprise. Cela permet d'identifier facilement les goulots d'étranglement, de hiérarchiser les tâches et d'améliorer l'efficacité opérationnelle. Les diagrammes Kanban et Gantt sont pratiques et efficaces pour la gestion de projets agiles, le développement de logiciels et d'autres processus d'amélioration continue.",
        taskdetailDescription: "Il permet d'entrer les détails de la tâche Kanban. Les détails du Kanban sont un champ obligatoire.",
        taskassignedDescription: "Permet aux utilisateurs d'être assignés à la tâche Kanban. Au moins un utilisateur doit être assigné à la tâche Kanban.",
        taskstartdateDescription: "Permet de sélectionner la date de début de la tâche Kanban. Dans le Kanban, la date de début prend automatiquement le moment de la création de la tâche.",
        taskenddateDescription: "Permet de sélectionner la date de fin de la tâche Kanban. Dans le Kanban, la date de fin de la tâche est automatiquement fixée à une semaine après la création de la tâche.",
        taskpriDescription: "Permet de sélectionner l'état de priorité de la tâche Kanban. Dans le Kanban, la date d'échéance de la tâche est automatiquement fixée à une semaine après la création de la tâche.",
        tagsDescription: "Permet d'ajouter des balises à la tâche Kanban. Les balises sont séparées les unes des autres lorsqu'elles sont ajoutées avec des virgules.",
        outlookDescription: "Lorsque l'option d'ajout de la tâche à Outlook est activée, une tâche est automatiquement ajoutée au calendrier en vous connectant à votre compte Outlook.",
        taskAssInfoDescription: "C'est le champ utilisé pour les personnes qui ne sont pas assignées à la tâche mais dont les informations sont demandées. Les personnes ajoutées pour informer ne peuvent pas agir sur les tâches.",
        receiver: "Destinataire",
        draft: "Brouillon",
        spam: "Courrier Indésirable",
        filterstate: "Filtrer par État",
        checkForspelling: "Vérifier les Erreurs d'Orthographe",
        emailToForward: "Courrier à Transférer",
        lowImpMails: "Courriers Peu Importants",
        normalImpMails: "Courriers Importants Normaux",
        highImpEmails: "Courriers Importants Élevés",
        showReadMails: "Afficher les Courriers Lus",
        groupinfoDescription: "C'est le champ utilisé pour les groupes qui ne sont pas assignés à une tâche, mais dont les informations sont demandées. Les personnes appartenant aux groupes ajoutés pour les informer ne peuvent pas agir sur les tâches.",
        timeoutMission: "Temps pour Commencer l'Alerte Avant la Fin de la Mission",
        timeoutmissionDescription: "Utilisé pour spécifier le moment de démarrage de l'alerte lorsque la tâche Kanban approche de sa date de fin.",
        taskperiodDescription: "Utilisé pour spécifier le temps de rappel périodique lorsque la date de fin d'une tâche Kanban approche.",
        taskProgress: "Progression de la Tâche",
        taskProgressDescription: "Utilisé pour indiquer la progression d'une tâche Kanban. La progression peut être consultée sur le diagramme de Gantt.",
        updatekanbanTask: "Mise à Jour de la Tâche Kanban",
        taskcreatorDescription: "Le créateur de la tâche Kanban peut être affiché.",
       
        uploadfileDescription: "Utilisé pour télécharger des fichiers vers la tâche Kanban. Les fichiers ou images sont enregistrés sur le DMS.",
        infoPersons: "Personnes à Avertir",
        warningtime: "Temps d'Alerte",
        taskPeriod: "Période de Tâche",
        taskProgresSatus: "Progression",
        
        makeTask: "Créateur de Tâches",
        addedforTasks: "Ajouté Pour la Tâche",
        outlookIntegration: "Intégration Outlook",
        loginoutlook: "Connexion au Calendrier Outlook",
        fetchoutlook: "Récupérer les Données du Calendrier Outlook",
       
        progressStatus: "État d'Avancement",
        taskColors: "Couleurs des Tâches",
        addcalendarTask: "Ajout de Tâches au Calendrier",
        updatetaskCalendar: "Mise à Jour des Tâches du Calendrier",
        statusComplete: "Filtrer l'État d'Achèvement",
        priorityFilter: "Filtrage par Priorité",
        columnFilter: "Filtrage par Colonne",
        assignPerson: "Personnes Assignées",
        assignGroup: "Groupes Assignés",
        noTask: "Aucune Tâche Trouvée",
        resetSort: "Réinitialiser le Triage",
        sortASC: "Trier A - Z",
        sortDESC: "Trier Z - A",
        sortAppointment: "Trier par Date d'Attribution",
        trash: "Corbeille",
        showOlderMail: "Afficher les Anciens Courriers",
        mailNotFound: "Courrier Non Trouvé",
        sendNewMail: "Envoyer un Nouveau Courrier",
        showUnreadMails: "Afficher les Courriers Non Lus",
        updateColumnMail: "Message à Contacter Lors de l'Ajout d'une Tâche à la Colonne",
        addTaskToOutlook: "Ajouter une Tâche à Outlook",
        enterYourMessage: "Saisissez Votre Message",
        reminder: "Rappel",
        determinedToTask: "Temps Spécifié pour la Tâche",
        remainingTime: "Temps Restant",
        hours: "Heure",
        withoutPriority: "Pas de Priorité",
        lowPri: "Priorité Basse",
        normalPri: "Priorité Moyenne",
        highPri: "Priorité Haute",
        showAllTasks: "Afficher Tout",
        showIncompleteTasks: "Afficher les Tâches Incomplètes",
        showCompeletedTasks: "Afficher les Tâches Terminées",
        reportDesigner: "Concepteur de Rapports",
        fileManagementSystem: "Système de Gestion des Fichiers",
        formDesigner: "Concepteur de Formulaires",
        boardDesigner: "Concepteur de Tableaux",
        themeSettings: "Paramètres de Thème",
        social: "Social",
        profile: "Profil",
        contacts: "Contacts",
        files: "Fichiers",
        pages: "Pages",
        noResultsFound: "Aucun Résultat Trouvé",
        processes: "Processus",
        filterByStatus: "Filtrer par État",
        filterByPri: "Filtrer par Priorité",
        filterByCol: "Filtrer par Colonne",
        todoAddTasks: "Ajouter des Tâches à Faire",
        addLabel: "Entrer une Étiquette",
        showWithSubTasks: "Afficher les Sous-Tâches",
        riverBoard: "Tableaux River",
        notify: "Notifications",
        likedYourPost: "A Aimé Votre Publication",
        likedYourComment: "A Aimé Votre Commentaire",
        systemNotify: "Notifications Système",
        readAllNotify: "Tout Marquer Comme Lu",
        recommendedUsers: "Personnes Recommandées",
        suggestedPosts: "Publications Suggérées",
        sPostReposted: "Publication Repostée",
        shareSomething: "Partager Quelque Chose",
        updatePost: "Mettre à Jour la Publication",
        ifYouDoNotSelectGroup: "Si vous ne sélectionnez pas de groupe, tout le monde peut voir votre publication",
        addComment: "Ajouter un Commentaire",
        sendComment: "Envoyer le Commentaire",
        areYouSureYouWantToDeleteThisPost: "Êtes-vous sûr de vouloir supprimer la publication",
        yes: "Oui",
        no: "Non",
        deletePost: "Supprimer la Publication",
        postSuccesfullyDeleted: "Publication Supprimée avec Succès",
        deleteComment: "Supprimer le Commentaire",
        areYouSureYouWantToDeleteThisComment: "Êtes-vous sûr de vouloir supprimer ce commentaire",
        commentSuccesfullyDeleted: "Commentaire Supprimé avec Succès",
        sharePost: "Partager la Publication",
        areYouSureYouWantToShareThisPost: "Êtes-vous sûr de vouloir partager cette publication",
        repostedSuccessfully: "Reposté avec Succès",
        upload: "Téléverser",
        refresh: "Actualiser",
        allowedExtentions: "Extensions autorisées : JPG, GIF ou PNG. Taille de fichier maximale",
        systemLandingPagePreference: "Préférence de la Page d'Accueil Système",
        allownonadminuserstosearchforotherusers: "Autoriser les utilisateurs non administrateurs à rechercher d'autres utilisateurs",
        passwordChange: "Changement de Mot de Passe",
        newPassword: "Nouveau Mot de Passe",
        retypethenewpassword: "Retaper le Nouveau Mot de Passe",
        notifications: "Notifications",
        showWhoLikesYourPost: "Afficher qui aime vos publications",
        showWhoLikesYourComment: "Afficher qui aime vos commentaires",
        aboutMe: "À Propos de Moi",
        bio: "Biographie",
        dateOfBirth: "Date de Naissance",
        website: "Site Web",
        phone: "Téléphone",
        saveChanges: "Enregistrer les Modifications",
        username: "Nom d'Utilisateur",
        content: "Contenu",
        projectManagement: "Gestion de Projet",
        addPage: "Ajouter une Page",
        addFolder: "Ajouter un Dossier",
        showUsers: "Utilisateurs Qui Peuvent Voir",
        showGroups: "Groupes Qui Peuvent Voir",
        readonlyUsers: "Utilisateurs Qui Peuvent Lire",
        readonlyGroups: "Groupes Qui Peuvent Lire",
        columnHeight: "Hauteur de Colonne",
        showKanbanCount: "Afficher les Informations de Quantité Kanban",
        kanbanSettings: "Paramètres Kanban",
        gantSettings: "Paramètres Gantt",
        schedulerSettings: "Paramètres du Calendrier",
        showRowLines: "Afficher les Lignes de Ligne",
        
        activateOutlookConnection: "Activer la Connexion Outlook",
        selectOutlookColumn: "Sélectionner la Colonne Outlook",
        selectSMTPopoverText: "Le SMTP de courrier à utiliser dans les composants Kanban, Gantt, Calendrier ou À-Faire est sélectionné.",
        menuFilePathId: "ID du Dossier de Menu River",
        recycleBinPathName: "Nom du Dossier Corbeille River",
        recycleBinPathId: "ID du Dossier Corbeille River",
        fileNameChangedMessage: "Le nom de fichier a été changé avec succès.",
        fileNameChangedErrorMessage: "Le nom de fichier n'a pas pu être changé.",
        fileCreatedMessage: "Le fichier a été créé avec succès.",
        newTaskMailDetailPopoverText: "Lorsqu'une nouvelle tâche est assignée aux composants Kanban, Gantt, Calendrier ou À-Faire, la partie du contenu du courrier qui sera ajoutée au courrier automatique qui sera envoyé aux utilisateurs assignés.",
        taskOnDropMailPopoverText: "La partie du contenu du courrier qui sera ajoutée au courrier automatique qui sera envoyé aux composants Kanban, Gantt, Calendrier ou À-Faire lorsque la colonne contenant une tâche est modifiée.",
        createdBy: "Créé par",
        updatedBy: "Mis à jour par",
        createdDate: "Date de Création",
        updatedDate: "Date de Mise à Jour",
        folderPermissionSettings: "Paramètres d'Autorisation de Dossier",
        quantity: "Quantité",
        fileManagementPermissionSettings: "Paramètres d'Autorisation de Gestion de Fichiers",
        fileNotFoundErrorText: "Aucun fichier trouvé correspondant à vos critères de recherche.",
        updateTaskMailDetailPopoverText: "La partie du contenu du courrier qui sera ajoutée au courrier automatique qui sera envoyé aux utilisateurs assignés lorsque des changements sont apportés aux composants Kanban, Gantt, Calendrier ou À-Faire dans la tâche correspondante.",
        selectOutlookColumnPopoverText: "La colonne pour inclure les tâches à ajouter via Outlook est sélectionnée.",
        createFolder: "Créer un Dossier",
        versionHistory: "Historique des Versions",
        deletePermanently: "Supprimer Définitivement",
        recover: "Récupérer",
        groupPermissions: "Permissions de Groupe",
        allowUpload: "Autoriser l'Upload de Fichier",
        allowView: "Autoriser la Visualisation de Fichier",
        allowDownload: "Autoriser le Téléchargement de Fichier",
        permissionType: "Type d'Autorisation",
        applyToSubFolders: "Appliquer à tous les sous-dossiers",
        newFolderName: "Nouveau Nom de Fichier",
        tagTips: "Les étiquettes doivent être précédées du symbole #. Elles peuvent contenir uniquement des lettres et des chiffres.",
        addAsNewVersion: "Ajouter comme Nouvelle Version",
        addAndChangeName: "Uploader avec Renommage",
        skip: "Passer",
        fileExistError: "Un fichier portant le même nom existe dans le dossier de destination",
        processFileShow: "Afficher le Dossier de Processus River",
        processFilePathName: "Nom du Dossier de Processus River",
        processFilePathId: "ID du Dossier de Processus River",
        menuFileShow: "Afficher le Dossier de Menu River",
        menuFilePathName: "Nom du Dossier de Menu River",
        fileCreatedErrorMessage: "Échec de la Création du Fichier.",
        fileDeletedessage: "Suppression du Fichier terminée.",
        fileDeletedErrorMessage: "Échec de la Suppression du Fichier.",
        fileMovedMessage: "Déplacement du Fichier terminé.",
        fileMovedErrorMessage: "Échec du Déplacement du Fichier.",
        fileCopyMessage: "Copie du Fichier terminée.",
        fileCopyErrorMessage: "Échec de l'Opération de Copie du Fichier.",
        fileDownloadMessage: "Téléchargement du Fichier terminé.",
        fileDownloadErrorMessage: "Échec du Téléchargement du Fichier.",
        fileSizeShowErrorMessage: "Impossible de visualiser car la taille du fichier est importante. Veuillez télécharger le fichier.",
        unsupportedFile: "Fichier Non Pris en Charge",
        fileNameError: "Il existe déjà un fichier avec ce nom, veuillez réessayer avec un nom différent.",
        fileGivePermissionMessage: "Autorisation de fichier complétée avec succès.",
        fileGivePermissionErrorMessage: "Erreur lors de l'autorisation de fichier. L'enregistrement a échoué.",
        groupName: "Nom du Groupe",
        createGroup: "Créer un Groupe",
        conversation: "Conversations",
        startConversation: "Démarrer une Conversation",
        userNotFound: "Contact non trouvé",
        chatNotFound: "Chat non trouvé",
        themeTypeLight: "Clair",
        themeTypeBorderedLight: "Encadré Clair",
        themeHalfDark: "Demi-Sombre",
        themeDark: "Sombre",
        themeRouteAnimation: "Animation de Transition",
        themeRouteAnimationFadeInLeft: "Fade Gauche",
        themeRouteAnimationZoomIn: "Zoom",
        themeRouteAnimationFadeIn: "Fade",
        themeRouteAnimationNone: "Aucune",
        themeMenuType: "Type de Menu",
        themeMenuTypeHorizontal: "Horizontal",
        themeMenuTypeVertical: "Vertical",
        themeMenuCollapse: "Menu Étroit",
        themeNavbarColor: "Couleur de la Barre de Navigation",
        themeNavbarType: "Type de Barre de Navigation",
        themeSticky: "Collant",
        themeStatic: "Statique",
        themeFloating: "Flottant",
        themeHidden: "Caché",
        themeFooterType: "Type de Pied de Page",
        catalog: "Catalogue",
        riverBoards: "Tableaux River",
       
        clipboardDesigner: "Concepteur de Presse-papiers",
        designMode: "Vous êtes en Mode de Conception",
        editPage: "Modifier la Page",
        rename: "Renommer",
        exportToPdf: "Exporter en PDF",
        exportToExcel: "Exporter en Excel",
        revenueReport: "Rapport de Revenus",
        header: "Titre",
        connection: "Connexion",
        primaryKey: "Clé Principale",
        secondaryKey: "Clé Secondaire",
        completedKey: "Clé de Terminé",
        countKey: "Clé de Compte",
        statusKey: "Clé de Statut",
        progressConnection: "Connexion d'Avancement",
        progressScript: "Script d'Avancement",
        progressPrimaryKey: "Clé Principale d'Avancement",
        progressCountKey: "Clé de Compte d'Avancement",
        progressPercentageKey: "Clé de Pourcentage d'Avancement",
        progressColorKey: "Clé de Couleur d'Avancement",
        keyColumn: "Colonne de Valeur",
        
        currency: "Devise",
        whitePage: "Page Blanche",
        noReportSelected: "Aucun Rapport Sélectionné",
        noTypeSelected: "Aucun Type de Rapport Sélectionné",
        whitePageReports: "Rapport Page Blanche",
        addBranch: "Ajouter une Branche/Sous-branche",
        branchName: "Nom de la Branche",
        rowNumber: "Numéro de Ligne",
        reportTypeSelect: "Sélectionner le Type de Rapport",
        reportDescription: "Description du Rapport...",
        useWeb: "Utiliser sur le Web",
        useMobile: "Utiliser sur Mobile",
        mainScript: "Script Principal",
        prerunScript: "Exécuter le script préalablement ?",
        preScript: "Script Préalable",
        testIt: "Tester",
        selectAll: "Sélectionner Tout",
        addReport: "Ajouter un Rapport",
        selectReportType: "Sélectionner le Type de Rapport",
        reportType: "Type de Rapport",
        reportDesingPermission: "L'utilisateur n'a pas l'autorisation de conception de rapport.",
        reportImportedOpenLeftMenu: "Rapport Importé. Vous pouvez l'ouvrir depuis le menu de gauche.",
        forReportExport: "Pour exporter le rapport, vous devez d'abord sélectionner un rapport.",
        anErrorOccurred: "Une erreur s'est produite",
        reportDeleted: "Rapport Supprimé",
        branchDeleted: "Branche Supprimée",
        addBranchName: "Veuillez entrer le nom de la branche",
        addedBranch: "Branche Ajoutée",
        selectConnectionAddress: "Veuillez sélectionner une adresse de connexion",
        enterSqlScript: "Veuillez entrer le script SQL",
        enterReportName: "Veuillez entrer le nom du rapport",
        reportAdded: "Rapport Ajouté",
        numberOfDataToBeProcessed: "Connexion réussie. Nombre de données à traiter :",
        dataNotFoundCheckYourConnection: "Données non trouvées ! Veuillez vérifier votre connexion et votre commande SQL",
        connectionNotFoundCheckYourConnection: "Échec de la connexion ! Veuillez vérifier votre connexion et votre commande SQL",
        fillConnectionAndSql: "Veuillez remplir votre connexion et votre commande SQL",
        allTypesActivate: "Tous les Types sont Activés à l'Affichage",
        passwordRequired: "Le mot de passe est requis",
        showLess: "Montrer Moins",
        showMore: "Montrer Plus",
        authority: "Autorité",
        preScriptDescription: "Description du Script Préalable",
        enterReportFilters: "Entrer les Filtres du Rapport",
        gridDesigner: "Concepteur de Grille",
        addMainBranch: "Ajouter une Branche Principale",
        resetSettings: "Réinitialiser les Paramètres",
        columsToGroup: "Utiliser le menu contextuel des colonnes d'en-tête pour grouper les données",
        customize: "Personnaliser",
        colorType: "Type de Couleur",
        formatManagement: "Gestion de Format",
        bottomTotalColumn: "Colonne de Total en Bas",
        formatType: "Type de Format",
        datagridSettingsReset: "Les paramètres de la grille de données seront réinitialisés",
        unexpectedError: "Désolé, une erreur inattendue s'est produite.",
        changesSaved: "Les modifications sont enregistrées",
        addGantTask: "Ajouter une Tâche Gantt",
        updGantTask: "Mettre à Jour une Tâche Gantt",
        updSchedulerTask: "Mettre à Jour une Tâche de Calendrier",
        updTodoTask: "Mettre à Jour une Tâche À-Faire",
        completeTask: "Terminer la Tâche",
        cantBeEmptyKanban: "Le titre de la tâche, le détail de la tâche et le destinataire de la tâche ne peuvent pas être vides",
        successAdd: "La tâche a été ajoutée avec succès",
        successUpd: "La tâche a été mise à jour avec succès",
        statusChangeTodo: "Le Statut d'achèvement de la Tâche a Changé avec Succès.",
        reminderSended: "E-mail de rappel envoyé pour la tâche avec succès",
        askForCompleteTask: "Êtes-vous sûr de vouloir terminer la tâche ?",
        infoForCompletedTask: "Les sous-tâches de votre tâche seront également terminées.",
        noPermisison: "Vous n'êtes pas autorisé.",
        successDependencyAdded: "La Dépendance de Tâche a été Ajoutée avec Succès",
        filterimportant: "Filtrer par Importance",
        enterEmail: "Entrer l'adresse e-mail",
        reply: "Répondre",
        newCol: "Nouvelle Colonne",
        thickness: "Épaisseur",
        distanceToUpperComponent: "Distance par rapport au composant supérieur",
        companyName: "Nom de l'Entreprise",
        showEmailModule: "Afficher le module d'e-mail",
        distanceToBottomComponent: "Distance par rapport au composant inférieur",
        canNonAdminUsersPost: "Les utilisateurs non administrateurs peuvent-ils publier",
        columnColor: "Couleur de Colonne",
        byWho: "par",
        updated: "Mise à Jour",
        
        commentAdded: "Commentaire Ajouté",
        completed: "Terminé",
        addRemoveColumns: "Ajouter/Supprimer des Colonnes",
        searchMessage: "Rechercher des messages",
        riverReports: "Rapports River BI",
        forms: "Formulaires",
        searchinProcess: "recherche dans les processus",
        searchVoiceMessage: "Le message que vous recherchez se trouve dans ce fichier audio",
        searchVoiceMessages: "Recherche de messages vocaux",
        showSocialNotifications: "Afficher les Notifications Sociales",
        showProjectManagementNotifications: "Afficher les Notifications de Gestion de Projet",
        showProcessNotifications: "Afficher les Notifications de Processus",
        pageDesingPermission: "L'utilisateur n'a pas l'autorisation de conception de page.",
        filemanagementDesingPermission: "L'utilisateur n'a pas l'autorisation de gestion de fichiers.",
        taskDelete: "La tâche sera supprimée.",
        tasksWillDeleted: "Êtes-vous sûr de vouloir supprimer la tâche ?",
        componentSettings: "Paramètres du Composant",
        aboutComponent: "À Propos du Composant",
        sureSaveChanges: "Êtes-vous sûr de vouloir enregistrer vos modifications ?",
        showDeletedNotifications: "Afficher les Notifications que j'ai Supprimées",
        shareSometthing: "Partager Quelque Chose",
        resetPageLayout: "Réinitialiser la Mise en Page de la Page",
        downloadingSS: "Téléchargement de la capture d'écran. Veuillez patienter.",
        showHorizontalLines: "Afficher les Lignes Horizontales",
        turnOffVerticalHide: "désactiver le masquage vertical",
        whichVerticalLinesAreToBeHidden: "Quelles lignes verticales doivent être masquées",
        priority: "Priorité",
        desingMode: "Vous êtes en mode création",
        boardSidebarMessageField: "Champ de message de la tâche",
        boardSidebarExtraField: "Champ de composant supplémentaire de la tâche",
        boardSidebarTaskHistory: "Historique de la tâche",
        loadMoreNotifications: "Afficher plus de notifications",
        deletedNotifications: "Notifications supprimées",
        noFormNotification: "Aucune notification de processus pour le moment",
        noDeletedFormNotifications: "Aucune notification de processus supprimée",
        noDeletedBoardNotification: "Aucune notification de tableau supprimée",
        noBoardNotification: "Aucune notification de tableau pour le moment",
        noDeletedSocialNotification: "Aucune notification de médias sociaux supprimée",
        noSocialNotification: "Aucune notification de médias sociaux pour le moment",
        deleteAll: "Tout supprimer",
        markAllRead: "Tout marquer comme lu",
        board: "Tableau",
        form: "Formulaire",
        formNotifications: "Notifications de Formulaire",
        boardNotifications: "Notifications de Tableau",
        socialNotifications: "Notifications Sociales",
        email: "Courriel",
        oldFormName: "Ancien Nom du Formulaire",
        newFormName: "Nouveau Nom du Formulaire",
        oldFormVariable: "Ancienne Variable du Formulaire",
        newFormVariable: "Nouvelle Variable du Formulaire",
        oldCategory: "Ancienne Catégorie",
        newCategory: "Nouvelle Catégorie",
        connectionInFile: "Connexion dans le Fichier",
        yourConnection: "Votre Connexion",
        element: "Élément",
        script: "Script",
        formClosingEvent: "Événement de Fermeture du Formulaire",
        message: "Message",
        formExportTextError: "Aucun composant n'a été ajouté au formulaire ou le formulaire n'est pas enregistré. Veuillez corriger ce problème pour éviter l'échec de l'opération.",
        checkRequiedFields: "Vérifier les Champs Requis",
        dontCheckRequiredFields: "Ne Pas Vérifier les Champs Requis",
        createPdfWarning: "Si coché, le formulaire ne sera pas enregistré et fermé",
        cratePdf: "Créer un PDF",
        identityNumber: "Numéro d'Identité",
        minPaymentHour: "Heure Min",
        maxPaymentHour: "Heure Max",
        darkBlue: "Bleu Foncé",
        orange: "Orange",
        component: "Composant",
        properties: "Propriétés",
        dmsPath: "Chemin DMS",
        dashboard: "Tableau de bord",
        slider: "Curseur",
        list: "Liste",
        otherImages: "Autres Images",
        unitScript: "Script Unité",
        updatableMaxQuantity: "Quantité Max Modifiable",
        newPage: "Nouvelle Page",
        code: "Code",
        resetHiddenComponent: "Réinitialiser la valeur des composants cibles masqués",
        bold: "Gras",
        italic: "Italique",
        stringFormat: "Format de Chaîne",
        videoUploaded: "Vidéo Téléchargée",
        fillInAllFields: "Remplissez tous les champs",
        normalPaging: "Pagination normale",
        pageSize: "Taille de la page",
        special: "Spécial",
        link: "Lien",
        fontWeight: "Poids de la police",
        pdfFileNotFound: "Fichier PDF non trouvé",
        canItBeUpdated: "Peut-il être mis à jour",
        themeMenuHidden: "Menu Caché",
        likedOn: "aimé sur",
        addedTask: "tâche ajoutée",
        updatedTask: "tâche nommée mise à jour",
        messageAddedToTask: "Message ajouté à la tâche",
        deleteTask: "la tâche nommée a été supprimée",
        completedTask: "tâche terminée",
        subCategory: "Sous-catégorie",
        toTheBoard: "au tableau",
        hideTaskDetail: "Masquer les Détails de la Tâche",
        selectData: "Sélectionner des données",
        noContentYet: "Pas Encore de Contenu",
        addRowBetween: "Ajouter une Ligne Entre",
        userPermissions: "Permissions de l'utilisateur",
        riverModules: "RIVER Modules",
        processHistory: "historique du processus",
        commentHasBeenMadeAtThisStage:"Un commentaire a été ajouté à ce stade",
        askForPassword: "Demander le mot de passe ?",
        passwordIsWrong: "Le mot de passe est incorrect, veuillez réessayer.",
        pleaseEnterPassword: "Veuillez entrer votre mot de passe pour continuer.",
        onePaymentEachSave: "Un paiement à chaque enregistrement",
        clearSelection: "Effacer la Sélection",
        allowDownloadVersionHistoryDocuments: "Autoriser le Téléchargement des Documents de l'Histoire des Versions",
        delegated: "délégué",
        openDocxToPdf: "Ouvrir le Fichier en PDF",
        activationStartDate: "Date de Début d'Activation",
        activationEndDate: "Date de Fin d'Activation",
        changeActivationDate: "Changer la Date d'Activation",
        showArchive: "Afficher les Archives",
        hideArchive: "Masquer les Archives",
        tasks: "Tâches",
        maxFileCountLimit: "Nombre maximum de fichiers",
        fileCountLimit: "Limite du nombre de fichiers",
        numberOfFilesExceeded: "Nombre de fichiers dépassé",
        numberOfFilesLimit: "Limite du nombre de fichiers",
        lineIntermediateAllowAdd: "Ajouter une ligne intermédiaire",
        lineIntermediateAllowCopy: "Copier la ligne intermédiaire",
        triggerReport: "Rapport de déclenchement",
        setWhitePageReportExportName: "définir le nom d'exportation du rapport de page blanche",
        menuRowUpdated: "Il existe des enregistrements avec une valeur auto-incrémentée. Le numéro auto-incrémenté a été mis à jour : ",
        isMenuRowControl: "Contrôle d'auto-incrément",
        sqlScriptWarning: "L'utilisation de ce champ peut entraîner des pertes de performance.",

        deletedTask: "Tâche supprimée",
        getDeletedTasks: "Obtenir les tâches supprimées",
        getTasks: "Obtenir les tâches",
        movePageDesignersNode: "Le panneau/menu sélectionné sera déplacé vers le dossier",
        informationAboutMoveToDirectory: "Les autorisations de l'utilisateur du panneau/menu déplacé changeront en fonction des autorisations du dossier dans lequel il est déplacé.",
        move: "Déplacer",
        download: "Télécharger",
        create: "Créer",
        statistic: "Statistiques",
        priRate: "Taux d'attribution par priorité",
        taskcreatorlist: "Liste des créateurs de tâches",
        completionRate: "Taux de réalisation",
        columnsTaskCount: "Nombre de tâches dans les colonnes",
        showKanbanFilter: "Afficher les filtres Kanban",
        processToStart: "Processus à démarrer",
        fieldMatching: "Correspondance des champs",
        showFileHistory: "Afficher l'historique du fichier",
        dontLetDeleteWhatTheyAdded: "Ils peuvent supprimer ce qu'ils ont ajouté",
        myJobs: "Mes emplois",
        excelExportName:"nom d'exportation Excel",
        filterSearch: "Rechercher dans les tâches",
        filterForAssignUsers: "Filtrer par assigné",
        filterForAssignUGroups: "Filtrer par groupe",
        filterForPri: "Filtrer par priorité",
        clearFilter: "Effacer les filtres",
        makeRequiredField: "Rendre le champ obligatoire",
        makeReadOnly: "Rendre en lecture seule",
        letItWorkProcessContinues: "Laissez-le fonctionner lorsque le processus continue",
        event: "Événement",
        selectEvent: "Sélectionner l'événement",
        rowEditor: "Éditeur de ligne",
        formOpeningComponentEvent: "Événement d'ouverture du formulaire pour le composant",
        boardToastNotification: "{{0}}  a  {{3}} à jour la tâche    {{2}}   dans le tableau  {{1}}",
        socialToastNotification: "{{0}}  a aimé votre message avec {{1}} contenu sur  {{2}}",
        processToastNotification: "{{0}} a commenté le formulaire  {{1}}",
        yesterday: "Hier",
        today: "Aujourd'hui",
        notSaved: "Non sauvegardé",
        editors: "Éditeurs",
        basic: "De base",
        file: "Fichier",
        datagridPreferences: "Préférences de la grille de données",
        deleteLetsAsk: "Autoriser la suppression ?",
        addNewRow: "Sans demander, ajouter en tant que nouvelle ligne",
        oldRowShouldBeDeleted: "Sans demander, supprimer les anciennes lignes",
        canBeSearched: "Peut être recherché?",
        processName: "nom du processus",
        removeReadOnly: "Supprimer la propriété en lecture seule",
        dontMakeRequired: "Ne pas rendre le champ obligatoire",
        dataName: "Nom des données",
        margin: "Marge",
        rowCount: "Nombre de lignes",
        colCount: "Nombre de colonnes",
        outsideMargin: "Marge extérieure",
        widgetName: "Nom du widget",
        searchInProcess: "Formulaires de traitement",


        changeEventScriptRun: "Les scripts de modification doivent-ils être déclenchés lorsque des données sont remplies?",
        dontChanceId: "(Cet ID d'objet ne peut pas être modifié car il est utilisé dans les activités de formulaire de composant.)",
        scriptButtonWarning: "Les paramètres d'état des boutons de script ne sont pas pris en charge dans les formulaires de menu.",
        description2: "déclaration 2",
        showJobListTabs: "afficher les onglets de la liste de tâches",
        showUserPhotosInJobList: "afficher les photos des utilisateurs dans la liste des tâches",
        dmsVideoGaleryTabWarning: "Vous n'avez pas l'autorisation d'utiliser ce champ.",
        searchInFormJson: "Rechercher sous le formulaire json",
        useExpandedJoblist: "Utiliser la liste de tâches étendue",
        editTour: "Modifier la visite",
        selectComponent: "Sélectionner le composant",
        tourComponents: "Composants de la visite",
        addTourElement: "Ajouter un élément de visite",
        startTourWhenFormOpen: "Démarrer la visite lorsque le formulaire est ouvert",
        compare: "Comparer",
        deleteTourElement: "Supprimer l'élément de visite",
        tourWillDeleted: "La visite sera supprimée",
        tourDelete: "Supprimer la visite",
        fixedRowHeight: "Hauteur de ligne fixe",
        pushWidgetItems: "Pousser les éléments du widget",
        allowMultiLayer: "Autoriser plusieurs couches",
        gridType: "Type de grille",
        useCollapsedJoblist: "utiliser la liste de tâches réduite",
        searchAllData: "Rechercher toutes les données",
        scriptsRunAllAreSelected: "Les scripts doivent-ils s'exécuter lorsque tout est sélectionné ?",
        
        menuSchedulerPermission: "Vous n'avez pas la permission d'ajouter une carte de menu",
        showOnTask: "L'éditeur doit-il apparaître dans les processus",
        alreadyHaveMenu: "Si vous souhaitez utiliser un composant de menu, il doit y avoir uniquement un composant de menu dans le formulaire et plusieurs composants de menu ne peuvent pas être ajoutés.",
        select: "Sélectionner",
        startedUserName: "Nom d'utilisateur démarré",
        startedUserId: "ID utilisateur démarré",
        processInstanceId: "ID d'instance de processus",
        formId: "Identifiant du formulaire",
        min: "Le minimum",
        difference:"Modifications entre deux fichiers",
        labelNotFound: "L'étiquette n'est pas définie dans cette langue",
        cleanElement: "Effacer le champ d'élément lors de l'impression des données ?",
        cleanColumn: "Effacer l'espace de colonne lors de l'impression des données ?",
        fileSkippedSuccessfully: "Annulé.",
        dmsTags: "Étiqueter",
        searchInFormContents: "rechercher dans le contenu du formulaire",
        jobType: "Type d'emploi",
        workStatus: "Statut de travail",
        or: "Ou",
        signInWithMicrosoft: "connectez-vous avec un compte Microsoft",
        receipt: "Reçu",
        receiptOptions: "Options de reçu",
        tax: "Taxe",
        selectboxWarningMessage: "Si les données dépassent 100, veuillez utiliser le composant de recherche. Les 100 premières valeurs sont affichées.",
        _filter: "filtre",
        removeFromFavorites: "retirer des favoris",
        addToFavorites: "ajouter aux Favoris",
        id: "ID",
        assignedUserName: "Nom d'utilisateur attribué",
        schemeId: "ID du schéma",
        formStatusByUser: "Statut du formulaire par utilisateur",
        formSituationByUser: "formulaire Situation par utilisateur",
        isDelegated: "est délégué?",
        formname: "nom de forme",
        formstatus: "statut du formulaire",
        timeOutDate: "Date d'expiration",
        saveFilterPack: "enregistrer le pack de filtres",
        enterRegistrationName: "entrez le nom d'enregistrement",
        filterPackages: "filtrer les forfaits",
        degree: "degré",
        confirmationPopupClose: "Si vous n'effectuez aucune action, la fenêtre contextuelle de confirmation se fermera automatiquement.",
        confirmationSecondsSetting: "Paramètre de secondes de la fenêtre contextuelle de confirmation",
        createPdfAndSendForm: "Le formulaire doit-il être envoyé lorsque le PDF est créé ?",
        confirmCreatePdfAndSendForm: "Un PDF sera créé et le formulaire sera envoyé. Confirmez-vous ?",
        onlyNumbersError: "Vous devez entrer une valeur contenant uniquement des chiffres.",
        speechLengthError: "Le discours est plus long que la longueur requise.",
        removeImage: "Supprimer",
        headerBundle: "Bundle d'en-tête",
        other: "Autre",
        verticalHeader: "En-tête vertical",
        exportDataAuth: "Autorisations d'exportation",
        errorFileType: "Le type de fichier est incorrect. Type de fichier accepté : ",
        folder: "Dossier",
        page:"Page",
        uploadPdfToDms: "Téléchargement du PDF généré vers DMS",
        uploadPdfToDmsName: "Entrez le nom du PDF à enregistrer dans DMS.",
        pdfName: "Nom du PDF",
        addTour: "Ajouter une visite",
        updateTour: "Mettre à jour la visite",
        editTourElement: "Éditer l'élément de visite",
        buttonAction: "Action du bouton",
        referenceArea: "Zone de référence",
        fieldToFill: "Champ à remplir",
        buttonColor: "Couleur du bouton",
        signFile: "Signer le fichier",
        addNewCategory: "Ajouter une nouvelle catégorie",
        autoRefreshJobList: "Actualisation automatique de la liste des tâches",
        changeScriptRow: "Modifier l'ordre des scripts",
        defaultRowCheckRequired: "Doit-on effectuer des vérifications de champs obligatoires lorsqu'une ligne par défaut existe ?",
        currentCode: "Code actuel",
        screenshotOnMobile: "Capture d'écran sur mobile",
        created: "Créé",
        keepPermission: "Transférer les autorisations lors du transfert du formulaire. (Non recommandé si vos utilisateurs ou groupes ne correspondent pas)",
    }
  
  }