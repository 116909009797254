export class MenuNode {
    FormId: number;
    NodeKey: string;
    NodeData: string;

    Status: number;
    ButtonName: string;
    UserId: number;
    SchemaId: number;
    FormJson: string;
    Name : string;
}
