import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  public apiData = [];
  public onApiDataChange: BehaviorSubject<any>;

  
    constructor(private http: HttpClient, public appInfo: AppInfoService) {

    this.onApiDataChange = new BehaviorSubject('');
  }



    getWhoLikedMyPost(userId: number) {
        
        return this.http.get<any>(this.appInfo.appUrl + "api/profile/getwholikedmypost?userId=" + userId, this.appInfo.httpOptions);

    }

    GetUserWPhoto(): Observable<any> {

        return this.http.get<any>(this.appInfo.appUrl + "api/user/getuserwphoto", this.appInfo.httpOptions);
    }

    getWhoLikeMyComment(userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/profile/getwholikedmycomment?userId=" + userId, this.appInfo.httpOptions);

    }
  
    addNotifications(model: any) {
        
        return this.http.post<any>(this.appInfo.appUrl + "api/notification/addnotification" ,JSON.stringify(model), this.appInfo.httpOptions);

    }
    getToastNotificationData(id: number, profileId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/gettoastnotificationdata?id=" + id + "&profileId=" + profileId, this.appInfo.httpOptions);

    }

    
   

    addIsReadUsers(profileId: number, pageType:number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/notification/addisreadusers?profileId=" + profileId + '&pageType=' + pageType, this.appInfo.httpOptions);

    }
    addDeletedUsers(profileId: number, pageType: number) {
        return this.http.post<any>(this.appInfo.appUrl + "api/notification/adddeletedusers?profileId=" + profileId + '&pageType=' + pageType, this.appInfo.httpOptions);

    }
    

   
    setReadUserToNotify(taskId: number, userId: number,type:number) {
        
        return this.http.post<any>(this.appInfo.appUrl + "api/notification/setreadusertonotify?taskId="+taskId+"&userId="+userId+"&type="+type , this.appInfo.httpOptions);

    }
    getNewTasksCount(userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/getnewtaskscount?userId=" + userId, this.appInfo.httpOptions);

    }
   

    addIsReadUser(ids: string, userId: number) {
    
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/markasreaded?ids=" + ids + "&userId=" + userId, this.appInfo.httpOptions);
    }
    deleteItem(ids: string, profileId: number) {

        return this.http.post<any>(this.appInfo.appUrl + "api/notification/markasdeleted?ids=" + ids + "&profileId=" + profileId, this.appInfo.httpOptions);

    }

getNotificationCount(userId:number,pageType:number){
    return this.http.get<any>(this.appInfo.appUrl + "api/notification/getnotificationcount?userId=" + userId + "&pageType=" + pageType, this.appInfo.httpOptions);

    }
    getDeletedNotificationCount(userId: number, pageType: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/getdeletednotificationcount?userId=" + userId + "&pageType=" + pageType, this.appInfo.httpOptions);

    }



    getAllNotificationsByPageType(userId: number, pageType: number, pageNo: number, pageSize: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/getnotifications?userId=" + userId + '&pageType=' + pageType + '&pageNo=' + pageNo + '&pageSize=' + pageSize, this.appInfo.httpOptions);

    }
    getDeletedNotificationsByPageType(userId: number, pageType: number, pageNo: number, pageSize: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/getdeletednotifications?userId=" + userId + '&pageType=' + pageType + '&pageNo=' + pageNo + '&pageSize=' + pageSize, this.appInfo.httpOptions);

    }

    getAllNotificationCount(userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/getallnotificationcount?userId=" + userId , this.appInfo.httpOptions);

    }

    getAllDeletedNotificationCount(userId: number) {
        return this.http.get<any>(this.appInfo.appUrl + "api/notification/getalldeletednotificationcount?userId=" + userId, this.appInfo.httpOptions);

    }
    
  }




