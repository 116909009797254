import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { TaskModel } from '@shared/models/task-model';
import { CurrentFormDataModel } from '@shared//models/form-data-model';
import { FormDelegateModel } from '../../models/form-delegate-model';

@Injectable({
    providedIn: 'root'
})
export class InstanceService {

    constructor(public appInfo: AppInfoService, private http: HttpClient) {

    }

    startInstance(taskData, isForm = true): Observable<TaskModel> {
        return this.http.post<TaskModel>(this.appInfo.appUrl + "api/instance/startinstance?isForm=" + isForm, JSON.stringify(taskData), this.appInfo.httpOptions);
    }

    getSchemaForm(schemaId, isReadonly) {
        let params = new HttpParams().set("schemaId", schemaId).set("userId", this.appInfo.currentUser.id).set("isReadonly", isReadonly);

        return this.http.get(this.appInfo.appUrl + "api/instance/getschemaform", { params: params });
    }

    getHistorySchemaForm(id, isReadonly) {
        let params = new HttpParams().set("id", id).set("userId", this.appInfo.currentUser.id).set("isReadonly", isReadonly);
        return this.http.get(this.appInfo.appUrl + "api/instance/gethistoryschemaform", { params: params });
    }

    getDatasForScheduler(schemaId, start, end, name) {
        let params = new HttpParams().set("schemaId", schemaId).set("userId", this.appInfo.currentUser.id).set("start", start).set("end", end).set("name", name);
        return this.http.get(this.appInfo.appUrl + "api/instance/getdatasforscheduler", { params: params });
    }

    getCurrentForm(userId,instanceId, processFormId, isReadonly) {
        let params = new HttpParams().set("instanceId", instanceId).set("processFormId", processFormId).set("uId", userId).set("isReadonly", isReadonly);


        return this.http.get(this.appInfo.appUrl + "api/instance/getcurrentform", { params: params });
    }
    getHistoryCurrentForm(id, instanceId, processFormId, isReadonly) {
        let params = new HttpParams().set("id", id).set("instanceId", instanceId).set("processFormId", processFormId).set("userId", this.appInfo.currentUser.id).set("isReadonly", isReadonly);

        return this.http.get(this.appInfo.appUrl + "api/instance/gethistorycurrentform", { params: params });
    }
    saveFormFilesData(objArray, processFormId, deleteFileIds = []): Observable<any> {
        let deleteFileIdsStr = "";
        if (deleteFileIds.length > 0)
            deleteFileIdsStr = "&deleteFileIds=" + deleteFileIds;
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/savefiledata?processFormId=" + processFormId + deleteFileIdsStr, JSON.stringify(objArray), this.appInfo.httpOptions);
    }
    updateOcrFilesData(fileIds = [], type = ""): Observable<any> {
        return this.http.post<void>(this.appInfo.appUrl + "api/instance/checkAndUpdateOcr?type=" + type, JSON.stringify(fileIds), this.appInfo.httpOptions);
    }

    getFormFilesData(objArray): Observable<any> {
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/getfiledata", JSON.stringify(objArray), this.appInfo.httpOptions);
    }
    getProcessFormWithDataId(_id) {
        let params = new HttpParams().set("processformdataid", _id);

        return this.http.get(this.appInfo.appUrl + "api/instance/getprocessformwithdataid", { params: params });
    }
    setProcessFormJson(_obj) {
        //setProcessForm(_pForm.id, _pForm.formJson)
        //let params = new HttpParams().set("processformid", _id).set("fromJson", formJson);
        //return this.http.get(this.appInfo.appUrl + "api/instance/setprocessformjson", { params: params });
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/setprocessformjson", JSON.stringify(_obj), this.appInfo.httpOptions);
    }
    saveFormData(data, schemaId, curentCustomerFormId, customerButton, curentCustomer, curentPrice, curentHirePurchase, curentPaymentDescription, paymentProvider, onePaymentEachSave = false): Observable<CurrentFormDataModel> {
        // curentCustomerFormId, customerButton, curentCustomer, curentPrice

        data.CustomerFormId = curentCustomerFormId.toString();
        data.CustomerFormComponent = JSON.stringify(customerButton);
        data.Customer = curentCustomer.toString();
        data.Price = curentPrice.toString();
        data.HirePurchase = curentHirePurchase.toString();
        data.PaymentProvider = paymentProvider.toString();
        data.PaymentDescription = curentPaymentDescription.toString();
        return this.http.post<CurrentFormDataModel>(this.appInfo.appUrl + "api/instance/savedata?schemaId=" + schemaId + "&onePaymentEachSave=" + onePaymentEachSave, JSON.stringify(data), this.appInfo.httpOptions);
    }

    saveFormDataForPdf(data): Observable<any> {
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/savedataforpdf", JSON.stringify(data), this.appInfo.httpOptions);
    }


    liveOpen(meetingRoomName, startDate, hostUrl, participantUrl, meetingType, processFormId,createRoom): Observable<any> {
        // curentCustomerFormId, customerButton, curentCustomer, curentPrice
        /*
        public string MeetingRoomName { get; set; }
        public string HostUrl { get; set; }
        public string ParticipantUrl { get; set; } 
        public DateTime StartDate { get; set; }  
        public string MeetingType { get; set; } 
        public int ProcessFormId { get; set; }
        public int UserId { get; set; }
        public bool CreateRoom { get; set; }
        */

        
        let data = {
            MeetingRoomName: meetingRoomName,
            StartDate: startDate,
            HostUrl: hostUrl,
            ParticipantUrl: participantUrl,
            MeetingType: meetingType,
            UserId: parseInt(this.appInfo.currentUser.id),
            ProcessFormId: parseInt(processFormId),
            CreateRoom: createRoom
        };
        return this.http.post<any>(this.appInfo.appUrl + "api/instance/liveopenandcreate", JSON.stringify(data), this.appInfo.httpOptions);
    }

    getHistory(instanceId: number): Observable<any[]> {
        let params = new HttpParams().set("instanceId", instanceId.toString()).set("userId", this.appInfo.currentUser.id.toString());

        return this.http.get<any[]>(this.appInfo.appUrl + "api/instance/gethistory", { params: params });
    }

    uniqueControl(text, componentId, controlKey, isMenu = false) {
        let params = new HttpParams().set("uniqueText", text).set("componentId", componentId).set("controlKey", controlKey).set("isMenu", isMenu);
        return this.http.get(this.appInfo.appUrl + "api/instance/uniqueControl", { params: params });
    }

    changeFormAvability(formDataId, isAvalible) {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("formDataId", formDataId).set("isAvalible", isAvalible);

        return this.http.get(this.appInfo.appUrl + "api/instance/changeformavability", { params: params });
    }

    changeMenuFormAvability(formId, pageId, formDataId, isAvalible) {
        let params = new HttpParams().set("userId", this.appInfo.currentUser.id).set("formId", formId).set("nodeKey", pageId).set("formDataId", formDataId).set("isAvalible", isAvalible);

        return this.http.get(this.appInfo.appUrl + "api/instance/changemenuformavability", { params: params });
    }

    delegateForm(delegateModel: FormDelegateModel) {
        return this.http.post<FormDelegateModel>(this.appInfo.appUrl + "api/instance/delegateForm", JSON.stringify(delegateModel), this.appInfo.httpOptions);
    }

    errorHandler(error) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            // Get client-side error
            errorMessage = error.error.message;
        } else {
            // Get server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

    getGlobalParameters() {
        return this.http.get(this.appInfo.appUrl + "api/user/getglobalparameters", this.appInfo.httpOptions);
    }

    getHeaders(): Observable<string[]> {
        return this.http.get<string[]>(this.appInfo.appUrl + "api/instance/gethistoryheaders", this.appInfo.httpOptions);
    }
}
