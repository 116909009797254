import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreSidebarModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';

import { EmptyLayoutComponent } from './empty-layout.component';
import { NavbarModule } from '../../components/navbar/navbar.module';
import { ContentModule } from '../../components/content/content.module';
import { FooterModule } from '../../components/footer/footer.module';
import { MenuModule } from '../../components/menu/menu.module';

@NgModule({
    declarations: [EmptyLayoutComponent],
    imports: [RouterModule, CoreCommonModule, CoreSidebarModule, NavbarModule, ContentModule, MenuModule, FooterModule],
    exports: [EmptyLayoutComponent]
})
export class EmptyLayoutModule { }
