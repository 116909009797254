import { Component, NgModule, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-custom-loading',
  templateUrl: 'loading.component.html',
    styleUrls: ['./loading.component.scss']
})

export class LoadingComponent {
  constructor( ) {

  }
}

@NgModule({
  imports: [
    CommonModule
  ],
    declarations: [],
    exports: []
})
export class LoadingModule { }
