import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

    constructor() { }

  save(key, data) {
    localStorage.setItem(key, data);
  }

  get(key) {
    return localStorage.getItem(key) || null;
  }

  delete(key) {
    localStorage.removeItem(key);
  }

  }
