import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';
import { CoreConfigService } from '@core/services/config.service';
import { ContentComponent } from './content.component';


@NgModule({
    declarations: [ContentComponent],
    imports: [RouterModule, CoreCommonModule],
    providers: [CoreConfigService],
    exports: [ContentComponent]
})
export class ContentModule {}
