import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"; 
import { DxSelectBoxModule, DxFileUploaderModule } from 'devextreme-angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ContentHeaderComponent } from "../../layout/auth-layout/components/content-header/content-header.component";

import {
    InstanceService, TaskService, GenericService, ReportService, AuthService
} from '@shared/services/api';

import { CoreCommonModule } from "../../../@core/common.module";

import { NgSelectModule } from "@ng-select/ng-select";
import { CoreDirectivesModule } from "../../../@core/directives/directives";
import { NoteComponent } from "./note.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,   
        ReactiveFormsModule, 
        NgbModule,
        DragDropModule, 
        FormsModule,  
        CoreCommonModule,
        CoreDirectivesModule,
        TranslateModule
    ],
    declarations: [ 
        NoteComponent
    ],


    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    exports: [NoteComponent],
    providers: [InstanceService, TaskService, GenericService, ReportService, AuthService]
})
export class NoteModule { }
