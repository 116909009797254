import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { VerticalLayoutModule } from './theme-layout/vertical/vertical-layout.module';
import { HorizontalLayoutModule } from './theme-layout/horizontal/horizontal-layout.module';
import { EmptyLayoutModule } from './theme-layout/empty/empty-layout.module';
import { CustomBreakPointsProvider } from './custom-breakpoints';
import { AuthLayoutRoutingModule } from './auth-layout.routing.module';
import { AuthLayoutComponent } from './auth-layout.component';
import { CorePipesModule } from '../../../@core/pipes/pipes.module';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

import { TaskService, ReportService } from '@shared/services/api';
import { MenuService } from '@shared/services/api/menu.service';
import { ChatService } from '../../../@shared/services/api/chat.service';
import { CoreConfigService } from '../../../@core/services/config.service';
import { CoreLoadingScreenService } from '../../../@core/services/loading-screen.service';
import { DragulaModule } from 'ng2-dragula';
import { TranslateModule } from '@ngx-translate/core';
import { DxButtonModule, DxPopupModule } from 'devextreme-angular';
@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule.withConfig({ disableDefaultBps: true }),
        VerticalLayoutModule,
        HorizontalLayoutModule,
        EmptyLayoutModule,
        CoreCommonModule,
        CoreSidebarModule,
        CoreThemeCustomizerModule,
        CorePipesModule,
        TranslateModule,
        DragulaModule.forRoot(),
        DxPopupModule,
        DxButtonModule
    ],
    providers: [
        CustomBreakPointsProvider,
        CoreSidebarService,
        TaskService,
        ReportService,
        MenuService,
        CoreConfigService,
        CoreLoadingScreenService,
    ],
    exports: [AuthLayoutComponent, CoreSidebarModule, VerticalLayoutModule, HorizontalLayoutModule, EmptyLayoutModule],
    declarations: [AuthLayoutComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
    //exports: [AuthLayoutComponent]
})
export class AuthLayoutModule { }
