import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from 'app/auth/models';
import { CoreMenu } from '../../types';

import { EventEmitterService } from '@shared/services/common/event-emitter.service';
import { PageDesignerService } from '../../../@shared/services/api/pagedesigner.service';
import { ProfileService } from '../../../app/pages/profile/profile.service';
import { AppInfoService } from '../../../@shared/services/common';

@Injectable({
    providedIn: 'root'
})
export class CoreMenuService {
    currentUser: User;
    onItemCollapsed: Subject<any>;
    onItemCollapseToggled: Subject<void>;

    // Private
    private _onMenuRegistered: BehaviorSubject<any>;
    private _onMenuUnregistered: BehaviorSubject<any>;
    private _onMenuChanged: BehaviorSubject<any>;
    private _currentMenuKey: string;
    private _registry: { [key: string]: any } = {};

    /**
     * Constructor
     *
     * @param {Router} _router
     * @param {AuthenticationService} _authenticationService
     */
    constructor(
        private _router: Router,
        private _eventEmitterService: EventEmitterService,
        private profileService: ProfileService,
        private _appInfoService: AppInfoService,
        private _pageDesignerService: PageDesignerService) {
        //this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));

        // Set defaults
        this.onItemCollapsed = new Subject();
        this.onItemCollapseToggled = new Subject();

        // Set private defaults
        this._currentMenuKey = null;
        this._onMenuRegistered = new BehaviorSubject(null);
        this._onMenuUnregistered = new BehaviorSubject(null);
        this._onMenuChanged = new BehaviorSubject(null);
    }

    // Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
    * onMenuRegistered
    *
    * @returns {Observable<any>}
    */
    get onMenuRegistered(): Observable<any> {
        return this._onMenuRegistered.asObservable();
    }

    /**
    * onMenuUnregistered
    *
    * @returns {Observable<any>}
    */
    get onMenuUnregistered(): Observable<any> {
        return this._onMenuUnregistered.asObservable();
    }

    /**
    * onMenuChanged
    *
    * @returns {Observable<any>}
    */
    get onMenuChanged(): Observable<any> {
        return this._onMenuChanged.asObservable();
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
    * Register the provided menu with the provided key
    *
    * @param key
    * @param menu
    */
    register(key, menu): void {
        // Confirm if the key already used
        if (this._registry[key]) {
            //console.error(`Menu with the key '${key}' already exists. Either unregister it first or use a unique key.`);

            //return;
        }

        // Add to registry
        this._registry[key] = menu;

        // Notify subject
        this._onMenuRegistered.next([key, menu]);
    }

    /**
    * Unregister the menu from the registry
    *
    * @param key
    */
    unregister(key): void {
        // Confirm if the menu exists
        if (!this._registry[key]) {
            console.warn(`Menu with the key '${key}' doesn't exist in the registry.`);
        }

        // Unregister sidebar
        delete this._registry[key];

        // Notify subject
        this._onMenuUnregistered.next(key);
    }

    /**
     * Register the new menu item
     *
     * @param key
     */
    PushMenuAndRegister(key, menu): void {
      
        // Confirm if the menu exists
        if (!this._registry[key]) {
            console.warn(`Menu with the key '${key}' doesn't exist in the registry.`);
        }

        let findIndex = this._registry[key].findIndex(el => el.id == menu.id)
        if (findIndex == -1) {

            this._registry[key].push(menu);
        } else {
            this._registry[key][findIndex] = menu;
        }

        // Notify subject
        this._onMenuRegistered.next(key);
    }

   


    /**
    * Get menu from registry by key
    *
    * @param key
    * @returns {any}
    */
    getMenu(key): any {
        // Confirm if the menu exists
        if (!this._registry[key]) {
            console.warn(`Menu with the key '${key}' doesn't exist in the registry.`);

            return;
        }

        // Return sidebar
        return this._registry[key];
    }

    /**
    * Get current menu
    *
    * @returns {any}
    */
    getCurrentMenu(): any {
        if (!this._currentMenuKey) {
            console.warn(`The current menu is not set.`);

            return;
        }

        return this.getMenu(this._currentMenuKey);
    }

    /**
    * Set menu with the key as the current menu
    *
    * @param key
    */
    setCurrentMenu(key): void {
        // Confirm if the sidebar exists
        if (!this._registry[key]) {
            console.warn(`Menu with the key '${key}' doesn't exist in the registry.`);

            return;
        }

        // Set current menu key
        this._currentMenuKey = key;

        // Notify subject
        this._onMenuChanged.next(key);
    }

    /**
     * On Menu Item Click Event 
     * Not belong to theme (River)
     * 
     * @param menuItem
     * */
    onMenuItemClickEvent(menuItem: CoreMenu): void {
        if (menuItem.isProcess || menuItem.order == 2) {
            this.selectItemProcess(menuItem)
        }
      
        else if (menuItem.isMenu) {
            this.selectItemMenu(menuItem)
        }

        else if (menuItem.isReport) {
            
            this.selectItemReport(menuItem)

        }

        else if (menuItem.isPages) {
            this.selectItemPage(menuItem)
        }

        else if (menuItem.refresh) {
            this.navigateWithRefresh(menuItem.url)
        }
        else if (menuItem.isAnalytics) {
            this.selectItemWidget(menuItem)
        }
        else {
            this.navigateWithoutRefresh(menuItem.url)
        }
    }

    navigateWithRefresh(url) {
        this._router.navigate(['/' + url])
    }

    navigateWithoutRefresh(url) {
        this._router.navigate(['/' + url])
    }
       //////////////////////////
     ///////// WIDGET ////////
    //////////////////////////


    selectItemWidget(menuItem) {
        this.profileService.getPreferences(this._appInfoService.currentUser.id).subscribe(data => {
       
            if (data  && data[0].analyticspreferences) {
                const widgetUrl = data[0].analyticspreferences;
                
                var params = widgetUrl.split('?')[1].split('&');
                var pageId;
                params.forEach(function (param) {
                    var [key, value] = param.split('=');
                    if (key === 'pageId') {
                        pageId = value;
                    }
                });

                const navigationExtras: NavigationExtras = {
                    queryParams: { pageId: pageId }
                };
                this._router.navigate(['/app/pageviewer'], navigationExtras);

                //this._router.navigate([widgetUrl]);
            } else {
                this.navigateWithoutRefresh(menuItem.url)
                console.error('Invalid data or analyticspreferences is missing.');
            }
            //ELSE�NDE DE MENU�TEM.URL
        });
    }

        //////////////////////////
    ///////// PROCESS ////////
    //////////////////////////
    selectItemProcess(menuItem) {

        if (this._router.url.toLowerCase() == "/app/home") {
            let data_scheme = {
                id: menuItem.id,
                name: menuItem.title
            };
            this._eventEmitterService.createTaskAndStartForm(data_scheme);

        } else {
            this._router.navigate(['/app/home'], { state: { id: menuItem.id, name: menuItem.title, isScheme: menuItem.isProcess } });
        }

    }
      

 

      /////////////////////////
     ///////// MENU //////////
    /////////////////////////
    selectItemMenu(menuItem) {
            //this._router.routeReuseStrategy.shouldReuseRoute = function () {
            //    return false;
            //}
            //this._router.onSameUrlNavigation = 'reload';
            //  this.router.runGuardsAndResolvers= 'always'
        this._router.navigate(['/app/menu/' + menuItem.id + "/" + menuItem.formId + "/" + menuItem.schemaId], { state: { formName: menuItem.name, isOpenForm: false } });

    }

      /////////////////////////
     ///////// RAPOR /////////
    /////////////////////////

    selectItemReport(menuItem) {

        if (this._router.url.toLowerCase() == "/app/report") {
            let data = {
                event: event, id: menuItem.id, name: menuItem.title, isReport: menuItem.isReport
            };
            this._eventEmitterService.pageWithRouteChange(data);
        } else {
            this._router.navigate(['/app/report'], { queryParams: { reportId: menuItem.id } });
        }

    }

    ////////////////////////////////
    ///////// PageDesigner /////////
    ////////////////////////////////

    selectItemPage(menuItem) {
        if (!!menuItem) {
            if (menuItem.pageType == 1)
                this._router.navigate(['/app/pageviewer'], { queryParams: { pageId: menuItem.id } });
            if (menuItem.pageType == 2) {
                var menuOptions = JSON.parse(menuItem.properties);
                var menuSettings = menuOptions.menuSettings
                this._router.navigate(['/app/menu/' + menuSettings.menu.id + "/" + menuSettings.menu.formId + "/" + menuSettings.menu.schemeId], { state: { formName: menuSettings.menu.name, isOpenForm: false } });
            }
            if (menuItem.pageType == 3)
                this._router.navigate(['/app/pageviewer'], { queryParams: { pageId: menuItem.id } });
           
        }

    }

}
