import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { CoreMenuService } from '../../core-menu.service';
import { AppInfoService, EventEmitterService } from '../../../../../@shared/services/common';
import { TaskService } from '../../../../../@shared/services/api';
@Component({
  selector: '[core-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuHorizontalItemComponent {
  @Input()
    item: any;
    analiticsIsTrue: boolean;
    licenseType: string;
    isNotPro: boolean;
    transferToDatagrid: boolean;
    constructor(private menuService: CoreMenuService, private appInfo: AppInfoService, private eventEmitterService: EventEmitterService, private taskService: TaskService) {
        if (appInfo.currentUser.tabs && appInfo.currentUser.tabs.includes(6)) {
            this.analiticsIsTrue = true;
        } else {
            this.analiticsIsTrue = false;
        }
        this.licenseType = this.appInfo.currentUser.licanseType;
        if (this.licenseType == '1') {
            this.isNotPro = true;
        }
        setTimeout(() => {

            console.log(this.item);
        },7000)
    }
    @HostListener('document:contextmenu', ['$event'])
    onRightClick(event: MouseEvent): void {
        this.eventEmitterService.itemRightClickEvent(true);
        
    }
    onMenuItemClick(item: any) {      
       this.menuService.onMenuItemClickEvent(item);
    }
   
    itemClick(event, title, id) {
        if (event.itemData.name == "addFavoriteScheme") {
            this.taskService.SetFavorite(id).subscribe(data => {
                this.eventEmitterService.addFavSchemeEvent(data.list);
            });
        }
        else if (event.itemData.name == "filterItems") {
            this.eventEmitterService.applyMenuToDataGridFilter(title);
        }
        else if (event.itemData.name == "deleteFavoriteScheme") {
            this.taskService.deleteFavoriteScheme(this.appInfo.currentUser.id, id).subscribe(data => {
                if (data) {
                    this.eventEmitterService.favSchemeDeleteEvent(id);
                }
            });
        }
    }
}
