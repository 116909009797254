import { Component, ChangeDetectorRef, OnInit, ViewChild, Input, ElementRef, AfterViewInit, ViewEncapsulation, OnDestroy, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, RouterState } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from "lodash";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { ComponentService } from '@shared/services/common/component.service';
import { EventEmitterService } from '@shared/services/common/event-emitter.service';
import {
    InstanceService,
    TaskService,
    GenericService,
    ReportService,
    AuthService,
    UserService
} from '@shared/services/api';
import { CoreLoadingScreenService } from '@core/services/loading-screen.service';
import { colors } from '../../colors.const';
import { CoreConfigService } from '../../../@core/services/config.service';
import { NoteService } from './note.service';
import { NoteModel } from './note.model';
import { Observable, Subject, Subscription } from 'rxjs';
import { CdkDragDrop, CdkDragEnd, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';

import { FormControl, FormGroup, Validator, FormsModule } from '@angular/forms';
import signalR from '@aspnet/signalr';
import notify from 'devextreme/ui/notify';
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import moment from 'moment';
import { CoreTranslationService } from '../../../@core/services/translation.service';
import { TranslateService } from '@ngx-translate/core';

import { locale as english } from 'app/auth/data/en'
import { locale as french } from 'app/auth/data/fr';
import { locale as german } from 'app/auth/data/de';
import { locale as turkish } from 'app/auth/data/tr';
import { locale as arabic } from 'app/auth/data/ar';
import { locale as azerbaijani } from 'app/auth/data/az';
import { locale as georgian } from 'app/auth/data/geo';
import { locale as dutch } from 'app/auth/data/nl';
import { locale as italian } from 'app/auth/data/it';
import { locale as russian } from 'app/auth/data/ru';


@Component({
    selector: 'sticky-note',
    templateUrl: 'note.component.html',
    styleUrls: ['./note.component.scss'],
    
})

export class NoteComponent implements OnInit {
    notePosition: { x: number, y: number } = { x: 0, y: 0 };
    clickCount = 0;
    id: number;
    OpenEdit: boolean = false;
    Colors: boolean = true;
    appUrl = ""
    isMenuToggled: boolean;
    noteContent: any;
    noteDate: Date;
    isDeleted: boolean;
    myNotes: any[] = []
    openNotes: boolean;
    Edit: boolean;
    colors = [
        { value: "#25f20f", text: "Düşük" },
        { value: "#ffef08", text: "Orta" },
        { value: "#ff0000", text: "Yüksek" }
    ];
    _isUpdated: boolean = false;
    selectedColor: string;
    showSticky: boolean;
    lenght: number;
    colorSelected: boolean;
    NoteDate: Date;
    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        public taskService: TaskService,
        public instanceService: InstanceService,
        private eventEmitterService: EventEmitterService,
        public genericService: GenericService,
        public reportingService: ReportService,
        public appInfo: AppInfoService,
        private authService: AuthService,
  
        private noteservice: NoteService,
        private _coreTranslationService: CoreTranslationService,
        public _translateService: TranslateService,    ) {

        this.modelChanged.pipe(debounceTime(6000)).subscribe(() => {
            this.saveNote();
            this.getStickNote();

            this.id = 0;

            this.Edit = false;
            this.OpenEdit = true;
        });

        this._coreTranslationService.translate(english, french, german, turkish, arabic, azerbaijani, georgian, dutch, italian,russian);

        this.appUrl = this.appInfo.appUrl

    }


    modelChanged = new Subject<string>();
    ngOnChanges(changes: SimpleChanges) {
        if (changes.showSticky) {

            this.showStickyNotes();
        }
    }
    userStatus = false;
    ngOnInit() {
        this.getStickNote()
        this.OpenEdit = true;

        this.authService._isLoggedIn.subscribe(data => {
            this.userStatus = data;
        })
        let _thisPage = this;
        var playAnimationEmit = setInterval(() => {
            if (_thisPage.userStatus) {
                this.playAnimation();

            } else {
                clearInterval(playAnimationEmit)
            }
        }, 1800000);


        this.showStickyNotes();
    }


    showStickyNotes() {
        this.eventEmitterService.invokeShowStickyEvent.subscribe((event: { show: boolean }) => {
            this.showSticky = event.show;
            if (this.showSticky) this.openNotes = false; this.OpenEdit = true;
        });

    }


    controlSticky() {
        if (this.OpenEdit == false && this.Edit == false && this.openNotes == false) {
            this.OpenEdit = true;
        }
    }

    playAnimation() {
        if (this.showSticky) {
            const noteElement = document.getElementById('_shake');
            noteElement.style.animationPlayState = 'running';

            setTimeout(() => {
                noteElement.style.animationPlayState = 'paused';
            }, 999);
        }
        else {
            return;
        }
        
    }

    getCurrentDateTime(): Date {
        return new Date();
    }

    getStickNote() {
        this.noteservice.getNoteList(this.appInfo.currentUser.id).subscribe(data => {

            this.myNotes = data;

        })

    }
    writeNoteScreen(){
        this.openNotes = false;
        this.Edit = true;
    }
    selectColor(colorId: number) {
        this.Colors = !this.Colors;
        if (colorId === 1) {
            this.selectedColor = "#9376E0";
        }  
        else if (colorId === 4) {
            this.selectedColor = "#C1D0B5"
        }
      
        else if (colorId === 6) {
            this.selectedColor = "#C4DFDF"
        }
        else if (colorId === 7) {
            this.selectedColor = "#F99B7D";
        }

        else if (colorId === 8) {
            this.selectedColor = "#FFEDA5"
        }
        

    }
   
    deleteNote(id: number) {

        this.noteservice.deleteNote(this.appInfo.currentUser.id, id).subscribe(data => {

            this.getStickNote();
            this.openNotes = false;
            this.OpenEdit = true;
            this.Edit = false;
         });

    }

    editNote(note: any) {

        this.noteContent = note.noteContent;
        this.NoteDate = note.noteDateFormatted
        this.selectedColor = note.selectedColor;
        this.id = note.id
        this.openNotes = false;
        this.Edit = true;
        this._isUpdated = true;
        

    }

    dblclick1(): void {
        this.getStickNote();
        this.OpenEdit = false;
        this.Edit = true
    }
    dblclick3() {
        this.OpenEdit = true;
        this.Edit = false
    }
    dblclick2() {
        this.openNotes = true;
        this.OpenEdit = false;

    }
 
    dblclick4() {
        this.openNotes = false; 
        this.OpenEdit = true;
    }
    

    txtQuery: string; // bind this to input with ngModel
    txtQueryChanged: Subject<string> = new Subject<string>();

    saveNote() {
        this._isUpdated = false;
        const note: NoteModel = {
            id: this.id,
            userId: this.appInfo.currentUser.id,
            selectedColor: this.selectedColor,
            _content:this.noteContent ,
            notedate: this.noteDate,
            userName: this.appInfo.currentUser.fullName,
            isDeleted: this.isDeleted,
           
        }
        if (this.noteContent != "" || this.noteContent == null) {

            this.noteservice.saveNote(note).subscribe(data => {
            })
            this.noteContent = "";
        }
        else {
            notify(`Content  field cannot be empty!`, 'error', 1500);
        }

        this.getStickNote();
        this.Edit = false;
        this.OpenEdit = true;
        this._isUpdated = false;
    }

    onChange(val) {
        this.modelChanged.next(val);
       }

    onDragEnd(event: CdkDragEnd): void {
        const { source, distance } = event;
           if (distance.x === 0 && distance.y === 0) {
              return;
        }
           this.notePosition = { x: source.getFreeDragPosition().x, y: source.getFreeDragPosition().y };
    }
    
}


    

