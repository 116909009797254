import { Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef, ChangeDetectorRef } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { AppInfoService } from '@shared/services/common/app-info.service';
import { EventEmitterService } from '@shared/services/common/event-emitter.service';
import { TaskService, ReportService, AuthService } from '@shared/services/api';

@Component({
    selector: 'vertical-layout',
    templateUrl: './vertical-layout.component.html',
    styleUrls: ['./vertical-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
    coreConfig: any;

    // Private
    private _unsubscribeAll: Subject<void>;

    public isLoggedIn = false;
    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     */
    constructor(
        private _coreConfigService: CoreConfigService,
        private _elementRef: ElementRef,
        private reportingService: ReportService,
        private _coreMenuService: CoreMenuService,
        private _appInfoService: AppInfoService,
        private _eventEmitterService: EventEmitterService,
        private _changeDetectorRef: ChangeDetectorRef,
        private _taskService: TaskService,
        private _reportingService: ReportService,
        private _authService: AuthService) {

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    canChat: any = false;
    ngOnInit(): void {

        // Subscribe to login changes
        this._authService._isLoggedIn.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
            this.isLoggedIn = data;
            if (this.isLoggedIn) {
                this.canChat = this._appInfoService.RiverEntity.users.find(x => x.id == this._appInfoService.currentUser.id).canChat;
                this.canChat = typeof this.canChat != "undefined" ? this.canChat : false;
            }
        })
        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
        
        try { this._changeDetectorRef.detectChanges(); } catch (ex) { }

    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    

    
}
