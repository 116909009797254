import { Component, NgModule, OnDestroy, OnInit } from '@angular/core';

import { ActivatedRoute,  } from '@angular/router';

import { AuthService, GenericService, MailService } from '@shared/services/api';
import { AppInfoService, ComponentService } from '@shared/services/common';

@Component({
    selector: 'app-login-form',
    templateUrl: 'htmlEditor.component.html',
    styleUrls: ['./htmlEditor.component.scss'],
})
export class HtmlEditorComponent implements OnInit, OnDestroy {


    languageLocal: any = "";
    closeIsMyPopup() {
        this.isHtmlPopup = true
    }
    localguid = "";
    isHtmlPopup = false; // ismi de�i�
    htmlMailObject = { Id: 0, Guid: "", Htmlbody: "", Userid: 0 }
    isMultiline: boolean = true;
    constructor(private authService: AuthService, public appInfo: AppInfoService,
        private route: ActivatedRoute,
        public genericService: GenericService, private componentService: ComponentService, private mailService: MailService) {
        this.route.queryParams.subscribe(params => {
            
            if (typeof params.guid != "undefined" && params.guid != "") {
                this.localguid = params.guid
                this.isHtmlPopup = true;
                //console.log(this.localguid)
                this.mailService.getHtmlBody(this.localguid).subscribe(response => {
                    if (!!response) {
                        this.htmlMailObject = response;
                        //console.log(response)
                    }
                });
            }

        });
        this.languageLocal = this.componentService.getLanguageSource(); //language

    }
    
    ngOnInit() {

    }

    ngOnDestroy() { 

    }

    saveText() {
        //let data = { id, guid, htmlbody, , }
        this.htmlMailObject.Guid = this.localguid;
        this.htmlMailObject.Userid = this.appInfo.currentUser.id;
        this.mailService.postHtmlBody(this.htmlMailObject).subscribe(data => {
            if (!!data) {
                //console.log(data)
                this.htmlMailObject = { Id: 0, Guid: "", Htmlbody: "", Userid: 0 }
                this.componentService.showAlert(this.languageLocal.add, "success", 300);

            }
            else {
                this.componentService.showAlert(this.languageLocal.toastMistake, "warning", 5000)
            }
        });




    }
} 
