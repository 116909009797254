import { NgModule } from '@angular/core';

import { CoreCommonModule } from '@core/common.module';

import { MenuComponent } from './menu.component';
import { VerticalMenuModule } from './vertical-menu/vertical-menu.module';
import { HorizontalMenuModule } from './horizontal-menu/horizontal-menu.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [MenuComponent],
    imports: [CoreCommonModule, VerticalMenuModule, HorizontalMenuModule, TranslateModule],
  exports: [MenuComponent]
})
export class MenuModule {}
  