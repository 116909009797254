import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';

import { CoreCommonModule } from '@core/common.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';
import { NavbarComponent } from './navbar.component';
import { NavbarSearchComponent } from './navbar-search/navbar-search.component';
import { NavbarBookmarkComponent } from './navbar-bookmark/navbar-bookmark.component';
import { NavbarNotificationComponent } from './navbar-notification/navbar-notification.component';
import { TranslateModule } from '@ngx-translate/core';
import { DevExtremeModule } from '../../../../devextreme.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { CoreSidebarModule } from '../../../../../@core/components/core-sidebar/core-sidebar.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DxGalleryModule, DxSelectBoxModule } from 'devextreme-angular';
import { NgApexchartsModule } from 'ng-apexcharts';
import { QuillModule } from 'ngx-quill';
import { ContentHeaderModule } from '../content-header/content-header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreDirectivesModule } from '../../../../../@core/directives/directives';
import { CardSnippetModule } from '../../../../../@core/components/card-snippet/card-snippet.module';



const routes: Routes = [
    {
        path: 'toastr',
        component: NavbarNotificationComponent,
        data: { animation: 'toastr' }
    }
];

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

@NgModule({
    declarations: [NavbarComponent, NavbarSearchComponent, NavbarBookmarkComponent, NavbarNotificationComponent],
    imports: [RouterModule, NgbModule, CoreCommonModule, PerfectScrollbarModule, CoreTouchspinModule, DevExtremeModule, TranslateModule.forChild(), CoreSidebarModule,
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        DevExtremeModule,
        
        CardSnippetModule,

        DxSelectBoxModule,
        NgApexchartsModule,
        FormsModule,
        ToastrModule,
        QuillModule.forRoot(),
        CoreCommonModule,
        ContentHeaderModule,
        NgSelectModule,
        CoreSidebarModule,
        CoreDirectivesModule,
        DxGalleryModule,],
    providers: [
        {

 
      provide: PERFECT_SCROLLBAR_CONFIG, 
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [NavbarComponent]
})
export class NavbarModule {}
