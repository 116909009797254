export const locale = {

    lang: 'ENEN',

    data: {

        loginButton: "Login",

        userName: "Username",

        pass: "Password",

        remember: "Remember Me",

        forgotPass: "Forget Password",

        modules: "Modules",

        generic: "General",

        search: "Search",

        logout: "Logout",

        waitingJob: "My Pending Works",

        myStarted: "I started",

        myIncluded: "I'm Included",

        myCompleted: "Completed Works",
        jobListMessageArea: "Job List Message Area",

        jobList: "Job List",

        formNo: "Form No",

        status: "Status",

        starter: "Started",

        generateLink: "Generate Link",

        assigned: "Assigned",

        timeoutDate: "Timeout Tar.",

        startDate: "Start Date",

        processing: "Processing",

        you: "you",

        history: "History",

        description: "Description",

        date: "Date",

        growShrink: "Grow/Reduce",

        close: "Close",

        gridReset: "Reset Grid Settings",

        columnSelect: "Column Selection",

        jobName: "Job Name",

        keyword: "Keyword",

        completion: "Completion",

        all: "All",

        completedDate: "End Date",

        completions: "Completed",

        ongoing: "Ongoing",

        formName: "Form Name",

        sunday: "Sunday",

        monday: "Monday",

        tuesday: "Tuesday",

        wednesday: "Wednesday",
        thursday: "Thursday",

        friday: "Friday",

        saturday: "Saturday",

        pdfExport: "Export Pdf",

        sendPassword: "Send My Password",

        assignedDate: "Assignment Date",

        events: "Events",

        new: "New",

        save: "Save",

        formula: "Formula",

        selectDataSource: "Select DataSource",

        requiredField: "Required Field",

        suffix: "Suffix",

        valueColumn: "Value Column",

        showLength: "Show Number of Tasks in Columns",

        clearButton: "Clear Button",

        allCaps: "All Caps",

        autoAsc: "Auto Asc",

        width: "Width",

        length: "Length",

        functions: "Functions",

        selectElement: "Select Element",

        selectColumn: "Select Column",

        edit: "Edit",

        changeEventActions: "Change Event Actions",

        barcode: "Barcode",

        systemInfo: "System Information",

        prefix: "Prefix",

        max: "Maximum",

        showMinMaxInfo: "Display Minimum and Maximum information",

        selectUser: "Select User",

        spinButton: "Spin Button",

        numberIncDecButton: "Increase-Decrease Number Button",

        decimal: "Decimal Number",

        numeral: "Number",

        wrongFormat: "you entered the wrong format",

        height: "Height",

        microphone: "Microphone",

        notifyDataReset: "Data Reset",

        data: "Data",

        dynamicData: "Dynamic Data",

        manualData: "Manual Data",

        selectDataGrid: "Select Datagrid",

        overrideUserChange: "Crush user changes",

        textColumn: "Text Column",

        makeInitialValue: "Default Initial Value",

        undo: "Undo",

        copy: "Copy",

        preview: "Preview",

        delete: "Delete",

        cancel: "Cancel",

        languageSettings: "Language Settings",

        messageSqlLinkDeleted: "Since you added data manually, the sql script and link address will be deleted! Continue?",

        messageSqlLinkDeletedForMenu: "Selecting data as a menu will delete the SQL script file and link address! Do you want to proceed?",

        permissions: "Permissions",

        objectid: "Object ID",

        fontSize: "Font Size",

        fontWidth: "Text Width",

        fontStyle: "Font Style",

        underline: "Underline",

        textColor: "Text Color",

        visibility: "Visibility",

        textAlignment: "Text Alignment",

        settings: "Settings",

        messageUnsaved: "Are you sure you want to exit without saving your changes?",

        warning: "Warning!",

        userAuthorizations: "User Authorizations",

        formPreferences: "Form Preferences",

        formOpeningevent: "Form Open Event",

        chooseConnectionAddress: "Choose Connection Address",

        selectPriorty: "Select Priority",

        loadingEditor: "Loading Editor ...",

        showAddButton: "Show Add Row Button",

        update: "Update",

        connectionID: "Connection ID",

        formPreview: "Form Preview",

        nameDisplayedform: "Name of the displayed form",

        formNamevariable: "Form Name Variable",

        category: "Category",
        formColor: "Form Color",

        generalForm: "General Form",

        saveForm: "Save Form",

        messageNeworupdate: "The changes you made on the existing form will be deleted. Continue?",

        messageDelete: "Are you sure you want to delete the form?",

        toastDeleted: "Form Deleted",

        toastError: "Sorry, an error occurred while processing",

        alertDifferentID: "This id is used elsewhere, please specify a different id.",

        confirmChanges: "Confirm changes",
        toastUserpermission: "User does not have form design permission.",

        toastCodeeditor: "Codeditor already exists",

        messageDeletesure: "Elements in this component will be deleted",

        messageSure: "Are you sure?",


        successful: "Succesful",

        rrror: "Error",

        toastFormupdate: "Form Updated",

        toastMistake: "Sorry, an error occurred while processing",

        toastSaved: "Saved",

        toastCopied: "Copied",

        toastEmptyFormMessage: "No form elements added yet",

        toastGiveName: "Please give your form a name",

        toastGiveVariableName: "Please give a name to the form name variable",

        toastVariableNameNumberWarning: "Variable name cannot start with number",

        formCopy: "Copy Form",
        messageButtonDelete: "Are you sure you want to delete the button?",

        noDataFound: "No Data Found",

        language: "Language",

        filterDatagrid: "Datagrid Filtering",

        addManualData: "Add Manual Data at Runtime",

        dateFormat: "Date Format",

        messageSqlLinkDelete: "Because you added data manually, sql script and link address will be deleted! Continue?",

        timeOption: "Clock Option",

        defaultTodaysDate: "Let default today's date",

        dateRange: "Date Range",

        minToday: "Minimum Today",

        maxToday: "Maximum Today",

        dateRangeDay: "Date Range (Day)",

        enterDateRangeValue: "Enter the date range value",

        imageIdColumn: "Id Column",

        statusSettings: "Status Settings",

        active: "Active",

        passive: "Passive",

        enable: "Enabled",

        disable: "Disabled",
        visible: "Visible",

        invisible: "Invisible",

        items: "Items",

        showItems: "Display Items",

        array: "Array",

        letLabelAppear: "Show Label",

        add: "Add",

        horizontal: "Horizontal",

        vertical: "Vertical",

        text: "Text",
        alertFillRequiredFields: "Please fill in the required fields",

        alertSignatureField: "Signature field cannot be empty",

        messageApproveTransaction: "Do you approve",

        button: "Button",

        reasonforRefusal: "Reason for Rejection",

        enterReasonRejection: "Enter Reason for Rejection",

        reject: "Reject",

        addDeleteButton: "Add/Delete Button",

        showBorder: "Border Appear",

        buttons: "Buttons",

        clean: "Clear",

        buttonSettings: "Button Settings",

        saveLocation: "Save Location",

        paymentButton: "Payment Button",

        paymentProvider: "Payment Provider",

        price: "Price",

        numberOfInstallments: "Number of Installments",

        descriptionField: "Description Field",

        current: "Current",

        currentForm: "Current Form",

        name: "Name",

        surname: "Surname",

        country: "Country",

        city: "City",

        address: "Address",

        authorizations: "Authorizations",

        users: "Users",

        user: "User",

        givePermission: "Authorize",

        groups: "Groups",

        group: "Group",

        saveandSend: "Save and Send",

        approve: "Approve",

        sendBack: "Send Back",

        deslineAndEnd: "Reject and End",

        complete: "Completed",

        blue: "Blue",

        gray: "Gray",
        green: "Green",

        red: "Red",

        left: "Left",

        right: "Right",

        color: "Color",

        symbol: "Symbol",

        position: "Position",

        action: "Action",

        total: "Total",

        filePath: "File Path",

        fileName: "File Name",

        fileUpload: "File Upload",

        choose: "Choose",

        updateData: "Update Data",


        show: "Show",

        columns: "Columns",

        changeEvent: "Change Event",

        columnSettings: "Column Settings",

        columnName: "Column Name",

        deleteColumn: "Delete Column",

        columnSqlScripts: "Column Sql Scripts",

        imageNameColumn: "File Name Column",

        columnLanguageSetting: "Column Language Setting",

        dataSource: "Datasource",

        filter: "Filter",

        reset: "Reset",

        column: "Column",

        image: "Image",

        searchLocation: "Search Location...",

        draggable: "Draggable",

        usersCanChange: "Users Can Change",

        multiMarker: "Multi Marker",

        markerLimit: "Mark Limit (0: No Limit)",

        meetingType: "Meeting Type",

        meetingName: "Meeting Name",


        loadingFiles: "Loading files...",

        fileSizeExceeded: "File size exceeded",

        filesLoaded: "Files loaded",

        condition: "Condition",

        value: "value",

        connectionAddress: "Connection Address",

        imageTypeColumn: "File Type column",

        visibleColumn: "Visible Column",

        selectionMode: "Selection Mode",

        selectMode: "Select Mode",

        datagridFiltering: "Datagrid Filtering",

        imageDataColumn: "File column",


        selectDataColumn: "Select Datagrid Column",

        dataFormat: "Data Format",


        photoPreview: "Photo Preview",

        videoPreview: "Video Preview",

        displayPhoto: "Display Photo",

        displayVideo: "Display Video",

        imageColumn: "Image Column",

        videoColumn: "Video Column",


        columnNameAppear: "Column name to appear",


        selected: "Selected",

        inStock: "In Stock",

        messageSave: "Are you sure you want to save your changes?",

        gallery: "Gallery",

        dollar: "dollar",

        sterling: "sterling",

        underPhoto: "Under the Photo",

        topPhoto: "Photo Top",


        byCategory: "By Category",

        dashboardShowHide: "Dashboard Show/Hide",

        showDetail: "View Detail",

        hideDetail: "Hide Detail",

        decrease: "Decrease",
        increase: "Increase",

        watch: "watch",

        watchMovie: "Watch Movie",

        noRecordsFound: "No Records Found!",

        appearance: "Apperance",

        numberofColumns: "Number of Columns",

        pagingRecords: "Paging Records",

        detailLocation: "Detail Location",

        hideImages: "Hide Images",

        quantityCategoryDisable: "Disable Category When Quantity Increases",

        general: "General",

        idColumn: "IdColumn",

        availableQuantity: "Available Quantity",

        categoryColumn: "Category Column",

        photoColumn: "Photo Column",

        titleColumn: "Title Column",

        priceColumn: "Price Column",

        updatablePrice: "Updatable Price",

        videoGallery: "Video Gallery",

        uniqueComponent: "Unique Component",

        showVariant: "Show Variant",

        transferTotalAmount: "Transfer Total Amount",


        title: "Title",

        hide: "Hide",

        detail: "Detail",

        priceQuery: "Price Query",

        quantityQuery: "Quantity Query",


        uniqueComponentError: "Unique Component Error",

        joinColumn: "Join Column",

        nameColumn: "Name Column",

        variantScript: "Variant Script",

        variantImages: "Variant Images",

        variantJoinColumn: "Variant Join Column",

        showAll: "Show All",

        showSelected: "Show Selected",

        showByCategory: "Show By Category",
        showInStock: "Show What's In Stock",

        notYetAdded: "Not yet added",

        added: "Added",

        startingDate: "Starting Date",

        endDate: "End Date",

        themeFeatures: "Theme Features",

        menu: "Menu",

        selectMenu: "Select Menu",

        selectSubmenu: "Select Submenu",

        selectMenuBrowser: "Select Menu Browser",

        nameElement: "Name Element",

        subject: "subject",

        picture: "picture",

        pictureElement: "Picture Element",

        startingDateElement: "Starting Date Element",

        endDateElement: "End Date Element",

        usersCanEdit: "Users Can Edit",

        thisPropertyCant: "This property CANNOT be used if data is being retrieved from SQL",

        calendarDisplay: "Calendar Display Properties",

        monthly: "Monthly",

        generalDisplayLunarDays: "General Display of Lunar Days",

        agenda: "Agenda",

        listofWeeklyEvents: "List of Weekly Events",

        weekly: "Weekly",

        weekend: "Weekend",

        generalDisplayWeekDays: "General Display of Weekdays",

        showWeekendDaysWhileWeekdays: "Show Weekend Days While Showing Weekdays",

        daily: "Daily",

        listofDailyEvents: "List of Daily Events",

        startTime: "Start Time",

        endTime: "End Time",

        changeDefaultColor: "Change Default Colors",

        weekdayColor: "Weekday Color",
        weekendColor: "Weekend Color",

        eventColor: "Event Color",

        groupBySql: "Group By Sql",

        groupColumnName: "Group Column Name",

        assignedColumnName: "Assigned Column Name",

        taskPriortiyColumnName: "Task Priority Column Name",

        taskSubjectColumnName: "Task Subject Column Name",

        taskDetailColumnName: "Task Detail Column Name",

        taskQuery: "Task Query",

        taskKeyExpression: "Task Key Expression",

        taskTitleExpression: "Task Title Expression",

        taskStartDateExpression: "Task Start Date Expression",

        taskEndDateName: "Task End Date Name",

        taskParentExpression: "Task Parent Expression",

        taskProgressExpression: "Task Progress Expression",

        taskColorExpression: "Task Color Expression",

        taskConnections: "Task Connections",

        diagramClear: "Are you sure you want to clear the diagram? This action cannot be undone.",

        uniqueComponentMenuCloneWarning: "Please change the unique component before copying.",

        filesLoading: "Loading files...",

        couldnotbeLoaded: "could not be loaded.",

        fileSizeLimit: "File Size limit",

        filesUploaded: "Files uploaded",

        filesWillDeleted: "The files will be deleted!",

        filesSureDelete: "Are you sure you want to delete the files?",

        fileDelete: "Do you want to delete the file?",

        uploadedPhotos: "",

        maxSize: "Max Size",

        center: "Medium",

        size: "Size",

        canItBeUpdated: "be updated after installation?",

        uploadedFiles: "Uploaded Files",

        type: "Type",

        showdeleteOldData: "Deletion Options for Old Data",

        videoBrowser: "Your browser does not support the video tag.",

        deleted: "Deleted",

        theseComponentElementsDeleted: "These component elements will be deleted.",

        invalidTarget: "Invalid target",

        ridge: "Straight Line",

        dotted: "Dot",

        dashed: "Striped",

        addRowColumn: "Add Row/Column",

        dataPagingShow: "Get Data as Pages",

        borderType: "Border Type",

        maxCharacters: "Maximum Number of Characters",

        edgeThickness: "Edge Thickness",

        lineSpacing: "Line Spacing",

        settingsRow: "Column Row/Column Settings",

        addRow: "Add Row",

        deleteRow: "Delete Row",

        rowPermission: "Row Permissions",

        addTabs: "Add Tabs",

        deletePanel: "Delete Panel",

        tabPermission: "Tab Permission",

        addPanel: "Add Panel",
        panelPermission: "Panel Permissions",

        backgroundColor: "Background Color",

        saveAndCloseChildForm: "Save And Close This Child Form While Opening A New Form",

        icon: "Symbol",

        selectForm: "Select Form",

        fontColor: "Font Color",

        minCharacters: "Minimum Number of Characters",

        generalCategory: "General Category",

        removeRow: "Are you sure you want to delete the record?",

        send: "send",

        declineFinish: "Decline and Finish",

        finish: "Finish",

        noData: "No Data",

        createEditMenu: "Create/Edit Menu",

        addNewBreakdown: "Add New Breakdown",

        addForm: "Add Form",

        addBottomBreak: "Add Bottom Break",

        addNode: "Add Node",

        topCategory: "Top Category",

        explanation: "Description",

        browserTitle: "Browser Title",


        formTitle: "Form Title",

        addSqlColumn: "Add Sql Column",

        formBg: "Form Background",

        codeEditor: "Code Editor",

        authorityType: "Authorization Type",

        viewOnly: "View Only",

        dark: "Dark",

        white: "White",

        hightBlack: "Black",

        run: "Run",
        fileLink:"File Link",
        givePasswordToTheLink: "Give Password To The Link",
        giveTheLinkAnExpirationDate: "Give the link an expiration date",

        rowColor: "Row Color",

        operator: "Operator",

        flowOfForm: "Form Flow",
        columnWidth: "Column Width",

        alignLeft: "Left Justify",

        alignRight: "Right Justify",

        alignButton: "Align Button",

        imageTableNameColumn: "Table Name",

        uploadBg: "Upload Background",

        selectFile: "Select File",

        dropFile: "Drop File here",

        alwaysRoundUp: "Always Round Up",

        alwaysRoundBot: "Always Roll Down",

        default: "Default",

        deleteOldDataTable: "Delete the old data of the table?",

        deleteOldData: " Delete old data?",

        integer: " Integer",

        double: "Decimal Number",

        pleaseFillFields: "Please fill in the required fields.",

        toastColumnNameNumber: "Column name cannot start with number",

        areYouSure: "Are you sure?",

        columnDeleted: "The column will be deleted",

        allColumnDeleted: "All Columns Will Be Deleted",

        idCantStartNumber: "Id cannot start with number",

        uploadFile: "Upload File",

        fileViewer: "FileViewer",

        ok: "Okay",

        map: "Map",

        editPanel: "Edit Panel",

        filterColumnName: "Filter Column Name",

        hintExamples: "TotalAmount, example:Maas",


        columnLanguageSettings: "Column Language Settings",


        fileColumn: "File Column",


        fileNameColumn: "File Name Column",

        extensionColumn: "Extension Column",

        multipleSelection: "Multiple Selection",

        addSelectionsRows: "Add selections as rows",

        mapSettings: "Map Settings",


        formatAndFeatures: "Format and Features",

        enterDateRange: "Enter the date range value",

        filesCanUploadedUsers: "Users can upload files",

        barcodeColumn: "Barcode Column",

        autoAscend: "Auto Ascend",

        roundType: "Rounding Type",
        font: "Font",

        columnProcess: "Column Operation",

        exportColumnTotal: "Export column total",

        outsideElementDisabled: "Disable External Elements",

        chat: "Chat",

        languages: "Languages",

        display: "Image",

        enterYourComment: "Enter your comment",

        comment: "Comments",

        fileUploaded: "pcs uploaded",

        pdfViewIsButton: "Show as Button",

        takeScreenshot: "Take Screenshot",

        alertText: "Alarm Text",

        clearButtonVisible: "Show Clear Button",

        allText: "All",

        letter: "Letter Only",

        letterandnumber: "Letter and Number",

        letterandsymbol: "Letter and Symbol",

        numberandsymbol: "Number and Symbol",
        showIDColumn: "Show Id Column",

        showCopyButton: "Show Copy Button",

        showDeleteButton: "Show Delete Button",

        showCheckboxButton: "Show Checkbox Button",

        autoRowHeight: "Auto Row Height",

        exportImport: "Form Import / Export",

        export: "Export",

        import: "Import",

        toastImport: "Form Import Completed.",

        warningApproveImport: "Confirm the operation after making the necessary adjustments. Importing the form will close the currently open form",

        defaultTodaysDateWithTime: "The default is today's date and time",

        filterVisible: "Show Column Filtering",


        updatetableMaxQuantity: "Max Updateable Quantity",

        hideQuantity: "Hide quantity selection",

        disableQuantityTextbox: "Disable quantity typing",

        multiplesOfQuantity: "Increase/decrease multiples of quantity",

        justnumber: "Number only",

        paymentHourWarning: "You cannot pay outside of checkout hours.",

        addColumn: "Add Column",

        mailSettings: "Mail Options",

        newTaskMailDetail: "Mail Text To Be Sent When A New Task Is Assigned",

        updateTaskMailDetail: "Mail Text To Be Sent When Task Is Edited",
        deleteTaskMailDetail: "Mail Text To Be Sent When Task Is Deleted",
        completeTaskMailDetail: "Mail Text To Be Sent When Task Is Completed",

        taskOnDropMail: "Mail Text to be Sent when Task is Relocated",

        selectSMTP: "Select SMTP Address",

        taskCreator: "Task Assignee",

        taskEndDate: "Task Expiry Date",

        tags: " Tags",

        taskStartDate: "Task Start Date",

        taskAssigned: "Task Assigned Person",

        taskDetail: "Task Detail",

        taskSubject: "Task Title",

        taskPri: "Task Priority",

        taskEdit: "Task Update",

        addTask: "Add Task",

        taskStatus: "Task Status",

        excelExportSettings: "Excel Export Settings",

        excelExportRequiredColor: "Required Field Column Color",

        allDataExcelExport: "Export Data as Excel",

        warningApproveImportReport: "Confirm the operation after making the necessary edits. Importing the report will close the currently open report.",

        reportName: "Report Name",
        emptyReportName: "Please Add Report Name From Right Side.",

        old: "old",


        upperNode: "Upper Refraction",

        pdfViewStaticPdf: "Show Fixed File",

        actions: "Actions",

        loading: "Loading",
        exportDataToExcell: "Export all data to Excel",

        showRefreshIcon: "Refresh Button",

        headerFilter: "Header Filter",

        filterRowVisible: "Search Box Appears in Column",

        password: "Password",

        categories: "Categories",

        pressEnter: "Press Enter",

        historyColumnValue: "Data History Column",

        hideMobile: "Hide on Mobile",

        align: "Alignment",

        theme: "Theme",

        info: "Info",

        showTitle: "Show Title",

        purple: "Purple",

        brown: "Brown",

        yellow: "Yellow",

        turnOnComments: "Open comments",

        showContent: "Show Content",

        canBeHidden: "Can be hidden",

        allowEdit: "Allow Editing",

        allowDelete: "Allow Delete",

        allowAdd: "Allow Add",

        allowCopy: "Allow Copy",

        toastDeleteErrorMessage: "This component is used in Form Preference scripts. It cannot be deleted.",

        formOpeningMultiElementEvent: "Form Opening Multi-Element Event",

        resetDatagridLocalSettings: "Reset Datagrid Locale",
        loginP1: "River provides a holistic memory system as your corporate memory that contains information such as past experiences, successes, failures, lessons learned, culture and values ​​of your organization or institution that can be used as a reference source for future decisions and actions. processes, the knowledge of its employees, customer experiences, the development of products and services, and many other information. This information will guide the company's future goals and strategies.",
        loginP2: "River helps your business to run business processes more efficiently and effectively with its web and advanced native mobile application supported structure. Everything you need to access is as close to you as your computer, tablet or smartphone.",
        loginConnect: "Connect",
        loginText: "Login",
        loginRightMore: "More",
        loginHeaderP1: "Your Corporate Memory",
        loginHeaderP2: "Mobility",
        loginHeaderP3: "Graphical Power",
        kanbanaddTasks: "Add Kanban Task",
        tasksstatusDesciption: "It displays the name of the column to which the Kanban task will be assigned.",
        taskssubjectDescription: "Allows you to enter the title of the Kanban task. The Kanban title is a required field.",
        taskassignedToTask: "Groups Added to Task",
        taskassignedTaskDescription: "Allows the selection of groups to be assigned to the kanban task.",
        addedForTasks: "Added For Task",
        taskAssignedInfoDescription: "People to Notify for Task",
        assignedInfo: "People to be notified",
        groupInfo: "Groups to Notify for Task",
        sortEnd: "Sort by End Date",
        sender: "From",
        forward: "Forward",
        attachments: "Attachments",
        loginP3: "Kanban and gantt charts are extremely useful tools for visualizing a project or workflow. They provide a visual representation of work items, their current status, and the workflow at different stages of the business. This makes it easy to identify bottlenecks, prioritize jobs, and improve operational efficiency. Kanban and gantt charts are practical and effective for managing agile projects, software development, and other continuous improvement processes.",
        taskdetailDescription: "It allows entering the details of the Kanban task. Kanban detail is a required field.",
        taskassignedDescription: "Allows users to be assigned to the Kanban task. At least one user must be assigned to Kanban.",
        taskstartdateDescription: "Allows the start date of the Kanban task to be selected. In Kanban, the start date automatically takes the creation time of the task.",
        taskenddateDescription: "Allows to select the end date of the Kanban task. In Kanban, the task end date is automatically one week after the task creation time.",
        taskpriDescription: "Allows to select the priority status of the Kanban task. In Kanban, the task due date automatically takes one week after the creation time of the task.",
        tagsDescription: "It allows adding tags of Kanban task. Tags are separated from each other as they are added with commas.",
        outlookDescription: "When adding the task to Outlook option is turned on, a task is automatically added to the calendar by logging into your outlook account.",
        taskAssInfoDescription: "This is the field used for people who are not assigned to the task but whose information is requested. People added to inform cannot act on tasks.",
        receiver: "Receiver",
        draft: "Draft",
        spam: "Unnecessary Email",
        filterstate: "Filter by Status",
        checkForspelling: "Check for Spelling Errors",
        emailToForward: "Email To Forward",
        lowImpMails: "Low Important Mails",
        normalImpMails: "Normal Important Mails",
        highImpEmails: "High Important Mails",
        showReadMails: "Show Read Mails",
        groupinfoDescription: "This is the field used for groups that are not assigned to a task, but whose information is requested. People belonging to groups added to inform them cannot operate on tasks.",
        timeoutMission: "Time to Begin Alert Before Mission Ends",
        timeoutmissionDescription: "Used to specify the time to start the alert when the Kanban task is approaching its end time",
        taskperiodDescription: "Used to specify the periodic reminder time when the end date of a kanban task is approaching.",
        taskProgress: "Task Progress",
        taskProgressDescription: "It is used to indicate the progress of a Kanban task. Progress can be viewed on the Gantt chart.",
        updatekanbanTask: "Kanban Task Update",
        taskcreatorDescription: "Kanban allows the task creator to be displayed.",
        uploadfileDescription: "Used to upload files to Kanban task. Files or images are recorded on DMS.",
        infoPersons: "Persons to Notify",
        warningtime: "Warning Time",
        taskPeriod: "Task Period",
        taskProgresSatus: "Task Progress",
        makeTask: "Task Creator",
        addedforTasks: "Added For Task",
        outlookIntegration: "Outlook Integration",
        loginoutlook: "Login Outlook Calendar",
        fetchoutlook: "Fetch Data from Outlook Calendar",
        progressStatus: "Progress Status",
        taskColors: "Task Colors",
        addcalendarTask: "Adding Tasks to Calendar",
        updatetaskCalendar: "Calendar Task Update",
        statusComplete: "Filter Completion Status",
        priorityFilter: "Priority Filtering",
        columnFilter: "Column Filtering",
        assignPerson: "Assigned Persons",
        assignGroup: "Assigned Groups",
        noTask: "No Tasks Found",
        resetSort: "Reset Sort",
        sortASC: "Sort A - Z",
        sortDESC: "Sort Z - A",
        sortAppointment: "Sort by Assignment Date",
        trash: "Trash",
        showOlderMail: "Show Older Mail",
        mailNotFound: "Mail Not Found",
        sendNewMail: "Send New Mail",
        showUnreadMails: "Show Unread Mail",
        updateColumnMail: "Message to Contact When Added Task to Column",
        addTaskToOutlook: "Add task to Outlook",
        enterYourMessage: "Enter Your Message",
        reminder: "Reminder",
        determinedToTask: "Time specified for the task ",
        remainingTime: "Remaining time",
        hours: "Hour",
        withoutPriority: "No priority",
        lowPri: "Low Priority",
        normalPri: "Medium Priority",
        highPri: "High Priority",
        showAllTasks: "Show All",
        showIncompleteTasks: "Show Incomplete",
        showCompeletedTasks: "Show Completed",
        reportDesigner: "Report Designer",
        fileManagementSystem: "File Management System",
        formDesigner: "Form Designer",
        boardDesigner: " Board Designer",
        themeSettings: "Theme Properties",
        social: "Social",
        profile: " Profile",
        contacts: " Contacts",
        files: " Files",
        pages: "Pages",
        noResultsFound: "No Results Found",
        processes: "Processes",
        filterByStatus: "Filter by Status",
        filterByPri: "Filter by Priority",
        filterByCol: "Filter by Column",
        todoAddTasks: "Add To-Do Tasks",
        addLabel: "Enter Label",
        showWithSubTasks: "Show Subtasks",
        riverBoard: "River Boards",
        notify: "Notifications",
        likedYourPost: "Liked Your Post ",
        likedYourComment: "Liked Your Comment ",
        systemNotify: "System Notifications",
        readAllNotify: "Read All Notifications",
        recommendedUsers: "Recommended People",
        suggestedPosts: "Suggested Posts",
        sPostReposted: "Repost Posted",
        shareSomething: "Share Something",
        updatePost: "Update Post",
        ifYouDoNotSelectGroup: "If you don't select a group, anyone can see your post",
        addComment: "Add Comment",
        sendComment: "Send Comment",
        areYouSureYouWantToDeleteThisPost: "Are you sure you want to delete the post",
        yes: "Yes",
        no: "No",
        deletePost: "Delete Post",
        postSuccesfullyDeleted: "Post successfully deleted",
        deleteComment: "Delete Comment",
        areYouSureYouWantToDeleteThisComment: "Are you sure you want to delete this comment",
        commentSuccesfullyDeleted: "Comment Successfully Deleted",
        sharePost: "Share Post",
        areYouSureYouWantToShareThisPost: "Are you sure you want to share this post",
        repostedSuccessfully: "Reposted Successfully",
        upload: "Upload",
        refresh: "Refresh",
        allowedExtentions: "Allowed extensions: JPG, GIF or PNG. Maximum file size",
        systemLandingPagePreference: "Landing page Preference",
        allownonadminuserstosearchforotherusers: "Allow non-admin users to search for other users",
        passwordChange: "Password Change",
        newPassword: "New Password",
        retypethenewpassword: "Retype new password",
        notifications: "Notifications",
        showWhoLikesYourPost: "Show who likes your posts",
        showWhoLikesYourComment: "Show who likes your comments",
        aboutMe: "About Me",
        bio: "Biography",
        dateOfBirth: "Date of Birth",
        website: "Website",
        phone: "phone",
        saveChanges: "Save Changes",
        username: "Username",
        content: "Content",
        projectManagement: "Project Management",
        addPage: "Add Page",
        addFolder: "Add Folder",
        showUsers: "Users Who Can See",
        showGroups: "Groups That Can See",
        readonlyUsers: "Readonly (Users)",
        readonlyGroups: "Readonly (Groups)",
        columnHeight: "Column Height",
        showKanbanCount: "Show Kanban Quantity Information",
        kanbanSettings: "Kanban Settings",
        gantSettings: "Gant Settings",
        schedulerSettings: "Calendar Settings",
        showRowLines: "Show Line Lines ",
        activateOutlookConnection: "Activate Outlook Connection",
        selectOutlookColumn: "Select the Outlook column",
        selectSMTPopoverText: "Mail SMTP to be used in Kanban, Gantt, Calendar or To-Do components is selected. ",
        menuFilePathId: "River Menu folder id",
        recycleBinPathName: "River Trash Folder Name",
        recycleBinPathId: "River Trash Folder Id",
        fileNameChangedMessage: "File name changed successfully.",
        fileNameChangedErrorMessage: "The filename could not be changed.",
        fileCreatedMessage: "The file was created successfully.",
        newTaskMailDetailPopoverText: "When a new task is assigned to Kanban, Gantt, Calendar or To-Do components, the part of the mail content that will be added to the automatic mail that will be sent to the assigned users. ",
        taskOnDropMailPopoverText: "The part of the mail content that will be added to the automatic mail that will be sent to the Kanban, Gantt, Calendar or To-Do components when the column containing a task is changed. ",
        createdBy: "Creator",
        updatedBy: "Updated",
        createdDate: "Created Date",
        updatedDate: "Updated Date",
        folderPermissionSettings: "File authorization settings",
        quantity: "Piece",
        fileManagementPermissionSettings: "File Management Permission Settings",
        fileNotFoundErrorText: "No files found matching your search criteria.",
        selectOutlookColumnPopoverText: "The column to include the tasks to be added via Outlook is selected.",
        createFolder: "Create File",
        versionHistory: "Version History",
        deletePermanently: "Delete Permanently",
        recover: "Recover",
        groupPermissions: "Group Permissions",
        allowUpload: "Allow File Upload",
        allowView: "Allow file view",
        allowDownload: "Allow file download",
        permissionType: "Permission Type",
        applyToSubFolders: "Apply to all subfolders",
        newFolderName: "New File Name",
        tagTips: "Tags must be prefixed with a # sign. It can contain only numbers and characters",
        addAsNewVersion: "Install as a new version",
        addAndChangeName: "Upload with rename",
        skip: "Skip",
        fileExistError: "File with same name exists in destination folder",
        processFileShow: "Show River Process Folder",
        processFilePathName: "River Process Folder name",
        processFilePathId: "River Process folder id",
        menuFileShow: "Show River Menu Folder",
        menuFilePathName: "River Menu Folder name",
        fileCreatedErrorMessage: "File creation failed.",
        fileDeletedessage: "File deletion completed.",
        fileDeletedErrorMessage: "Failed to delete file.",
        fileMovedMessage: "File move completed.",
        fileMovedErrorMessage: "The file move failed.",
        fileCopyMessage: "File copying completed.",
        fileCopyErrorMessage: "The file copy operation failed.",
        fileDownloadMessage: "File download completed.",
        fileDownloadErrorMessage: "File download failed.",
        fileSizeShowErrorMessage: "Cannot view because the file size is large. Please download the file",
        unsupportedFile: "Unsupported file",
        fileNameError: "There is a file with this filename, please try again with a different name",
        fileGivePermissionMessage: "File authorization completed successfully.",
        fileGivePermissionErrorMessage: "Error in file authorization. Registration failed.",
        groupName: "Group Name",
        createGroup: "Create Group",
        conversation: "Conversations",
        startConversation: "Start Conversation",
        userNotFound: "Contact not found",
        chatNotFound: "Chat not found",
        themeTypeLight: "Light",
        themeTypeBorderedLight: "Framed",
        themeHalfDark: "Half Dark",
        themeDark: "Dark",
        themeRouteAnimation: "Transition Animation",
        themeRouteAnimationFadeInLeft: "Left Fade",
        themeRouteAnimationZoomIn: "Zoom",
        themeRouteAnimationFadeIn: "Fade",
        themeRouteAnimationNone: "None",
        themeMenuType: "Menu Type",
        themeMenuTypeHorizontal: "Horizontal",
        themeMenuTypeVertical: "Vertical",
        themeMenuCollapse: "Narrow Menu",
        themeNavbarColor: "Navigation bar color",
        themeNavbarType: "Navigation bar type",
        themeSticky: "Sticky",
        themeStatic: "Static",
        themeFloating: "Floating",
        themeHidden: "Hidden",
        themeFooterType: "Footer type",
        catalog: "Catalog",
        riverBoards: "River Boards",
        clipboardDesigner: "Clipboard Designer",
        designMode: "You are in Design Mode",
        editPage: "Edit Page",
        rename: "Rename",
        exportToPdf: "Output as PDF",
        exportToExcel: "Output as Excel",
        revenueReport: "Revenue Report",
        header: "Title",
        connection: "Connection",
        primaryKey: "Primary key",
        secondaryKey: "Secondary Key",
        completedKey: "Completed Key",
        countKey: "Count Key",
        statusKey: "Status Key",
        progressConnection: "Progress Connection",
        progressScript: "Progress Script",
        progressPrimaryKey: "Progress Primary Key",
        progressCountKey: "Progress Count Key",
        progressPercentageKey: "Percent Progress Key",
        progressColorKey: "Progress Color Key",
        keyColumn: "Value Column",
        currency: "Currency",
        whitePage: "White Page",
        noReportSelected: "No Report Selected",
        noTypeSelected: "No Report Type Selected",
        whitePageReports: "White Page Report",
        addBranch: "Add Break/Subbreak",
        branchName: " Breakdown Name",
        rowNumber: "Sequence Number",
        reportTypeSelect: "Select Report Type",
        reportDescription: "Report description...",
        useWeb: "Use on the Web",
        useMobile: "Use on Mobile",
        mainScript: "Main Script",
        prerunScript: "Run pre-script?",
        preScript: "PreScript",
        testIt: "Test",
        selectAll: "Select All",
        addReport: "Add Report",
        selectReportType: "Select Report Type",
        reportType: "Report Type",
        reportDesingPermission: "User does not have report design permissions.",
        reportImportedOpenLeftMenu: "Report Imported. You Can Open From Left Menu.",
        forReportExport: "For Report Export, you must first select a report.",
        anErrorOccurred: "Error occurred",
        reportDeleted: "Report Deleted",
        branchDeleted: "Deleted Breakdown",
        addBranchName: "Please enter the breakdown name",
        addedBranch: "Branch Added",
        selectConnectionAddress: "Please select a connection address",
        enterSqlScript: "Please enter sql script",
        enterReportName: "Please enter report name",
        reportAdded: "Report Added",
        numberOfDataToBeProcessed: "Connection successful. Number of data to process:",
        dataNotFoundCheckYourConnection: "Data not found! Please check your connection and sql command",
        connectionNotFoundCheckYourConnection: "Connection failed! Please check your connection and sql command",
        fillConnectionAndSql: "Please fill in your connection and sql command",
        allTypesActivate: "All Types Activate at Display Time",
        passwordRequired: "Password is required",
        showLess: "Show Less",
        showMore: "Show More",
        authority: "Authority",
        preScriptDescription: "Prescript description",
        enterReportFilters: "Enter Report Filters",
        gridDesigner: "Grid Designer",
        addMainBranch: "Add Main Breakdown",
        resetSettings: "Reset Settings",
        columsToGroup: "Use context menu of header columns to group data",
        customize: "Customize",
        colorType: "Color Type",
        formatManagement: "Format Management",
        bottomTotalColumn: "Bottom Total Column",
        formatType: "Format Type",
        datagridSettingsReset: "Data grid settings will be reset",
        unexpectedError: "Sorry, we encountered an unexpected error.", 
        changesSaved: "Changes are saved",
        addGantTask: "Add Gant Task",
        updGantTask: "Update Gant Task",
        updSchedulerTask: "Update Calendar Task",
        updTodoTask: "Update To-Do Task",
        completeTask: "Complete Task",
        cantBeEmptyKanban: "Task title, task detail and task assignee cannot be empty",
        successAdd: "The task has been added successfully",
        successUpd: "The task was updated successfully",
        errorReadonlyUpd: "You cannot update the task because you do not have authorization.", 
        errorCompletedUpd: "You cannot update the completed task.",
        statusChangeTodo: "The Task Completion Status Changed Successfully.",
        reminderSended: "Reminder email sent for task successfully",
        askForCompleteTask: "Are you sure you want to complete the task?",
        infoForCompletedTask: "The subtasks of your task will also be completed.",
        noPermisison: "You are not authorized.",
        successDependencyAdded: "Task Dependency Added Successfully",
        filterimportant: "Filter by Importance",
        enterEmail: "Enter e-mail address",
        reply: "Reply",
        newCol: "New Column",
        thickness: "Thickness",
        distanceToUpperComponent: "Distance to upper component",
        companyName: "Company Name",
        showEmailModule: "Show email module",
        distanceToBottomComponent: "Distance from bottom component",
        canNonAdminUsersPost: "Can non-admin users post",
        columnColor: "Column Color",
        byWho: "by",
        updated: "Updated",
        commentAdded: "Comment Added",
        completed: "Done",
        addRemoveColumns: "Add/Remove Columns",
        searchMessage: "Search messages",
        riverReports: "River BI Reports",
        forms: "Forms",
        searchinProcess: "Search in processes",
        searchVoiceMessage: "The message you are looking for is in this audio file",
        searchVoiceMessages: "Searching voicemails",
        showSocialNotifications: "Show Social Notifications",
        showProjectManagementNotifications: "Show Project Management Notifications",
        showProcessNotifications: "Show Process Notifications",
        pageDesingPermission: "User does not have page design permissions.",
        filemanagementDesingPermission: "User does not have file management permissions.",
        taskDelete: "The task will be deleted.",
        tasksWillDeleted: "Are you sure you want to delete the task?",
        componentSettings: "Component Settings",
        aboutComponent: "About Component",
        sureSaveChanges: "Are you sure you want to save your changes?",
        showDeletedNotifications: "Show Notifications I Deleted",
        shareSometthing: "Share Something",
        resetPageLayout: "Reset Page Layout",
        downloadingSS: "Downloading screenshot. Please wait.",
        showHorizontalLines: "Show Horizontal Lines",
        turnOffVerticalHide: "Turn off Vertical Hide",
        whichVerticalLinesAreToBeHidden: "Which vertical lines are to be hidden",
        priority: "Priority",
        desingMode: "You are in Design Mode",
        boardSidebarMessageField: "Task Message Field",
        boardSidebarExtraField: "Task Extra Component Field",
        boardSidebarTaskHistory: "Task History",
        loadMoreNotifications: "Show More Notifications",
        deletedNotifications: "Deleted Notifications",
        noFormNotification: "No Process Notifications Yet",
        noDeletedFormNotifications: "No Deleted Process Notifications",
        noDeletedBoardNotification: "No Deleted Board Notifications",
        noBoardNotification: "No Board Notifications Yet",
        noDeletedSocialNotification: "No Deleted Social Media Notifications",
        noSocialNotification: "No Social Media Notifications Yet",
        deleteAll: "Delete All",
        markAllRead: "Mark All as Read",
        board: "Board",
        form: "Form",
        formNotifications: "Form Notifications",
        boardNotifications: "Board Notifications",
        socialNotifications: "Social Notifications",
        email: "E-mail",
        oldFormName: "Old Form Name",
        newFormName: "New Form Name",
        oldFormVariable: "Old Form Variable",
        newFormVariable: "New Form Variable",
        oldCategory: "Old Category",
        newCategory: "New Category",
        connectionInFile: "Connection in File",
        yourConnection: "Your Connection",
        element: "Element",
        script: "Script",
        formClosingEvent: "Form Closing Event",
        message: "Message",
        formExportTextError: "There are no components added to the form or the form is not saved. Please fix this issue to prevent the operation from failing.",
        checkRequiedFields: "Check Required Fields",
        dontCheckRequiredFields: "Don't Check Required Fields",
        createPdfWarning: "If checked, the form will not be saved and closed",
        cratePdf: "Create PDF",
        identityNumber: "Identity Number",
        minPaymentHour: "Min Hour",
        maxPaymentHour: "Max Hour",
        darkBlue: "Dark Blue",
        orange: "Orange",
        component: "Component",
        properties: "Properties",
        dmsPath: "DMS Path",
        dashboard: "Dashboard",
        slider: "Slider",
        list: "List",
        otherImages: "Other Images",
        unitScript: "Unit Script",
        updatableMaxQuantity: "Updatable Max Quantity",
        newPage: "New Page",
        code: "Code",
        resetHiddenComponent: "Reset Value of Hidden Target Components",
        bold: "Bold",
        italic: "Italic",
        stringFormat: "String Format",
        videoUploaded: "Video Uploaded",
        fillInAllFields: "Fill in All Fields",
        normalPaging: "Normal Paging",
        pageSize: "Page Size",
        special: "Special",
        link: "Link",
        fontWeight: "Font Weight",
        pdfFileNotFound: "PDF File Not Found",
        themeMenuHidden: "Hidden Menu",
        likedOn: "Liked On",   
        subCategory: "Subcategory",
        toTheBoard: "to the board",
        changeTasksPage: "Görevin Panosunu Değiştir",
        hideTaskDetail: "Hide Task Details",
        selectData: "Select Data",
        noContentYet: "No Content Yet",
        addRowBetween: "Add Row Between",
        userPermissions: "User Permissions",
        riverModules: "RIVER Modules",
        processHistory: "Process History",
        askForPassword: "Ask for password ?",
        passwordIsWrong: "Password is incorrect, please try again.",
        pleaseEnterPassword: "Please enter your password to continue.",
        onePaymentEachSave: "One Payment for Each Save",
        clearSelection: "Clear Selection",
        allowDownloadVersionHistoryDocuments: "Allow Download of Version Histories Documents",
        addNewColor: "Add New Column",

        delegated: "Delegated",
        openDocxToPdf: "Open File as PDF",
        activationStartDate: "Activation Start Date",
        activationEndDate: "Activation End Date",
        changeActivationDate: "Change Activation Date",
        showArchive: "Show Archive",
        hideArchive: "Hide Archive",
        tasks: "Tasks",
        maxFileCountLimit: "Maximum File Count",
        fileCountLimit: "File Count Limit",
        numberOfFilesExceeded: "Number of Files Exceeded",
        numberOfFilesLimit: "Number of Files Limit",
        lineIntermediateAllowAdd: "Add Intermediate Line",
        lineIntermediateAllowCopy: "Copy Intermediate Line",
        triggerReport: "Trigger Report",
        setWhitePageReportExportName: "Set White Page Report Export Name",
        menuRowUpdated: "There are records with auto-incremented value. Auto-increment number has been updated: ",
        isMenuRowControl: "Auto-Increment Control",
        sqlScriptWarning: "Using this field may cause performance losses.",        

        deletedTask: "Deleted Task",
        getDeletedTasks: "Get Deleted Tasks",
        getTasks: "Get Tasks",
        
        movePageDesignersNode: "Selected Board/Menu Will Be Moved to Folder",
        informationAboutMoveToDirectory: "The user permissions of the moved Board/Menu will change according to the permissions of the folder it is moved into.",
        move: "Move",
        download: "Download",
        create: "Create",
        statistic: "Statistics",
        priRate: "Assignment Rate by Priority",
        taskcreatorlist: "List of Task Creators",
        completionRate: "Completion Rate",
        columnsTaskCount: "Number of Tasks in Columns",
        showKanbanFilter: "Show Kanban Filters",
        showFileHistory: "Show File History",
        processToStart: "Process to Start",
        fieldMatching: "Field Matching",
        dontLetDeleteWhatTheyAdded: "They can delete what they added",
        myJobs: "My Jobs",
        excelExportName:"Excel Export Name",
        filterSearch: "Search in Tasks",
        filterForAssignUsers: "Filter by Assignee",
        filterForAssignUGroups: "Filter by Group",
        filterForPri: "Filter by Priority",
        clearFilter: "Clear Filters",
         makeRequiredField: "Make Field Required",
        makeReadOnly: "Make Read-Only",
        letItWorkProcessContinues: "Let it work when the process continues",
        event: "Event",
        selectEvent: "Select Event",
        rowEditor: "Row Editor",
        formOpeningComponentEvent: "Form Opening Component Event",
        addedTask: "added",
        updatedTask: "updated",
        messageAddedToTask: "Message added",
        deleteTask: "deleted",
        completedTask: "completed",
        boardToastNotification: "{{0}}  {{3}} task named {{2}}   to  board  {{1}}",
        socialToastNotification: "{{0}}  liked your post  with {{1}} content on  {{2}}",
        processToastNotification: "{{0}} commented on the form  {{1}}",
        yesterday: "Yesterday",
        today: "Today",
        notSaved: "Not Saved",
        editors: "Editors",
        basic: "Basic",
        file: "File",
        datagridPreferences: "Datagrid Preferences",
        deleteLetsAsk: "'Delete?' let's ask.",
        addNewRow: "Without asking, add as new row",
        oldRowShouldBeDeleted: "Without asking, delete old rows",
        canBeSearched: "Can be Searched?",
        processName: "Process Name",
        removeReadOnly: "Remove Read-Only Property",
        dontMakeRequired: "Don't Make Required Field",
        generalSettings: "General Settings",
        pageDesignerFirstPage: "First Page",
        beginDayHour: "Beginning of the Day",
        endDayHour: "Ending of the Day",
        dayTimeDuration: "Time Duration",
        colorSettings: "Color Settings",
        colorSettingsDescription: "Data background color.",
        dataName: "Data Name",
        margin: "Margin",
        rowCount: "Row Count",
        colCount: "Column Count",
        outsideMargin: "Outside Margin",
        widgetName: "Widget Name",
        searchInProcess: "Process forms",

        changeEventScriptRun: "Should change scripts be triggered when data is filled?",
        dontChanceId: "(This object ID cannot be changed because it is used in Component Form Activities.)",
        scriptButtonWarning: "Script button status settings are not supported in menu forms.",
        infiniteScroll: "Infinite Scroll",
        scrollSettings: "Scroll Settings",
        pageNameSetting: "Sayfa Adı Ayarları",
        showPageName: "Sayfa Adını Göster",
        bindProcess: "Bind the Process to This Page",
        bindProcessDetail: "Once the process binds to the page, the page will exist in a read-only form. Changes to the page can only be edited through the process. The process form opens while the page data is being edited.", 
        description2: "Description 2",
        showJobListTabs: "Show Joblist Tabs",
        showUserPhotosInJobList: "Show User Photos In Joblist",

        dmsVideoGaleryTabWarning: "You do not have permission to use this field.",
        searchInFormJson: "Search In Form json",
        useExpandedJoblist: "Use Expanded Job List",
        editTour: "Edit Tour",
        selectComponent: "Select Component",
        tourComponents: "Tour Components",
        addTourElement: "Add Tour Element",
        startTourWhenFormOpen: "Start Tour When Form Open",
        compare: "Compare",
        deleteTourElement: "Delete Tour Element",
        tourWillDeleted: "Tour will be deleted",
        tourDelete: "Delete Tour",
        fixedRowHeight: "Fixed Row Height",
        pushWidgetItems: "Push Widget Items",
        allowMultiLayer: "Allow Multi-Layer",
        gridType: "Grid Type",

        useCollapsedJoblist: "Use Collapsed Job List",
        searchAllData: "Search All Data",
        scriptsRunAllAreSelected: "Should scripts run when all are selected?",
        
        menuSchedulerPermission: "You do not have permission to add menu card",
        showOnTask: "Should the editor show in the processes",
        alreadyHaveMenu: "If you want to use a menu component, there must be only a menu component in the form and more than one menu component cannot be added.",
        select: "Select",
        startedUserName: "Started User Name",
        startedUserId: "Started User ID",
        processInstanceId: "Process Instance ID",
        formId: "Form ID",
        min: "Minimum",
        labelNotFound: "Label is not defined in this language",
        difference: "Changes Between Two Files",
        cleanElement: "Clear element field when printing data?",
        cleanColumn: "Clear column space when printing data?",
        fileSkippedSuccessfully: "Cancelled.",
        dmsTags: "Tag",
        searchInFormContents: "Search In Form Contents",
        jobType: "Job Type",
        workStatus: "Work Status",
        or: "Or",
        signInWithMicrosoft: "Sign in with Microsoft Account",
        receipt: "Receipt",
        receiptOptions: "Receipt options",
        tax: "Tax",
        selectboxWarningMessage: "If the data exceeds 100, please use the lookup component. The first 100 values are shown.",
        sendMailManager: "Send e-mail to managers of selected users",
        degree: "Degree",
        completeDateShort: "Comp.Dt:",
        confirmationPopupClose: "If you do not take any action, the confirmation popup will close automatically.",
        confirmationSecondsSetting: "Confirmation popup seconds setting",
        createPdfAndSendForm: "Should the form be sent when the PDF is created?",
        confirmCreatePdfAndSendForm: "A PDF will be created and the form will be sent. Do you confirm?",
        _filter: "Filter",
        removeFromFavorites: "Remove from favorites",
        addToFavorites: "Add to favorites",
        id: "ID",
        assignedUserName: "Assigned User Name",
        schemeId: " Scheme Id",
        formStatusByUser: "Form Status By User",
        formSituationByUser: "Form Situation By User",
        isDelegated: "Is Delegated",
        formname: "Form Name",
        formstatus: "Form Status",
        timeOutDate: "Timeout Date",
        saveFilterPack: "Save Filter Pack",
        enterRegistrationName: "Enter Registration Name",
        filterPackages: "Filter Packages",
        onlyNumbersError: "You must enter a value containing only numbers.",
        speechLengthError: "The speech is longer than the required length.",
        removeImage: "Remove",
        headerBundle: "Header Bundle",
        other: "Other",
        verticalHeader: "Vertical Header",
        exportDataAuth: "Export authorizations",
        errorFileType: "The file type is incorrect. Accepted file type: ",
        folder: "Folder",
        page:"Page",
        uploadPdfToDms: "Uploading Generated PDF to DMS",
        uploadPdfToDmsName: "Enter the name of the PDF to be saved to DMS.",
        pdfName: "PDF Name",
        addTour: "Add Tour",
        updateTour: "Update Tour",
        editTourElement: "Edit Tour Element",
        buttonAction: "Button Action",
        referenceArea: "Reference Area",
        fieldToFill: "Field to Fill",
        buttonColor: "Button Color",
        signFile: "Sign the File",
        addNewCategory: "Add New Category",
        autoRefreshJoblist: "Job List Automatically Refresh",
        changeScriptRow: "Change script order",
        defaultRowCheckRequired: "Should required field checks be performed when a default row exists?",
        currentCode: "Current Code",
        screenshotOnMobile: "Taking a screenshot on mobile",
        created: "Created",
        keepPermission: "Transfer permissions when transferring the form. (Not recommended if your users or groups do not match)",
    }

}