import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';
import { AuthService } from '../../../../../@shared/services/api';
import { AppInfoService } from '@shared/services/common/app-info.service';

@Component({
    selector: 'horizontal-layout',
    templateUrl: './horizontal-layout.component.html',
    styleUrls: ['./horizontal-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HorizontalLayoutComponent implements OnInit, OnDestroy {
    coreConfig: any;
    public isLoggedIn = false;

    // Private
    private _unsubscribeAll: Subject<void>;

    /**
     * Constructor
     *
     * @param {CoreConfigService} _coreConfigService
     */
    constructor(private _coreConfigService: CoreConfigService, private _authService: AuthService, private _appInfoService: AppInfoService) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();

    }

    // Lifecycle Hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    canChat: any = false;
    ngOnInit(): void {
         // Subscribe to login state
        this._authService._isLoggedIn.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
            this.isLoggedIn = data;
            if (this.isLoggedIn) {
                this.canChat = this._appInfoService.RiverEntity.users.find(x => x.id == this._appInfoService.currentUser.id).canChat;
                this.canChat = typeof this.canChat != "undefined" ? this.canChat : false;
            }
        })
        // Subscribe to config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this.coreConfig = config;
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
