import { Pipe, PipeTransform } from '@angular/core';

interface MenuItem {
    id: number;
    icon: string;
    title: string;
    type: string;
    children?: MenuItem[];
    isPage: boolean;
    isProcess: boolean;
    isMenu: boolean;
    isReport: boolean;
    refresh: boolean;
    exactMatch: boolean;
    openInNewTab: boolean;
    class: string;
}

@Pipe({ name: 'coremenufilter' })
export class CoreMenuFilterPipe implements PipeTransform {

    transform(menu: MenuItem[], filter: string): MenuItem[] {
        if (!filter) {
            // filtre boşsa  orijinali döndür
            return menu;
        }

        const filteredMenu: MenuItem[] = this.filterMenu(menu, filter);

        // filtre sonucu bulunan değerin dışındaki başlıkları getirme
        if (filteredMenu.length === 0 && menu.some(item => item.isReport && !item.isPage && !item.isProcess && !item.isMenu)) {
            return [];
        }

        return filteredMenu;
    }

    private filterMenu(menu: MenuItem[], filter: string): MenuItem[] {
        const filteredMenu: MenuItem[] = [];

        for (const item of menu) {
            const newItem: MenuItem = { ...item }; //  yeni menu olustur

            if (item.children && item.children.length > 0) {
                // childrenları varsa recursive calıs
                newItem.children = this.filterMenu(item.children, filter);

                // en az bir tane uygunsa ekle
                if (newItem.children.length > 0 || newItem.title.toLocaleLowerCase('tr').includes(filter.toLocaleLowerCase('tr'))) {
                    filteredMenu.push(newItem);
                }
            } else {
                // en alltaki childrena ulaşınca ekle
                if (newItem.title.toLocaleLowerCase('tr').includes(filter.toLocaleLowerCase('tr'))) {
                    filteredMenu.push(newItem);
                }
            }
        }

        return filteredMenu;
    }
}
